import { Breadcrumb } from "@/general/model/breadcrumb";
import { useState } from "react";
import { BreadcrumbsContext } from "./BreadcrumbsContext";

export const BreadcrumbsProvider = ({ children }) => {
    
    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

    return (
        <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
            {children}
        </BreadcrumbsContext.Provider>
    );
};
import { Dropdown } from 'react-bootstrap';
import './LanguageSelector.scss'
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { t } from 'i18next';

function LanguageSelector() {

    const { i18n } = useTranslation();

    const handleLanguageChange = (languageCode: string) => {

        if (languageCode === i18n.language) { return; };

        i18n.changeLanguage(languageCode);
        dayjs.locale(languageCode);
        localStorage.setItem('selectedLanguage', languageCode);
        toast.success(t('languages:toasts:languageChanged', { language: t(`languages:${languageCode}`) }));
    };

    return (
        <Dropdown className='language-selector' drop="down-centered">
            <Dropdown.Toggle className="toggle">
                <span>{i18n.language}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleLanguageChange("en")}>{t('languages:en')}</Dropdown.Item>
                <Dropdown.Item onClick={() => handleLanguageChange("nl")}>{t('languages:nl')}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default LanguageSelector
import { useState } from 'react';
import './QuestionList.scss'

type QuestionListProps = {
    title: string
    questions: string[]
}

function QuestionList({ title, questions }: QuestionListProps) {

    const [completedQuestions, setCompletedQuestions] = useState<string[]>([]);

    function onQuestionCompleted(question: string) {
        if (completedQuestions.some(q => q == question)) {
            setCompletedQuestions(completedQuestions.filter(q => q != question));
        } else {
            setCompletedQuestions([...completedQuestions, question]);
        }
    }

    return (
        <div className='question-list'>
            <h2 className='title'>{title}</h2>
            <ul className='questions'>
                {questions.map((question, index) =>
                    <li key={index}
                        className={`question ${completedQuestions.some(q => q == question) ? 'question--completed' : ''}`}
                        onClick={() => onQuestionCompleted(question)}>
                        {completedQuestions.some(q => q == question) &&
                            <i className='icon fas fa-check me-2' />
                        }
                        {question}
                    </li>
                )}
            </ul>
        </div>
    )
}

export default QuestionList
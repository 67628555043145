import { t } from "i18next";
import { Button, Container, Modal } from "react-bootstrap";

type ConfirmModalProps = {
    title: string;
    description: string;
    showModal: boolean;
    isSubmitting?: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

function ConfirmModal({ title, description, showModal, isSubmitting, onConfirm, onClose }: ConfirmModalProps) {
    return (
        <Modal className="wihv-modal confirm-modal" show={showModal} onHide={() => onClose()} backdrop="static" centered>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body as={Container}>
                <p>{description}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button className="btn btn--zwartblauw btn--transparent" onClick={() => onClose()}>
                    {t('general:no')}
                </Button>
                <Button className="btn btn--oranje" disabled={isSubmitting} onClick={() => onConfirm()}>
                    {t('general:yes')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmModal
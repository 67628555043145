import Button from "@/components/button/Button";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import { t } from "i18next";
import { ProgressBar } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import DashboardTile from "./DashboardTile";
import './ProfileRequirementCompletionTile.scss';

type ProfileRequirementCompletionCardProps = {
    tileClassName?: string
}

function ProfileRequirementCompletionTile({ tileClassName }: ProfileRequirementCompletionCardProps) {

    const { totalCompletion, isLoading } = useProfileCompletionRequirements();

    return (
        <DashboardTile tileClassName={`${tileClassName}`} contentClassName="profile-requirement-completion-tile-content">
            <h2 className="title">
                {t('dashboard:profileRequirementCompletion:title')}
            </h2>

            <div className="profile-progress">
                {isLoading
                    ? <Skeleton height={24} style={{borderRadius: 'var(--border-radius-round)'}} />
                    : <ProgressBar now={totalCompletion} label={`${totalCompletion}%`} />
                }
            </div>

            <div className="goals">
                <div className="goal">
                    <h3 className="title">{t('dashboard:profileRequirementCompletion:professionGuideTitle')}</h3>
                    <p className="description">{t('dashboard:profileRequirementCompletion:professionGuideDescription')}</p>
                    <Button
                        displayType='primary'
                        disabled={totalCompletion != 100}
                        text={t('dashboard:profileRequirementCompletion:professionGuideBtn')}
                        icon={totalCompletion == 100 ? 'arrow-right' : 'lock'} />
                </div>

                <div className="goal">
                    <h3 className="title">{t('dashboard:profileRequirementCompletion:quickscanTitle')}</h3>
                    <p className="description">{t('dashboard:profileRequirementCompletion:quickscanDescription')}</p>
                    <Button
                        displayType='primary'
                        disabled={totalCompletion != 100}
                        text={t('dashboard:profileRequirementCompletion:quickscanBtn')}
                        icon={totalCompletion == 100 ? 'arrow-right' : 'lock'} />
                </div>
            </div>
        </DashboardTile>
    )
}

export default ProfileRequirementCompletionTile
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { IConfigContext } from "@/context/ConfigContext";

export const reactPlugin = new ReactPlugin();

export function createApplicationInsight(config: IConfigContext): ApplicationInsights {

    const browserHistory = createBrowserHistory();
    const appInsights = new ApplicationInsights({
        config: {
            connectionString: config.configBody.logging.aiConnectionString,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
            }
        }
    });
    var telemetryInitializer = (envelope) => {
        envelope.tags["appName"] = 'PM Dashboard';
    }
    appInsights.addTelemetryInitializer(telemetryInitializer);
    appInsights.loadAppInsights();
    return appInsights;

}


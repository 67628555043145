import { ProfileContext } from '@/context/ProfileContext'
import { Profile } from '@/profile/model/profile'
import { WorkStatus } from '@/profile/model/work-status'
import { AnimatePresence, motion } from 'framer-motion'
import { t } from 'i18next'
import { createRef, useContext, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Button from '../button/Button'
import StepperComponent, { Step } from '../stepper/Stepper'
import './OnboardingForm.scss'

function OnboardingForm() {

    const navigate = useNavigate();
    const profileContext = useContext(ProfileContext);
    const formRef = createRef<HTMLFormElement>();
    const [validated, setValidated] = useState(false);
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
    const [steps, setSteps] = useState<Step[]>([
        {
            stepValue: 0,
            stepLabel: "Profiel",
            completed: false
        },
        {
            stepValue: 1,
            stepLabel: "Werkstatus",
            completed: false
        }
    ]);

    const saveChanges = () => {
        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            onboardingCompleted: true
        };

        profileContext.saveProfile(updatedProfile)
            .then(() => {
                navigate('/profile');
            })
            .catch(() => { });
    };

    useEffect(() => {
        setSubmitEnabled(steps.every(step => step.completed));
    }, [steps]);

    useEffect(() => {
        setSteps(prev => prev.map((step) => {
            if (step.stepValue === 0) {
                return (formRef?.current?.checkValidity() || formRef?.current == undefined) ? { ...step, completed: true } : { ...step, completed: false }
            }

            if (step.stepValue === 1) {
                return profileContext.tempChanges?.workStatus ? { ...step, completed: true } : { ...step, completed: false }
            }
        }));

    }, [profileContext.tempChanges]);

    const updateTempChanges = (propertyName, newValue) => {

        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            [propertyName]: newValue
        };

        profileContext.updateChanges(updatedProfile);
    };

    const nextStep = () => {

        setValidated(true);

        if (currentStepIndex === 0 && formRef.current?.checkValidity()) {
            setSteps(steps.map((step, index) => index === currentStepIndex ? { ...step, completed: true } : step));
            setCurrentStepIndex(currentStepIndex + 1);
        }
    };

    return (
        <div className='onboarding-form'>

            <StepperComponent steps={steps} currentStepIndex={currentStepIndex} stepClickEnabled={false} onHandleStepClick={() => { }} />

            <div className='form-steps'>

                <AnimatePresence mode='wait'>
                    {currentStepIndex === 0 &&
                        <motion.section className='profile-step'
                            key={'step1'}
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}>

                            <h2>Profiel aanvullen</h2>
                            <p>Welkom bij Werk in het Vooruitzicht! Laten we beginnen met het invullen van je profiel.</p>

                            <Form ref={formRef} validated={validated} noValidate>
                                <Form.Group as={Row} className="mt-3">
                                    <Col>
                                        <Form.Label><span>{t('profile:personalData:labelGivenName')}</span><span className='required'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="" required autoComplete='given-name' value={profileContext.tempChanges?.givenName ?? ''} onChange={e => updateTempChanges('givenName', e.target.value)} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-3">
                                    <Col>
                                        <Form.Label><span>{t('profile:personalData:labelSurname')}</span><span className='required'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="" required autoComplete='family-name' value={profileContext.tempChanges?.surname ?? ''} onChange={e => updateTempChanges('surname', e.target.value)} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-3">
                                    <Col>
                                        <Form.Label>{t('profile:personalData:labelCity')}</Form.Label>
                                        <Form.Control type="text" placeholder="" autoComplete='address-level2' value={profileContext.tempChanges?.city ?? ''} onChange={e => updateTempChanges('city', e.target.value)} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </motion.section>
                    }

                    {currentStepIndex === 1 &&
                        <motion.section className='workstatus-step'
                            key="step2"
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}>

                            <h2>Werkstatus</h2>
                            <p>Wat is je huidige werkstatus? Selecteer de juiste optie.</p>
                            <div className='option-list'>
                                <div className={`workstatus ${profileContext?.tempChanges?.workStatus == WorkStatus.Employed ? 'workstatus--selected' : ''}`} tabIndex={0} onClick={() => updateTempChanges('workStatus', WorkStatus.Employed)}>
                                    <i className="fas fa-briefcase" />
                                    <h3>Werkend</h3>
                                </div>
                                <div className={`workstatus ${profileContext?.tempChanges?.workStatus == WorkStatus.Unemployed ? 'workstatus--selected' : ''}`} tabIndex={0} onClick={() => updateTempChanges('workStatus', WorkStatus.Unemployed)}>
                                    <i className="fas fa-search" />
                                    <h3>Werkzoekend</h3>
                                </div>
                                <div className={`workstatus ${profileContext?.tempChanges?.workStatus == WorkStatus.Student ? 'workstatus--selected' : ''}`} tabIndex={0} onClick={() => updateTempChanges('workStatus', WorkStatus.Student)}>
                                    <i className="fas fa-user-graduate" />
                                    <h3>Student</h3>
                                </div>
                                <div className={`workstatus ${profileContext?.tempChanges?.workStatus == WorkStatus.Other ? 'workstatus--selected' : ''}`} tabIndex={0} onClick={() => updateTempChanges('workStatus', WorkStatus.Other)}>
                                    <i className="fas fa-ellipsis" />
                                    <h3>Anders</h3>
                                </div>
                            </div>
                        </motion.section>
                    }
                </AnimatePresence>
            </div>

            <div className='actions'>
                {currentStepIndex > 0 && currentStepIndex < steps.length &&
                    <Button text='Vorige' displayType='secondary' onClick={() => setCurrentStepIndex(currentStepIndex - 1)} icon='arrow-left' />
                }
                {currentStepIndex < steps.length - 1 &&
                    <Button text='Volgende' displayType='secondary' onClick={() => nextStep()} icon='arrow-right' />
                }
                {currentStepIndex == steps.length - 1 &&
                    <Button displayType='primary' text={'Antwoorden opslaan'} icon='floppy-disk' disabled={!submitEnabled} onClick={() => { saveChanges() }} />
                }
            </div>
        </div>
    )
}

export default OnboardingForm
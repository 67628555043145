import toast from 'react-hot-toast';
import LinkButton from '../components/button/LinkButton';
import { t } from 'i18next';
import { useScreenDetector } from './useScreenDetector';

const useCustomToasts = () => {

    const { isMobile } = useScreenDetector();

    const showMeasurementsVoucherCTAToast = () => {

        toast.dismiss('toast-measurements-cta');

        toast(
            (ref) => (
                <div className="toast-content">
                    <div>
                        <p className='pb-3'>{t('measurements:toasts:limitedAccessWarning')}</p>
                        <LinkButton text="Mijn vouchers" icon="arrow-right" to={'/vouchers'} displayType="primary" onClick={() => toast.dismiss(ref.id)} />
                    </div>
                    <i role="button" className="close fas fa-times fa-xl" onClick={() => toast.dismiss(ref.id)} />
                </div>
            ),
            {
                id: 'measurements-voucher-cta',
                duration: 10000, // 10 seconds
                className: 'toast-measurements-cta',
                style: {
                    backgroundColor: 'var(--color-brand-secondary-300)',
                    color: 'var(--color-neutral-light-0)',
                    border: 'var(--border-width-m) solid var(--color-brand-secondary-600)',
                    borderRadius: isMobile ? 'var(--border-radius-l)' : 'var(--border-radius-xl)',
                    maxWidth: isMobile ? '90vw' : '40vw',
                },
            }
        );
    };

    return {
        showMeasurementsVoucherCTAToast,
    };
}

export default useCustomToasts;
import { t } from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import './UsefulLinks.scss';

function UsefulLinks() {
    return (
        <Container className="useful-links mt-4">
            <Row>
                <Col>
                    <h4 className='title'>{t('settings:usefulLinks:title')}</h4>
                    <ul>
                        <li>
                            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                {t('settings:usefulLinks:privacyPolicy')}
                            </a>
                        </li>
                        <li>
                            <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">
                                {t('settings:usefulLinks:cookiePolicy')}
                            </a>
                        </li>
                        <li>
                            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                {t('settings:usefulLinks:termsAndConditions')}
                            </a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default UsefulLinks;
import { BreadcrumbsContext } from "@/context/BreadcrumbsContext";
import { AttributeCategory } from "@/general/model/attribute-category";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import useProfileAttributes from "@/hooks/useProfileAttributes";
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './DevelopmentOverview.scss';
import CategoryAttributes from "./components/CategoryAttributes";
import ProfileEvaluation from "./components/profile-evaluation/ProfileEvaluation";

function DevelopmentOverview() {

    useDocumentTitle(t('documentTitles:development'));
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const { profileAttributes, isLoading } = useProfileAttributes();

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:development')
        }]);
    }, [setBreadcrumbs]);

    return (
        <div className="development-overview py-5">

            {/* TODO: Loading */}

            <Container fluid className='pb-5'>
                <Container>
                    <p>
                        Dit is een overzicht van jouw persoonlijke ontwikkeling. Hier vind je een overzicht van jouw persoonlijke eigenschappen, vaardigheden en potentieel. Dit overzicht is gebaseerd op de gegevens die je hebt ingevuld in je profiel.
                        Wanneer je op een kenmerk klikt krijg je meer informatie over dit kenmerk, zoals de omschrijving en de bronnen waarop dit kenmerk is gebaseerd. Klopt er een kenmerk niet? Geef feedback via de knop onderaan de pagina.
                    </p>
                </Container>
            </Container>

            <Container fluid>
                <Container>
                    {profileAttributes &&
                        <Row>
                            <Col sm={12}>
                                <CategoryAttributes category={AttributeCategory.Personality} attributes={profileAttributes.filter(x => x.category === AttributeCategory.Personality)} />
                            </Col>
                            <Col sm={12} className="mt-5">
                                <CategoryAttributes category={AttributeCategory.Skills} attributes={profileAttributes.filter(x => x.category === AttributeCategory.Skills)} />
                            </Col>
                            <Col sm={12} className="mt-5">
                                <CategoryAttributes category={AttributeCategory.Potential} attributes={profileAttributes.filter(x => x.category === AttributeCategory.Potential)} />
                            </Col>
                        </Row>
                    }
                </Container>
            </Container>

            <Container fluid className='py-5'>
                <Container>
                    <ProfileEvaluation />
                </Container>
            </Container>
        </div>
    )
}

export default DevelopmentOverview;

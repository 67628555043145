import React, { useState } from "react";
import { CookiePreferencesContext, ICookiePreferencesContext } from "./CookiePreferencesContext";
import { CookiePreferences } from "@/general/model/cookie-preferences";
import { t } from "i18next";
import toast from "react-hot-toast";
import Cookies from 'js-cookie';

export function CookiePreferencesProvider({ children }): React.ReactElement {

    const cookiePreferences = Cookies.get('wihv_cookie_preferences');

    const [contextValue, setContextValue] = useState<ICookiePreferencesContext>({
        preferences: cookiePreferences ? JSON.parse(cookiePreferences) : null as CookiePreferences,
        acceptAll: acceptAll,
        denyAll: denyAll,
        updatePreferences: updatePreferences
    });

    function acceptAll() {
        const updatedPreferences =
            {
                strictlyNecessary: true,
                functional: true,
                multimedia: true
            } as CookiePreferences;
        setContextValue(prev => ({ ...prev, preferences: updatedPreferences }));

        toast.success(t('settings:toasts:cookiePreferencesSaved'));
        Cookies.set('wihv_cookie_preferences', JSON.stringify(updatedPreferences), { expires: 60 });
    };

    function denyAll() {
        const updatedPreferences =
            {
                strictlyNecessary: true,
                functional: false,
                multimedia: false
            } as CookiePreferences;
        setContextValue(prev => ({ ...prev, preferences: updatedPreferences }));

        toast.success(t('settings:toasts:cookiePreferencesSaved'));
        Cookies.set('wihv_cookie_preferences', JSON.stringify(updatedPreferences), { expires: 60 });
    }

    function updatePreferences(newPreferences: CookiePreferences) {
        const updatedPreferences = { ...newPreferences } as CookiePreferences;
        setContextValue(prev => ({ ...prev, preferences: updatedPreferences }));

        toast.success(t('settings:toasts:cookiePreferencesSaved'));
        Cookies.set('wihv_cookie_preferences', JSON.stringify(updatedPreferences), { expires: 60 });
    };

    return (
        <CookiePreferencesContext.Provider value={contextValue}>
            {children}
        </CookiePreferencesContext.Provider>
    );
}
import { ConfigContext } from '@/context/ConfigContext';
import { MeasurementDefinition } from '@/measurements/model/measurement-definitions/measurement-definition';
import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import useSWR from 'swr';

const useRecommendedMeasurements = () => {

    const configContext = useContext(ConfigContext);
    const isAuthenticated = useIsAuthenticated();
    const { data: recommendedMeasurements, isLoading, error, mutate } = useSWR<MeasurementDefinition[]>(isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.measurements.recommended : null);

    return { recommendedMeasurements, isLoading, error, mutate };
}

export default useRecommendedMeasurements;

import CookieConsentBanner from "@/components/cookie-consent-banner/CookieConsentBanner";
import ScrollToTopOnNavigation from "@/components/scroll-to-top/ScrollToTopOnNavigation";
import { BreadcrumbsProvider } from "@/context/BreadcrumbsProvider";
import { LayoutContext } from "@/context/LayoutContext";
import { motion } from "framer-motion";
import { useContext, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import './AuthenticatedAppLayout.scss';
import Header from "./header/Header";
import NavigationDrawer from "./navigation-drawer/NavigationDrawer";
import { ProtectedRoute } from "./ProtectedRoute";

function AuthenticatedAppLayout() {

    const {
        isMobile,
        navigationFolded,
        stickyHeader,
        headerHeight,
        navigationWidth
    } = useContext(LayoutContext);

    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty('--sizing-header-height', headerHeight);
        root.style.setProperty('--sizing-navigation-width', navigationWidth);
    }, [navigationFolded, stickyHeader]);

    return (
        <ProtectedRoute>
            <BreadcrumbsProvider>

                <ScrollToTopOnNavigation />

                <motion.div
                    className={`main-wrapper ${isMobile ? `mobile ${navigationFolded ? 'folded' : 'unfolded'}` : ''}`}
                    animate={{ gridTemplateColumns: isMobile ? '1fr' : `${navigationWidth} 1fr` }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}>

                    <motion.aside
                        className="wihv-navigation"
                        animate={{ width: navigationWidth }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}>
                        <NavigationDrawer />
                    </motion.aside>

                    <header className="wihv-header">
                        <Header />
                    </header>

                    <main className="wihv-main">
                        <Outlet />
                    </main>
                </motion.div>

                <CookieConsentBanner />
                <Toaster
                    position="bottom-center"
                    gutter={16}
                    containerStyle={
                        {
                            zIndex: 999,
                            marginLeft: navigationWidth
                        }
                    }
                    toastOptions={
                        {
                            duration: 5000,
                            style: {
                                padding: '16px',
                                gap: '12px',
                            },
                            success: {
                                duration: 3000,
                                style: {
                                    border: '2px solid #41B883'
                                },
                            },
                            error: {
                                style: {
                                    border: '2px solid #F56565'
                                },
                            },
                            loading: {
                                style: {
                                    border: '2px solid #3182CE'
                                },
                            }
                        }
                    }
                />
            </BreadcrumbsProvider>
        </ProtectedRoute>
    );
}

export default AuthenticatedAppLayout;
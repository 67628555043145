import { LayoutContext } from '@/context/LayoutContext';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './NavigationDrawerItem.scss';

type NavigationDrawerItemProps = {
    icon: string;
    title: string;
    link: string;
}

function NavigationDrawerItem({ icon, title, link }: NavigationDrawerItemProps) {

    const { navigationFolded } = useContext(LayoutContext);

    return (
        <li className={`navigation-drawer-item ${navigationFolded ? 'folded' : 'unfolded'}`}>
            <NavLink to={link} className={`nav-link`}>
                <i className={`icon fas fa-${icon}`} />
                <AnimatePresence>
                    {navigationFolded
                        ?
                        <div className='nav-tooltip'>
                            {title}
                        </div>
                        :
                        <motion.div
                            key={title}
                            className='title'
                            initial={{ opacity: 0, width: 0 }}
                            animate={{ opacity: 1, width: 'auto' }}
                            exit={{ opacity: 0, width: 0 }}
                            transition={{ duration: 0.2, delay: 0.1 }}
                        >
                            {title}
                        </motion.div>
                    }
                </AnimatePresence>
            </NavLink>
        </li>
    )
}

export default NavigationDrawerItem
import { Attribute } from '@/general/model/attribute';
import { t } from 'i18next';
import { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

type MeasurementInfoProps = {
    isLoading: boolean;
    description: string;
    attributes: Attribute[];
}

function MeasurementInfo({ isLoading, description, attributes }: MeasurementInfoProps) {

    const [showAttributeModal, setShowAttributeModal] = useState(false);
    const [selectedAttribute, setSelectedAttribute] = useState<Attribute>(null);

    return (
        <Row>
            <Col sm={12}>
                <h3 className='mb-3'>{t('measurements:info:title')}</h3>
            </Col>
            <Col sm={12} md={12} lg={6}>
                <h4 className='mb-2'>{t('measurements:info:contentTitle')}</h4>
                <p>
                    {isLoading
                        ? <Skeleton height={18} count={4} style={{ opacity: 0.7 }} className='mt-2' baseColor='var(--color-neutral-lightblue-300)' highlightColor='rgba(255,255,255,0.6)' />
                        : description
                    }
                </p>
            </Col>
            <Col sm={12} md={12} lg={{ span: 5, offset: 1 }} className='pt-4 pt-lg-0'>
                <h4 className='mb-2'>{t('measurements:info:characteristicsTitle')}</h4>
                <p>
                    {isLoading &&
                        <Skeleton height={18} inline={true} width={120} count={3} style={{ opacity: 0.7 }} className='mt-2 me-4' baseColor='var(--color-neutral-lightblue-300)' highlightColor='rgba(255,255,255,0.6)' />
                    }
                    {!isLoading && attributes?.map((attribute, index) => (
                        <span key={attribute.id}>
                            <a className='text-decoration-underline' role='button' onClick={() => { setSelectedAttribute(attribute); setShowAttributeModal(true) }}>
                                {attribute.name}
                            </a>
                            {index < attributes.length - 1 && ', '}
                        </span>
                    ))}
                </p>
            </Col>

            <Modal className='wihv-modal wihv-modal--info' centered show={showAttributeModal} onHide={() => setShowAttributeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('measurements:info:explanationTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='info-modal__title'>{selectedAttribute?.name}</p>
                    <p className='info-modal__description'>{selectedAttribute?.description}</p>
                </Modal.Body>
            </Modal>

        </Row>
    )
}

export default MeasurementInfo
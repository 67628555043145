import { LoadingComponent } from "@/components/loading-component/LoadingComponent";
import { ConfigContext } from "@/context/ConfigContext";
import useAuthenticatedRequest from "@/hooks/useAuthenticatedRequest";
import { Profile } from "@/profile/model/profile";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import useSWR from "swr";
import { IProfileState, ProfileContext } from "./ProfileContext";

function ProfileProvider({ children }): React.ReactElement {

    const configContext = useContext(ConfigContext);
    const authenticatedRequest = useAuthenticatedRequest();
    const { data: profileDetails, isLoading: detailsIsLoading, error, mutate } = useSWR(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.profile.detail, { shouldRetryOnError: false });
    const { mutate: mutateProfileCompletion } = useSWR(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.profile.completionRequirements, { shouldRetryOnError: false });

    const [contextValue, setContextValue] = useState<IProfileState>({
        profile: undefined,
        tempChanges: undefined,
        requestInProgress: false,
        updateProfile: updateProfile,
        updateChanges: updateChanges,
        saveProfile: saveProfile
    });

    useEffect(() => {
        if (profileDetails) {
            setContextValue((prevContextValue) => {
                return {
                    ...prevContextValue,
                    profile: profileDetails,
                    tempChanges: profileDetails,
                };
            });

            mutateProfileCompletion();
        }
    }, [profileDetails]);

    function updateProfile(profile: Profile) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                profile: profile
            };
        });
    }

    function updateChanges(profile: Profile) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                tempChanges: profile
            };
        });
    }

    function saveProfile(profile: Profile): Promise<Profile> {

        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                requestInProgress: true,
            };
        });

        return authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.profile.detail, 'POST', profile)
            .then((response) => {
                updateChanges(response);
                mutate();
                toast.success(t('profile:toasts:saveSuccess'));
                return response;
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('profile:toasts:saveFailed') + ' ' + error.message);
                throw error;
            })
            .finally(() => {
                setContextValue((prevContextValue) => {
                    return {
                        ...prevContextValue,
                        requestInProgress: false,
                    };
                });
            });
    };


    if (detailsIsLoading) {
        const message = t('general:retrievingData');
        return <LoadingComponent message={message} />;
    } else {
        return (
            <ProfileContext.Provider value={contextValue}>
                {children}
            </ProfileContext.Provider>
        );
    }
}

export default ProfileProvider;
import { RecommendedProfession } from "@/professions/model/recommended-profession";

function getRecommendedMockProfessions() {
    const recommendedMockProfessions: Partial<RecommendedProfession>[] = [
        {
            "id": "1",
            "name": "Data analyst",
            "shortName": "Data analyst",
            "description": "Do you love working with data and turning it into insights? As a data analyst, you'll be responsible for collecting, analyzing, and interpreting large sets of data to help businesses make informed decisions.",
            "percentRecommended": 64,
            "attributeCategoryRecommendations": [
                {
                    "attributeCategory": 1,
                    "percentRecommended": 49
                },
                {
                    "attributeCategory": 2,
                    "percentRecommended": 67
                },
                {
                    "attributeCategory": 3,
                    "percentRecommended": 89
                }
            ]
        },
        {
            "id": "2",
            "name": "Software developer",
            "shortName": "Data analyst",
            "description": "Are you passionate about coding and building software? As a software developer, you'll be responsible for designing, developing, and maintaining software applications that meet the needs of businesses and consumers.",
            "percentRecommended": 78,
            "attributeCategoryRecommendations": [
                {
                    "attributeCategory": 1,
                    "percentRecommended": 62
                },
                {
                    "attributeCategory": 2,
                    "percentRecommended": 84
                },
                {
                    "attributeCategory": 3,
                    "percentRecommended": 71
                }
            ]
        },
        {
            "id": "3",
            "name": "UX designer",
            "shortName": "Data analyst",
            "description": "Do you have a passion for creating user-friendly and visually appealing digital experiences? As a UX designer, you'll be responsible for designing and testing user interfaces to ensure they meet the needs of users.",
            "percentRecommended": 56,
            "attributeCategoryRecommendations": [
                {
                    "attributeCategory": 1,
                    "percentRecommended": 42
                },
                {
                    "attributeCategory": 2,
                    "percentRecommended": 63
                },
                {
                    "attributeCategory": 3,
                    "percentRecommended": 78
                }
            ]
        },
        {
            "id": "4",
            "name": "Digital marketer",
            "shortName": "Data analyst",
            "description": "Are you creative and analytical with a passion for marketing? As a digital marketer, you'll be responsible for developing and executing marketing campaigns across various digital channels to drive business growth.",
            "percentRecommended": 72,
            "attributeCategoryRecommendations": [
                {
                    "attributeCategory": 1,
                    "percentRecommended": 58
                },
                {
                    "attributeCategory": 2,
                    "percentRecommended": 76
                },
                {
                    "attributeCategory": 3,
                    "percentRecommended": 63
                }
            ]
        },
        {
            "id": "5",
            "name": "Cybersecurity analyst",
            "shortName": "Data analyst",
            "description": "Do you have a passion for protecting sensitive information and preventing cyber attacks? As a cybersecurity analyst, you'll be responsible for identifying and mitigating security risks to ensure the safety of digital assets.",
            "percentRecommended": 81,
            "attributeCategoryRecommendations": [
                {
                    "attributeCategory": 1,
                    "percentRecommended": 67
                },
                {
                    "attributeCategory": 2,
                    "percentRecommended": 89
                },
                {
                    "attributeCategory": 3,
                    "percentRecommended": 76
                }
            ]
        },
        {
            "id": "6",
            "name": "Product manager",
            "shortName": "Data analyst",
            "description": "Do you have a passion for creating and launching successful products? As a product manager, you'll be responsible for overseeing the entire product development lifecycle, from ideation to launch.",
            "percentRecommended": 68,
            "attributeCategoryRecommendations": [
                {
                    "attributeCategory": 1,
                    "percentRecommended": 53
                },
                {
                    "attributeCategory": 2,
                    "percentRecommended": 71
                },
                {
                    "attributeCategory": 3,
                    "percentRecommended": 84
                }
            ]
        },
        {
            "id": "7",
            "name": "Data scientist",
            "shortName": "Data analyst",
            "description": "Do you have a passion for data and machine learning? As a data scientist, you'll be responsible for developing and implementing algorithms to extract insights from large sets of data.",
            "percentRecommended": 75,
            "attributeCategoryRecommendations": [
                {
                    "attributeCategory": 1,
                    "percentRecommended": 60
                },
                {
                    "attributeCategory": 2,
                    "percentRecommended": 82
                },
                {
                    "attributeCategory": 3,
                    "percentRecommended": 69
                }
            ]
        },
        {
            "id": "8",
            "shortName": "Data analyst",
            "name": "Full-stack developer",
            "description": "Do you have a passion for both front-end and back-end development? As a full-stack developer, you'll be responsible for designing and developing both the client-side and server-side of web applications.",
            "percentRecommended": 83,
            "attributeCategoryRecommendations": [
                {
                    "attributeCategory": 1,
                    "percentRecommended": 67
                },
                {
                    "attributeCategory": 2,
                    "percentRecommended": 89
                },
                {
                    "attributeCategory": 3,
                    "percentRecommended": 76
                }
            ]
        },
        {
            "id": "9",
            "shortName": "Data analyst",
            "name": "Social media manager",
            "description": "Do you have a passion for social media and creating engaging content? As a social media manager, you'll be responsible for developing and executing social media strategies to increase brand awareness and engagement.",
            "percentRecommended": 60,
            "attributeCategoryRecommendations": [
                {
                    "attributeCategory": 1,
                    "percentRecommended": 45
                },
                {
                    "attributeCategory": 2,
                    "percentRecommended": 67
                },
                {
                    "attributeCategory": 3,
                    "percentRecommended": 78
                }
            ]
        },
        {
            "id": "10",
            "name": "IT helpdesk technician",
            "shortName": "Data analyst",
            "description": "Do you enjoy troubleshooting technical issues and helping others? As an IT helpdesk technician, you'll be responsible for providing technical support to users and resolving technical issues.",
            "percentRecommended": 52,
            "attributeCategoryRecommendations": [
                {
                    "attributeCategory": 1,
                    "percentRecommended": 37
                },
                {
                    "attributeCategory": 2,
                    "percentRecommended": 59
                },
                {
                    "attributeCategory": 3,
                    "percentRecommended": 71
                }
            ]
        }
    ]

    return recommendedMockProfessions;
}

export default getRecommendedMockProfessions;
import ProfilePicture from "@/components/profile-picture/ProfilePicture";
import { BreadcrumbsContext } from "@/context/BreadcrumbsContext";
import useCurrentAccount from "@/hooks/useCurrentAccount";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './ProfileOverview.scss';
import ProfileCertificates from "./components/ProfileCertificates";
import ProfileEducations from "./components/ProfileEducations";
import ProfileInterests from "./components/ProfileInterests";
import ProfileLanguages from "./components/ProfileLanguages";
import ProfilePersonalData from "./components/ProfilePersonalData";
import ProfileUploads from "./components/ProfileUploads";
import ProfileVolunteerExperiences from "./components/ProfileVolunteerExperiences";
import ProfileWorkExperiences from "./components/ProfileWorkExperiences";

function ProfileOverview() {

    useDocumentTitle(t('documentTitles:profile'));
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const { currentAccount } = useCurrentAccount();

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:profile')
        }]);
    }, [setBreadcrumbs]);

    return (
        <div className="profile">

            <Container fluid className='profile-header'>
                <Container>
                    <div className="content">
                        <div className="picture">
                            <ProfilePicture editable={true} width="200px" height="200px" iconSize="fa-2xl" />
                        </div>
                        <div className="title">
                            <h1>{currentAccount != null ? currentAccount.name : t('profile:title')}</h1>
                            <p>{t('profile:description')}</p>
                        </div>
                    </div>
                </Container>
            </Container>

            <Container fluid className='pb-5'>
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>
                            <ProfilePersonalData />
                            <ProfileInterests />
                            <ProfileLanguages />
                            <ProfileVolunteerExperiences />
                            <ProfileUploads />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6}>
                            <ProfileEducations />
                            <ProfileWorkExperiences />
                            <ProfileCertificates />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default ProfileOverview;

import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useBlocker } from "react-router-dom";
import { isEqual } from "lodash";
import { t } from "i18next";

type UnsavedChangesGuardProps = {
    shouldBlock: boolean | { obj1: any, obj2: any }
}

function UnsavedChangesGuard({ shouldBlock }: UnsavedChangesGuardProps) {

    const [block, setBlock] = useState(false);

    useEffect(() => {

        if (typeof shouldBlock === 'boolean') {
            setBlock(shouldBlock);
        } else {
            setBlock(!isEqual(shouldBlock.obj1, shouldBlock.obj2));
        }

    }, [JSON.stringify(shouldBlock)]);

    let blocker = useBlocker(block);

    const handleClose = () => blocker.reset();
    const handleConfirm = () => blocker.proceed();

    return (
        <Modal className="wihv-modal" centered show={blocker.state === "blocked"} onHide={handleClose} aria-describedby="modal-description">
            <Modal.Header closeButton>
                <Modal.Title className="font-bold">{t('general:confirmTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p id="modal-description">{t('general:confirmDescription')}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn--oranje" onClick={handleClose}>
                    {t('general:cancel')}
                </Button>
                <Button className="btn btn--zwartblauw btn--transparent" onClick={handleConfirm}>
                    {t('general:confirm')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UnsavedChangesGuard
import UnsavedChangesGuard from '@/components/unsaved-changes-guard/UnsavedChangesGuard';
import { BreadcrumbsContext } from '@/context/BreadcrumbsContext';
import { ConfigContext } from '@/context/ConfigContext';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import useSWR from 'swr';
import { SettingsContext } from '../../context/settings-context';
import './SettingsOverview.scss';
import AccountSettings from './components/AccountSettings';
import PrivacySettings from './components/PrivacySettings';
import UsefulLinks from './components/UsefulLinks';

function SettingsOverview() {

    useDocumentTitle(t('documentTitles:settings'));
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const configContext = useContext(ConfigContext);
    const settingsContext = useContext(SettingsContext);

    const { data, isLoading, error } = useSWR(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.general.settings);

    useEffect(() => {

        if (data) {
            settingsContext.updateSettings(data);
        }

    }, [data]);

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:settings')
        }]);
    }, [setBreadcrumbs]);

    return (
        <div className='settings'>
            <Container fluid className='py-5'>
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <AccountSettings />
                        </Col>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                            <PrivacySettings />
                            <UsefulLinks />
                        </Col>
                    </Row>
                </Container>
            </Container>

            <UnsavedChangesGuard shouldBlock={{ obj1: settingsContext.settings, obj2: settingsContext.tempChanges }} />
        </div>
    )
}

export default SettingsOverview
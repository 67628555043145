export interface FileUpload {
    id: string;
    fileName: string;
    description: string;
    type: FileUploadType;
    file: File;
    uploadedAt: string;
}

export enum FileUploadType {
    Certificate = 1,
    CV = 2,
    MotivationLetter = 3,
    Other = 99,
}
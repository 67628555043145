
import PageNotFound from '@/components/not-found-component/PageNotFound';
import ProfileProvider from '@/context/ProfileProvider';
import { Route, Routes } from 'react-router-dom';
import ProfileOverview from './ProfileOverview';

function ProfileModule() {
    return (
        <Routes>
            <Route path="/" index element={
                <ProfileProvider>
                    <ProfileOverview />
                </ProfileProvider>
            } />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default ProfileModule;
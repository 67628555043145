import { ProfileContext } from '@/context/ProfileContext';
import { Profile } from '@/profile/model/profile';
import { useContext, useState } from 'react';
import { Container, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../button/Button';
import LanguageSelector from '../language-selector/LanguageSelector';
import './Onboarding.scss';
import OnboardingForm from './OnboardingForm';
import OnboardingPitch from './OnboardingPitch';

type OnboardingProps = {
    isSubmitting?: boolean;
    onConfirm: () => void;
}

function Onboarding({ isSubmitting, onConfirm }: OnboardingProps) {

    const { i18n } = useTranslation();
    const [onboardingStarted, setOnboardingStarted] = useState<boolean>(false);
    const [onboardingUsingForm, setOnboardingUsingForm] = useState<boolean>(true);
    const [showBackBtn, setShowBackBtn] = useState<boolean>(false);

    return (
        <Modal className="wihv-modal onboarding-modal" size='xl' show={true} backdrop="static" centered>
            <Modal.Header>
                <img src={`/assets/img/werk-in-het-vooruitzicht-fullcolor.svg`} alt='logo' />
                <div className='language-selection'>
                    <img className='language-flag' src={`/assets/img/languages/${i18n.language}.svg`} alt={i18n.language} />
                    <LanguageSelector />
                </div>
            </Modal.Header>

            <Modal.Body as={Container} className='p-2 p-md-4'>

                {!onboardingStarted &&
                    <div className='onboarding-start'>
                        <h1 className='title'>
                            Start hier je onboarding
                        </h1>
                        <p className='description'>
                            Lorem ipsum dolor sit amet consectetur adipiscing elit litora vivamus mattis auctor,
                            feugiat blandit porttitor platea scelerisque ullamcorper sollicitudin potenti libero
                            lacus cursus in, faucibus felis orci leo magnis fames consequat vel mi suscipit.
                        </p>
                        <div className='onboarding-options'>
                            <div className={`option ${onboardingUsingForm ? 'option--selected' : ''}`} tabIndex={0} onClick={() => setOnboardingUsingForm(true)}>
                                <i className='icon fas fa-keyboard'></i>
                                <h2>Formulier invullen</h2>
                                <p>Fill in the form to complete your onboarding</p>
                            </div>
                            {/* <div className={`option ${!onboardingUsingForm ? 'option--selected' : ''}`} tabIndex={0} onClick={() => setOnboardingUsingForm(false)}>
                                <i className='icon fas fa-video'></i>
                                <h2>Video inspreken</h2>
                                <p>Record a pitch to complete your onboarding</p>
                            </div> */}
                        </div>
                        <Button displayType='primary' text='Start the onboarding' icon='play' onClick={() => setOnboardingStarted(true)} />
                    </div>
                }

                {onboardingStarted &&
                    <>
                        {showBackBtn &&
                            <div>
                                <Button displayType='tertiary' text='Terug naar keuzemenu' icon='arrow-left' onClick={() => setOnboardingStarted(false)} />
                            </div>
                        }
                        {onboardingUsingForm
                            ? <OnboardingForm />
                            : <OnboardingPitch />
                        }
                    </>
                }

            </Modal.Body>

            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}

export default Onboarding

import { useEffect, useState } from 'react'
import RecordRTC, { RecordRTCPromisesHandler } from 'recordrtc'
import Button from '../button/Button'
import './VideoRecorder.scss'
import { AnimatePresence, Variants, motion } from 'framer-motion'

type VideoRecorderProps = {
    submitBtnText: string;
    width?: string;
    onRecordingSubmit: (blob: Blob) => void;
}

function VideoRecorder({ submitBtnText, width = '100%', onRecordingSubmit }: VideoRecorderProps) {

    const popupVariants: Variants = {
        hidden: { opacity: 0, y: -50 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -50 }
    };

    const [recorder, setRecorder] = useState<RecordRTC | null>();
    const [stream, setStream] = useState<MediaStream | null>();
    const [blob, setBlob] = useState<Blob | null>();
    const [error, setError] = useState<string | null>();
    const [isDownloadPopupVisible, setDownloadPopupVisible] = useState(false);
    const [hasPopupShown, setHasPopupShown] = useState(false);

    useEffect(() => {
        if (blob && !hasPopupShown) {
            setDownloadPopupVisible(true);
            setHasPopupShown(true);
        }
    }, [blob, hasPopupShown]);

    const startRecording = async () => {
        const mediaDevices = navigator.mediaDevices;
        const stream: MediaStream = await mediaDevices.getUserMedia({
            video: {
                width: { min: 720, ideal: 1280, max: 1920 },
                height: { min: 480, ideal: 720, max: 1080 },
                aspectRatio: { ideal: 1.777777778 }
            },
            audio: true,
        }).catch((error) => {
            setError('Er is een fout opgetreden bij het starten van de opname. Controleer of de camera en microfoon toestemming hebben om te worden gebruikt.');
            return null;
        });

        if (stream === null) {
            return;
        }

        const recorder: RecordRTC = new RecordRTCPromisesHandler(stream, {
            type: 'video/mp4'
        });

        await recorder.startRecording();
        setRecorder(recorder);
        setStream(stream);
        setBlob(null);
    }

    const stopRecording = async () => {
        if (recorder) {
            await recorder.stopRecording();
            const blob: Blob = await recorder.getBlob();
            (stream as any).stop();
            setBlob(blob);
            setStream(null);
            setRecorder(null);
        }
    }

    return (
        <div className='video-recorder'>
            <div className='video-container' style={{ width: width }}>
                {!stream &&
                    <video
                        className='video'
                        controls
                        muted
                        src={blob ? URL.createObjectURL(blob) : undefined}
                    />
                }
                {stream &&
                    <video
                        className='video'
                        autoPlay
                        playsInline
                        muted
                        ref={(video) => {
                            if (video) {
                                video.srcObject = stream;
                            }
                        }}
                    />
                }

                <AnimatePresence>
                    {isDownloadPopupVisible &&
                        <motion.div
                            className='download-popup'
                            initial='hidden'
                            animate='visible'
                            exit='exit'
                            variants={popupVariants}
                            transition={{ ease: "easeOut", duration: 0.5 }}
                        >
                            <span>You can download the video for yourself via the video controls below. Click on the following icon: <i className='fas fa-ellipsis-vertical ms-2'></i></span>
                            <button onClick={(e) => { e.preventDefault(); setDownloadPopupVisible(false)}}>
                                <i className='fas fa-times'></i>
                            </button>
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
            {error &&
                <p className='warning'>
                    <i className='fas fa-exclamation-triangle me-2'></i>
                    {error}
                </p>
            }
            <div className='controls'>
                <Button text={`${blob ? 'Opnieuw opnemen' : 'Start opname'}`} displayType='secondary' disabled={recorder} icon={`${blob ? 'rotate-right' : 'play'}`} onClick={startRecording} />
                <Button text='Stop opname' displayType='secondary' disabled={!recorder} icon='stop' onClick={stopRecording} />
                {blob && <Button text={submitBtnText} displayType='primary' icon='search' onClick={() => onRecordingSubmit(blob)} />}
            </div>
        </div>
    )
}

export default VideoRecorder
import { useAttributeCategoryTranslation, useAttributeSubcategoryTranslation } from "@/general/i18n/translation-helpers"
import { Attribute } from "@/general/model/attribute"
import { AttributeCategory } from "@/general/model/attribute-category"
import { useState } from "react"
import AttributeDetailsModal from "./AttributeDetailsModal"
import './CategoryAttributes.scss'

type CategoryAttributesProps = {
    category: AttributeCategory
    attributes: Attribute[]
}

function CategoryAttributes({ category, attributes }: CategoryAttributesProps) {

    const { translateAttributeCategory } = useAttributeCategoryTranslation();
    const { translateAttributeSubcategory } = useAttributeSubcategoryTranslation();
    const [showAttributeModal, setShowAttributeModal] = useState(false);
    const [selectedAttribute, setSelectedAttribute] = useState<Attribute>(null);

    const groupedAttributes = attributes.reduce((acc, attribute) => {
        const subCategory = attribute.subCategory;
        if (!acc[subCategory]) {
            acc[subCategory] = [];
        }
        acc[subCategory].push(attribute);
        return acc;
    }, {} as Record<string, Attribute[]>);

    return (
        <div className={`category-attributes category-${category}`}>
            <header>
                <h2 className="title">{translateAttributeCategory(category)}</h2>
                <div className="count">{attributes.length}</div>
            </header>
            {Object.entries(groupedAttributes).map(([subCategory, subCategoryAttributes]) => (
                subCategoryAttributes.length > 0 &&
                <div key={subCategory} className="subcategory">
                    <h3 className="subtitle">{translateAttributeSubcategory(category, Number(subCategory))}</h3>
                    <div className="attributes">
                        {subCategoryAttributes.map((attribute, index) => (
                            <span key={index} className={`attribute`} tabIndex={0} onClick={() => { setSelectedAttribute(attribute); setShowAttributeModal(true) }}>
                                {attribute.name}
                            </span>
                        ))}
                    </div>
                </div>
            ))}

            <AttributeDetailsModal showAttributeModal={showAttributeModal} setShowAttributeModal={setShowAttributeModal} attribute={selectedAttribute} />
        </div>
    )
}

export default CategoryAttributes
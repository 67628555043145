import { BreadcrumbsContext } from '@/context/BreadcrumbsContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss';
import { LayoutContext } from '@/context/LayoutContext';

function Breadcrumbs() {

    const { stickyHeader, isMobile } = useContext(LayoutContext);
    const { breadcrumbs } = useContext(BreadcrumbsContext);

    return (
        <nav aria-label="breadcrumb">
            <ol className={`breadcrumb ${stickyHeader ? 'sticky' : ''}`}>
                {!isMobile && breadcrumbs?.map((crumb, index) => (
                    <li key={index} className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? 'active' : ''}`}>
                        {crumb.path
                            ? <Link to={crumb.path} className='link'>{crumb.label}</Link>
                            : <span aria-current={index === breadcrumbs.length - 1 ? 'page' : undefined}>{crumb.label}</span>
                        }
                    </li>
                ))}
                {isMobile && breadcrumbs?.length > 0 && (
                    <li className='breadcrumb-item active'>
                        <span aria-current="page">{breadcrumbs[breadcrumbs.length - 1].label}</span>
                    </li>
                )}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
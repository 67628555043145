import LinkButton from "@/components/button/LinkButton";
import FavoriteMeasurementToggle from "@/components/favorite-toggle/FavoriteMeasurementToggle";
import SwiperComponent from "@/components/swiper-component/SwiperComponent";
import { MeasurementDefinition } from "@/measurements/model/measurement-definitions/measurement-definition";
import { useIsAuthenticated } from "@azure/msal-react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { SwiperSlide } from 'swiper/react';
import './MeasurementSwiper.scss';

type MeasurementSwiperProps = {
    measurements: MeasurementDefinition[];
}

function MeasurementSwiper({ measurements }: MeasurementSwiperProps) {

    const isAuthenticated = useIsAuthenticated();
    const isCompleted = (measurement: MeasurementDefinition) => !isNaN(Date.parse(measurement.completed));

    return (
        <SwiperComponent scrollbar={true} loop={false}>
            {measurements.map((measurement) => (
                <SwiperSlide key={measurement.id} className={`measurement-slide`}>
                    <Link to={`/measurements/${measurement.id}`}>

                        {isAuthenticated &&
                            <FavoriteMeasurementToggle measurement={measurement} />
                        }

                        <div className="banners">
                            {isCompleted(measurement) &&
                                <div className="completed">
                                    <i className="fas fa-check"></i>
                                </div>
                            }
                        </div>

                        <img
                            src={measurement.imageUrl || '/assets/img/WIHV_3D_Visual_Leerpad.jpg'}
                            className="background-img"
                            alt=""
                        />

                        <div className="content overlay">
                            <h3 className="title">{measurement.title}</h3>
                            <p className="description">{measurement.description}</p>
                            {isCompleted(measurement) &&
                                <LinkButton
                                    icon="chevron-right"
                                    displayType="white"
                                    text={t('measurements:overview:measurementCardResults')}
                                    to={`/measurements/${measurement.id}/results`} />
                            }
                        </div>
                    </Link>
                </SwiperSlide>
            ))}
        </SwiperComponent>
    )
}

export default MeasurementSwiper
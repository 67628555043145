import { ConfigContext } from '@/context/ConfigContext';
import { Interest } from '@/profile/model/interest';
import debounce from "debounce-promise";
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { SingleValue } from "react-select/dist/declarations/src/types";

type SearchBarProps = {
    selectedInterest?: Interest;
    required?: boolean;
    onInterestChange: (interest: Interest) => void;
}

export default function InterestSearchBar({ selectedInterest, required = false, onInterestChange }: SearchBarProps) {

    const configContext = useContext(ConfigContext);
    const [selectedOption, setSelectedOption] = useState<SingleValue<any>[]>([]);

    useEffect(() => {

        if (selectedInterest.name) {
            
            const selectedOptions = {
                value: {
                    name: selectedInterest.name,
                    description: selectedInterest.description,
                    id: selectedInterest.id
                } as Interest,
                label: `${selectedInterest.name}`
            };

            setSelectedOption(selectedOptions as unknown as SingleValue<any>[]);
        }
    }, [selectedInterest]);

    const handleCreatedOption = (inputValue: string) => {        
        if (inputValue) {
            const selectedInterest = {
                name: inputValue,
                id: null
            } as Interest;

            setSelectedOption({label: inputValue, value: null} as unknown as SingleValue<any>[]);
            onInterestChange(selectedInterest);
            return;
        }
    }

    const handleSelectedOption = (selectedOption: any) => {

        setSelectedOption(selectedOption);
        const selectedInterest = selectedOption?.value ?? null;
        onInterestChange(selectedInterest);
    }

    const _loadInterestSuggestions = async (inputValue: string) => {

        let url = `${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.search.interests}`;

        // Add query parameters to url
        const params = new URLSearchParams({ query: inputValue } as any);
        url += `?${params}`;

        return fetch(url, {
            credentials: "same-origin",
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey,
            }
        })
            .then((response) => {                
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Invalid response");
            })
            .then((result) => {                
                return result.map((item: Interest) => {
                    return {
                        value: item,
                        label: `${item.name}`
                    };
                });
            })
            .catch(
                (error) => {

                });
    }

    const loadInterestSuggestions = debounce(_loadInterestSuggestions, 300);

    return (
        <Form.Group as={Row} className='interest-search'>
            <Col>
                <Form.Label>{t('profile:interests:labelName')}{required && <span className='required'>*</span>}</Form.Label>
                <AsyncCreatableSelect
                    isMulti={false}
                    isClearable
                    required={required}
                    placeholder={t('profile:interests:placeholderSearch')}
                    value={selectedOption}
                    onChange={(s) => handleSelectedOption(s)}
                    onCreateOption={(s) => handleCreatedOption(s)}
                    defaultOptions={false}
                    loadOptions={loadInterestSuggestions}
                    hideSelectedOptions={true}
                    className='p-0'
                    formatCreateLabel={(inputValue) => <span role='button'>{t('profile:interests:searchCreate')} "<span className='font-bold'>{inputValue}</span>"</span>}
                    noOptionsMessage={(e) => e.inputValue ? t('profile:interests:searchNoResults') : null}
                    classNames={{
                        control: () => 'search-control'
                    }}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: state.isDisabled ? '' : 'none',
                            borderRadius: '26px',
                            padding: '0.5rem',
                            boxShadow: 'none'
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: 'black',
                            cursor: 'pointer'
                        }),
                    }} />
            </Col>
        </Form.Group>
    );
};
import { Attribute } from '@/general/model/attribute';
import { DataOrigin, OriginType } from '@/general/model/data-origin';
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './AttributeDetailsModal.scss';

type AttributeDetailsModalProps = {
    showAttributeModal: boolean;
    setShowAttributeModal: (show: boolean) => void;
    attribute: Attribute;
}

function AttributeDetailsModal({ showAttributeModal, setShowAttributeModal, attribute }: AttributeDetailsModalProps) {

    function getLinkByOriginType(origin: DataOrigin): string {
        switch (origin.type) {
            case OriginType.Measurement:
                return `/measurements/${origin.value}/results`;
            case OriginType.InputFields:
                return '/profile';
            default:
                return '/';
        }
    }

    return (
        <Modal className='wihv-modal wihv-modal--info attributes-detail-modal' centered show={showAttributeModal} onHide={() => setShowAttributeModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{attribute?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {attribute?.description &&
                    <>
                        <h3 className='info-modal__subtitle mb-1'>Omschrijving</h3>
                        <p className='info-modal__description'>{attribute?.description}</p>
                    </>
                }

                {attribute?.origins && attribute?.origins.length > 0 &&
                    <>
                        <h3 className='info-modal__subtitle mt-4 mb-1'>Bronnen</h3>
                        {attribute?.origins?.map((origin, i) => (
                            <div className={`attribute-origins__origin d-flex mb-1`} key={i}>
                                <div className="icon">
                                    {origin.type === OriginType.Measurement && <i className='fas fa-clipboard-question fa-lg me-3'></i>}
                                    {origin.type === OriginType.InputFields && <i className='fas fa-user-edit fa-lg me-3'></i>}
                                    {origin.type === OriginType.Favorites && <i className='fas fa-heart fa-lg me-3'></i>}
                                    {origin.type === OriginType.Uploads && <i className='fas fa-upload fa-lg me-3'></i>}
                                    {origin.type === OriginType.Feedback && <i className='fas fa-comments fa-lg me-3'></i>}
                                </div>
                                <Link to={getLinkByOriginType(origin)} className="link">
                                    <span className={`name`}>{origin.displayValue}</span>
                                </Link>
                            </div>
                        ))}
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}

export default AttributeDetailsModal
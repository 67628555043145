import { Route, Routes } from 'react-router-dom';
import MeasurementsOverview from './MeasurementsOverview';
import MeasurementContainer from './components/MeasurementContainer';
import MeasurementResults from './components/MeasurementResults';
import PageNotFound from '@/components/not-found-component/PageNotFound';

function MeasurementsModule() {

    return (
        <Routes>
            <Route path="/">
                <Route index element={<MeasurementsOverview />} />
                <Route path={":measurementId"} element={<MeasurementContainer />} />
                <Route path={":measurementId/results"} element={<MeasurementResults />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    )
}

export default MeasurementsModule;
import CTAFullWidth from "@/components/cta/CTAFullWidth";
import { BreadcrumbsContext } from "@/context/BreadcrumbsContext";
import getRecommendedMockProfessions from "@/general/utilities/MockRecommendedProfessionsRetriever";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import useRecommendedProfessions from "@/hooks/useRecommendedProfessions";
import { RecommendedProfession } from "@/professions/model/recommended-profession";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './ProfessionsGuideOverview.scss';
import RecommendedProfessionsPodium from "./components/RecommendedProfessionsPodium";
import RecommendedProfessionsTable from "./components/RecommendedProfessionsTable";

function ProfessionsGuideOverview() {

    useDocumentTitle(t('documentTitles:professionGuide'));
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const { recommendedProfessions, mutate } = useRecommendedProfessions();
    const recommendedMockProfessions = getRecommendedMockProfessions() as RecommendedProfession[];
    const { profileCompleted } = useProfileCompletionRequirements();
    const [professions, setProfessions] = useState<RecommendedProfession[]>(recommendedMockProfessions);

    useEffect(() => {
        mutate();
    }, []);

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:professionGuide')
        }]);
    }, [setBreadcrumbs]);

    useEffect(() => {
        if (profileCompleted && recommendedProfessions && recommendedProfessions.length > 0) {
            recommendedProfessions.sort((a, b) => b.percentRecommended - a.percentRecommended);
            setProfessions(recommendedProfessions);
        } else {
            setProfessions(recommendedMockProfessions.sort((a, b) => b.percentRecommended - a.percentRecommended));
        }
    }, [recommendedProfessions, profileCompleted]);

    return (
        <div className="professions-guide">
            <div className={`${profileCompleted ? '' : 'locked-overview'}`}>

                {!profileCompleted &&
                    <Container fluid className="locked-info">
                        <Container>
                            <CTAFullWidth
                                title={t('professionsGuide:guideLockedTitle')}
                                description={t('professionsGuide:guideLockedDescription')}
                                imageSrc="/assets/img/WIHV_3D_Visual_User_Icon_Add.jpg"
                                buttonLink="/profile"
                                buttonText={t('professionsGuide:guideLockedBtn')}
                                colSpan={9}
                                colOffset={1} />
                        </Container>
                    </Container>
                }

                <Container fluid className='py-5 position-relative'>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <RecommendedProfessionsPodium recommendedProfessions={professions.slice(0, 3)} />
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className='pb-5 position-relative'>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <RecommendedProfessionsTable recommendedProfessions={professions.slice(3)} />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        </div>
    )
}

export default ProfessionsGuideOverview
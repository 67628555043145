import FileUploadControl from '@/components/file-upload-control/FileUploadControl';
import { ConfigContext } from '@/context/ConfigContext';
import { ProfileContext } from '@/context/ProfileContext';
import { useFileUploadTypeTranslation } from '@/general/i18n/translation-helpers';
import formatFileSize from '@/general/utilities/FileSizeStringFormatter';
import useAuthenticatedRequest from '@/hooks/useAuthenticatedRequest';
import { t } from 'i18next';
import { createRef, useContext, useEffect, useState } from 'react';
import { CloseButton, Form, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { mutate } from 'swr';
import { FileUpload, FileUploadType } from '../model/file-upload';
import './ProfileUploadsModal.scss';
import Button from '@/components/button/Button';

type ProfileUploadsModalProps = {
    showModal: boolean;
    onClose?: () => void;
}

function ProfileUploadsModal({ showModal = false, onClose }: ProfileUploadsModalProps) {

    const configContext = useContext(ConfigContext);
    const profileContext = useContext(ProfileContext);
    const formRef = createRef<HTMLFormElement>();
    const [validated, setValidated] = useState<boolean>(false);
    const [filesToUpload, setFilesToUpload] = useState<FileUpload[]>([]);
    const { translateFileUploadType } = useFileUploadTypeTranslation();
    const authenticatedRequest = useAuthenticatedRequest();

    const maxFileSize = 10485760; //10MB
    const maxFiles = 3;

    const getUploadTypes = () => {
        let types: any[] = [];
        const uploadTypes = Object.values(FileUploadType).filter(value => !isNaN(Number(value)));
        uploadTypes.forEach((type, index) => {
            types.push({ label: translateFileUploadType(+type), value: type });
        });
        return types;
    }

    useEffect(() => {
        if (showModal) {
            setFilesToUpload([]);
            setValidated(false);
        }
    }, [showModal]);

    function handleAcceptedFiles(files: File[]) {
        const newFiles = [...filesToUpload];
        files.forEach(file => {
            newFiles.push({
                id: '',
                fileName: file.name,
                description: '',
                type: null,
                file: file,
                uploadedAt: ''
            });
        });
        setFilesToUpload(newFiles);
    }

    function handleRejectedFiles(files: any) {

        if (files.length > maxFiles) {
            toast.error(t('profile:toasts:validationMaxFiles'));
            return;
        }

        files.forEach((file: any) => {
            if (file.file.size > maxFileSize) {
                toast.error(t('profile:toasts:validationMaxFileSize', { fileSize: formatFileSize(file.file.size), maxFileSize: formatFileSize(maxFileSize), fileName: file.file.name }));
            }
        });
    }

    function removeFile(index: number) {
        const newFiles = [...filesToUpload];
        newFiles.splice(index, 1);
        setFilesToUpload(newFiles);
    }

    function updateChanges(index: number, value: any, key: string) {
        const newFiles = [...filesToUpload];
        newFiles[index][key] = value;
        setFilesToUpload(newFiles);
    }

    function uploadFiles() {
        setValidated(true);
        if (formRef.current?.checkValidity()) {
            authenticatedRequest(`${configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.uploads.list}`, 'POST', filesToUpload)
                .then(() => {
                    toast.success(t('profile:toasts:uploadFilesSuccess'));
                    mutate(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.uploads.list);
                    onClose();
                }
                ).catch((error) => {
                    toast.error(t('profile:toasts:uploadFilesError'));
                });
        }
    }

    return (
        <Modal className='wihv-modal wihv-modal--profile profile-uploads-modal' show={showModal} size='lg' backdrop="static" centered>
            <Modal.Header>
                <Modal.Title>{t('profile:uploads:title')}</Modal.Title>
                <CloseButton variant='white' onClick={() => onClose()} />
            </Modal.Header>
            <Modal.Body>
                <Form ref={formRef} validated={validated} noValidate>

                    <p className='warning'>{t('profile:uploads:warning')}</p>

                    <FileUploadControl className='upload-control' maxFiles={maxFiles} maxFileSize={maxFileSize} onAcceptedFiles={handleAcceptedFiles} onRejectedFiles={handleRejectedFiles} />

                    <div>
                        {filesToUpload.length > 0 && <h2 className='subtitle'>{t('profile:uploads:subtitle')}</h2>}
                        {filesToUpload?.map((item, index) => (
                            <div key={index} className='upload-item'>
                                <div className='icon'>
                                    <i className="fa-regular fa-file" />
                                </div>
                                <div className='metadata'>
                                    <span className='name'>{item.file.name}</span>
                                    <span className='size'>{formatFileSize(item.file.size)}</span>
                                </div>
                                <div className='type'>
                                    <Form.Label>{t('profile:uploads:labelType')}</Form.Label>
                                    <Select
                                        placeholder={t('profile:uploads:placeholderFileUploadType')}
                                        className="file-upload-type-select"
                                        required={true}
                                        value={filesToUpload[index]?.type ? { label: translateFileUploadType(filesToUpload[index].type), value: filesToUpload[index].type } : null}
                                        isClearable={false}
                                        isSearchable={true}
                                        options={getUploadTypes()}
                                        onChange={(s) => updateChanges(index, s.value, 'type')}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                border: 'solid 1px rgba(0,0,0, 0.2)',
                                                borderRadius: '25px',
                                                padding: '0rem 0.5rem',
                                                boxShadow: 'none',
                                                maxWidth: '250px',
                                            }),
                                            option: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: '#0c1732',
                                                backgroundColor: state.isSelected ? '#f8f9fa' : state.isFocused ? '#f8f9fa' : 'white',
                                            }),
                                        }}
                                    />
                                    {validated && !filesToUpload[index]?.type && <p className='error'>{t('profile:uploads:validationTypeError')}</p>}
                                </div>
                                <button type='button' onClick={() => removeFile(index)} className="remove"><i className="fa-solid fa-trash-can" /></button>
                            </div>
                        ))}
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button displayType='secondary' text={t('general:cancel')} onClick={() => onClose()} />
                <Button displayType='primary' text={t('general:save')} loading={profileContext.requestInProgress} onClick={() => uploadFiles()} />
            </Modal.Footer>
        </Modal>
    );
}

export default ProfileUploadsModal
import { CookiePreferencesContext } from '@/context/CookiePreferencesContext';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import Button from '../button/Button';
import CookiePreferencesModal from '../cookie-preferences-modal/CookiePreferencesModal';
import './CookieConsentDisabledPlaceholder.scss';

function CookieConsentDisabledPlaceholder() {

    const cookiePreferencesContext = useContext(CookiePreferencesContext);
    const [showCookiePreferencesModal, setShowCookiePreferencesModal] = useState(false);

    useEffect(() => {
        if (cookiePreferencesContext.preferences) {
            setShowCookiePreferencesModal(false);
        }
    }, [cookiePreferencesContext.preferences]);

    return (
        <>
            <div className='cookie-consent-disabled-placeholder'>
                <div className='info-card'>
                    <img className="image" src="/assets/img/cookie-icon.png" alt="" />
                    <h3 className='title'>{t('cookieConsent:consentDisabledTitle')}</h3>
                    <p className='description'>{t('cookieConsent:consentDisabledDescription')}</p>
                    <Button onClick={() => setShowCookiePreferencesModal(true)} text={t('settings:privacy:cookiePreferencesBtn')} displayType='tertiary' />
                </div>
            </div>
            <CookiePreferencesModal showModal={showCookiePreferencesModal} onClose={() => setShowCookiePreferencesModal(false)} />
        </>
    )
}

export default CookieConsentDisabledPlaceholder
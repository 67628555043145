import { ImageSelectionMeasurementAnswer } from "@/measurements/model/measurement-answers/image-selection-measurement-answer";
import { MeasurementAnswer } from "@/measurements/model/measurement-answers/measurement-answer";
import { ImageSelectionMeasurementDefinition, ImageSelectionQuestion } from "@/measurements/model/measurement-definitions/image-selection-measurement-definition";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './ImageSelectionMeasurement.scss';

type ImageSelectionMeasurementProps = {
    measurement: ImageSelectionMeasurementDefinition;
    isSubmitting: boolean;
    onSave: (data: MeasurementAnswer) => void;
}

function ImageSelectionMeasurement({ measurement, isSubmitting, onSave }: ImageSelectionMeasurementProps) {

    const [currentQuestion, setCurrentQuestion] = useState<ImageSelectionQuestion>(measurement.questions[0]);
    const [answer, setAnswer] = useState<ImageSelectionMeasurementAnswer>({ id: measurement.id, type: measurement.type, answers: [] });
    const [selectedValue, setSelectedValue] = useState<string>("");

    useEffect(() => {
        setSelectedValue("");
    }, [currentQuestion]);

    const nextQuestion = () => {

        const index = measurement.questions.indexOf(currentQuestion);
        if (index < measurement.questions.length - 1) {
            setCurrentQuestion(measurement.questions[index + 1]);
        }
    }

    const updateAnswer = (value: string) => {

        const answer = { selectionId: currentQuestion.id, value: value };

        setAnswer((prevAnswers) => {
            const index = prevAnswers.answers.findIndex((selection) => selection.selectionId === answer.selectionId);
            if (index > -1) {
                prevAnswers.answers[index] = answer;
            } else {
                prevAnswers.answers.push(answer);
            }
            return { ...prevAnswers };
        });

        nextQuestion();
    }

    const handleSave = () => {
        onSave(answer);
    }

    return (
        <>
            <Container className="image-selection-measurement">
                <Row className="pt-5">
                    <h1 className="image-selection-measurement__title">{measurement.title}</h1>
                    <h2 className="image-selection-measurement__subtitle">{currentQuestion.title}</h2>
                    <p className="image-selection-measurement__current-question">{t('measurements:imageSelection:currentQuestion1')} <span className="font-black">{measurement.questions.indexOf(currentQuestion) + 1}</span> {t('measurements:imageSelection:currentQuestion2')} {measurement.questions.length}</p>
                </Row>
                <Row className="image-selection-measurement__question gy-3 pt-5">
                    {currentQuestion.images.map((image, i) => (
                        <Col sm={12} md={6} lg={6} className="d-flex justify-content-center">
                            <label className="image-selection-measurement__image-selection" key={`${measurement.id}-${i}`} htmlFor={`${measurement.id}-${i}`}>
                                <input
                                    className="image-selection-measurement__input"
                                    tabIndex={0}
                                    onChange={(e) => { setSelectedValue(image.value); updateAnswer(image.value) }}
                                    checked={selectedValue === image.value}
                                    type="radio"
                                    id={`${measurement.id}-${i}`}
                                    name={image.title}
                                    value={image.value} />
                                <img className="image-selection-measurement__image" src={image.url} alt={currentQuestion.title} />
                                <p className="image-selection-measurement__image-title">{image.title}</p>
                            </label>
                        </Col>
                    ))}
                </Row>
                {answer.answers.length === measurement.questions.length &&
                    <Row className="d-flex justify-content-center">
                        <button className="btn btn--oranje mt-5" disabled={isSubmitting} onClick={() => handleSave()}>
                            {t('measurements:imageSelection:saveBtn')}
                            {isSubmitting && <i className="fa-solid fa-spinner fa-lg fa-spin ms-2" />}
                            {!isSubmitting && <i className="fas fa-chevron-right ms-2" />}
                        </button>
                    </Row>
                }
            </Container>
        </>
    )
}

export default ImageSelectionMeasurement
import { ConfigContext } from '@/context/ConfigContext';
import { LearningPath } from '@/learning/models/learning-path';
import { LearningResourceStatus } from '@/learning/models/learning-resource-status';
import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import useSWR from 'swr';

const useStartedLearningPaths = () => {

    const configContext = useContext(ConfigContext);
    const isAuthenticated = useIsAuthenticated();
    const url = isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.learningPaths.list + `?status=${LearningResourceStatus.Started}` : null;
    const { data: learningPaths, isLoading: learningPathsLoading, error: learningPathsError, mutate: mutateStartedLearningPaths } = useSWR<LearningPath[]>(url);

    return { learningPaths, learningPathsLoading, learningPathsError, mutateStartedLearningPaths };
}

export default useStartedLearningPaths;
import CTAFullWidth from "@/components/cta/CTAFullWidth";
import ScrollToTopButton from "@/components/scroll-to-top/ScrollToTopButton";
import { BreadcrumbsContext } from "@/context/BreadcrumbsContext";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import useFavoriteProfessions from "@/hooks/useFavoriteProfessions";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import useRecommendedProfessions from "@/hooks/useRecommendedProfessions";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import './ProfessionsOverview.scss';
import FavoriteProfessions from "./components/FavoriteProfessions";
import ProfessionCategories from "./components/ProfessionCategories";
import ProfessionList from "./components/ProfessionList";
import RecommendedProfessions from "./components/RecommendedProfessions";

function ProfessionsOverview() {

    useDocumentTitle(t('documentTitles:professions'));
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const { favoriteProfessions } = useFavoriteProfessions();
    const { recommendedProfessions, mutate } = useRecommendedProfessions();
    const { totalCompletion } = useProfileCompletionRequirements();

    useEffect(() => {
        mutate();
    }, []);

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:professions')
        }]);
    }, [setBreadcrumbs]);

    return (

        <div className="profession-overview">

            <RecommendedProfessions />

            <FavoriteProfessions />

            <Container fluid className="pt-3 pb-5">
                <Container>
                    <ProfessionCategories />
                </Container>
            </Container>

            <Container fluid className="gradient-background py-5">
                <Container>
                    <CTAFullWidth
                        title={t('professions:overview:ctaProfileTitle')}
                        description={t('professions:overview:ctaProfileDescription')}
                        imageSrc={'/assets/img/professions/WIHV_Professions_Helpdesk.jpg'}
                        buttonText={t('professions:overview:ctaProfileBtn')}
                        buttonLink={'/profile'} />
                </Container>
            </Container>

            <Container fluid className="py-5">
                <Container>
                    <ProfessionList />
                </Container>
            </Container>

            <ScrollToTopButton section='list' />
        </div>
    )
}

export default ProfessionsOverview
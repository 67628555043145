import Button from '@/components/button/Button';
import ProgressBar from '@/components/progress-bar/ProgressBar';
import { FormMeasurementAnswer } from '@/measurements/model/measurement-answers/form-measurement-answer';
import { MeasurementAnswer } from '@/measurements/model/measurement-answers/measurement-answer';
import { FormMeasurementDefinition } from '@/measurements/model/measurement-definitions/form-measurement-definition';
import Form from '@rjsf/core';
import { RJSFValidationError } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import './FormMeasurement.scss';

type SubmitButtonProps = {
    hasNextStep: boolean;
    isSubmitting: boolean;
}

function SubmitButton({ hasNextStep, isSubmitting }: SubmitButtonProps) {

    return (
        <Button
            text={hasNextStep ? t('measurements:form:nextBtn') : t('measurements:form:saveBtn')}
            type='submit'
            displayType='primary'
            loading={isSubmitting}
            icon={`${hasNextStep ? 'arrow-right' : 'floppy-disk'}`} />
    );
}

type FormMeasurementProps = {
    measurement: FormMeasurementDefinition;
    isSubmitting: boolean;
    onSave: (data: MeasurementAnswer) => void;
}

function FormMeasurement({ measurement, isSubmitting, onSave }: FormMeasurementProps) {

    const [answer, setAnswer] = useState<FormMeasurementAnswer>({ id: measurement.id, type: measurement.type, formData: [] });
    const [currentStep, setCurrentStep] = useState(0);
    const [currentSchema, setCurrentSchema] = useState<any>({});

    const numberOfSteps = Object.keys(measurement.form.formSchema.properties).length;
    const hasNextStep = currentStep < numberOfSteps - 1;

    useEffect(() => {
        setCurrentSchema({
            ...measurement.form.formSchema,
            required: measurement.form.formSchema.required.includes(Object.keys(measurement.form.formSchema.properties)[currentStep]) ?
                [Object.keys(measurement.form.formSchema.properties)[currentStep]] : [],
            properties: {
                [Object.keys(measurement.form.formSchema.properties)[currentStep]]:
                    measurement.form.formSchema.properties[Object.keys(measurement.form.formSchema.properties)[currentStep]]
            }
        });
    }, [currentStep]);

    useEffect(() => {
        // Defer the execution to ensure .radio elements are rendered
        const timer = setTimeout(() => {
            const radioDivs = document.querySelectorAll('.radio');

            radioDivs.forEach(radioDiv => {
                const handleClick = () => {
                    const input = radioDiv.querySelector('input[type="radio"]') as HTMLInputElement;

                    if (input) {
                        input.click();
                    }
                };

                radioDiv.addEventListener('click', handleClick);

                return () => {
                    radioDiv.removeEventListener('click', handleClick);
                };
            });
        }, 50);

        return () => clearTimeout(timer);
    }, [currentSchema]);

    const handleNext = (formData: any) => {
        if (!formData) {
            return;
        }

        const answerFormData = [
            ...answer.formData,
            formData
        ];

        setAnswer({ ...answer, formData: answerFormData });
    }

    useEffect(() => {

        if (answer.formData.length === 0) {
            return;
        }

        if (hasNextStep) {
            setCurrentStep(currentStep + 1);
        }
        else {
            onSave(answer);
        }

    }, [answer]);

    return (
        <Container fluid className='measurement-content py-5'>
            <Container className='form-container'>
                <div className='form-card'>
                    <div className='progression'>
                        <div className='current'>
                            {String(currentStep + 1).padStart(2, '0')}
                        </div>
                        <div className='text'>
                            {t('measurements:form:of')}
                        </div>
                        <div className='total'>
                            {String(numberOfSteps).padStart(2, '0')}
                        </div>
                    </div>
                    <ProgressBar className='mb-4 mb-md-5' currentStep={currentStep + 1} maxSteps={numberOfSteps} />
                    <Form
                        schema={currentSchema}
                        uiSchema={measurement.form.uiSchema}
                        validator={validator}
                        onSubmit={(e) => { handleNext(e?.formData) }}
                        onError={(e) => { console.error(e) }}
                        transformErrors={transformErrors}
                        showErrorList={false}
                        focusOnFirstError={true}
                        noHtml5Validate={true}
                        children={
                            <>
                                <SubmitButton isSubmitting={isSubmitting} hasNextStep={hasNextStep} />
                            </>
                        }
                    />
                </div>
            </Container>

            <div className="background-footer">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
        </Container>
    )
}

export default FormMeasurement

function transformErrors(errors, uiSchema) {

    return errors.map((error: RJSFValidationError) => {
        if (error.name === 'required') {
            error.message = t('measurements:form:errorRequired');
        }
        if (error.name === 'maxItems') {
            error.message = t('measurements:form:errorMax', { limit: error.params.limit });
        }
        return error;
    });
}
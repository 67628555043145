import WihvLoader from '@/components/loading-component/WihvLoader';
import useStartedLearningCourses from '@/hooks/useStartedLearningCourses';
import useStartedLearningPaths from '@/hooks/useStartedLearningPaths';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import './CurrentlyLearningTile.scss';
import DashboardTile from './DashboardTile';
import LinkButton from '@/components/button/LinkButton';

type CurrentlyLearningTileProps = {
    tileClassName?: string;
}

function CurrentlyLearningTile({ tileClassName }: CurrentlyLearningTileProps) {

    const { learningCourses, learningCoursesLoading } = useStartedLearningCourses();
    const { learningPaths, learningPathsLoading } = useStartedLearningPaths();
    const hasLearningItems = learningCourses?.length > 0 || learningPaths?.length > 0;
    const isLoading = learningCoursesLoading || learningPathsLoading;

    return (
        <DashboardTile tileClassName={tileClassName} contentClassName='currently-learning-tile-content'>
            <h2 className='title'>{t('dashboard:currentlyLearning:title')}</h2>

            {isLoading &&
                <div className='d-flex h-100 align-items-center justify-content-center'>
                    <WihvLoader />
                </div>
            }

            {(!isLoading && hasLearningItems) &&
                <div className='learning-list'>

                    <h3 className='subtitle'>
                        <i className="fas fa-graduation-cap me-2"></i>
                        {t('dashboard:currentlyLearning:courses')}
                    </h3>

                    {learningCourses?.length > 0 && learningCourses?.slice(0, 2).map(course => (
                        <div key={course.id} className='learning-item'>
                            <span className='name'>{course.name}</span>
                            <Link to={`learning/course/${course.id}`} className='link'>
                                {t('dashboard:currentlyLearning:continueLearning')}
                            </Link>
                        </div>
                    ))}

                    <h3 className='subtitle'>
                        <i className="fas fa-sitemap me-2"></i>
                        {t('dashboard:currentlyLearning:learningPaths')}
                    </h3>

                    {learningPaths?.length > 0 && learningPaths?.slice(0, 2).map(path => (
                        <div key={path.id} className='learning-item'>
                            <span className='name'>{path.name}</span>
                            <Link to={`learning/path/${path.id}`} className='link'>
                                {t('dashboard:currentlyLearning:continueLearning')}
                            </Link>
                        </div>
                    ))}
                </div>
            }

            {(!isLoading && !hasLearningItems) &&
                <div className='no-learning-items'>
                    <p className='description'>
                        {t('dashboard:currentlyLearning:noLearningItems')}
                    </p>
                    <div className='link'>
                        <LinkButton displayType='secondary' to='/learning' text={t('dashboard:currentlyLearning:cta')} icon='chevron-right' />
                    </div>
                </div>
            }
        </DashboardTile>
    )
}

export default CurrentlyLearningTile
import CTAFullWidth from "@/components/cta/CTAFullWidth";
import ScrollToTopButton from "@/components/scroll-to-top/ScrollToTopButton";
import { BreadcrumbsContext } from "@/context/BreadcrumbsContext";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import './LearningOverview.scss';
import FavoriteLearningResources from "./components/FavoriteLearningResources";
import LearningResourceCategories from "./components/LearningResourceCategories";
import LearningResourceList from "./components/LearningResourceList";
import RecommendedLearningResources from "./components/RecommendedLearningResources";
import StartedLearningResources from "./components/StartedLearningResources";

function LearningOverview() {

    useDocumentTitle(t('documentTitles:learning'));
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:learning')
        }]);
    }, [setBreadcrumbs]);

    return (

        <div className="learning-overview">

            <RecommendedLearningResources />

            <StartedLearningResources />

            <FavoriteLearningResources />

            <Container fluid className="pt-3 pb-5">
                <Container>
                    <LearningResourceCategories />
                </Container>
            </Container>

            <Container fluid className="gradient-background py-5">
                <Container>
                    <CTAFullWidth
                        title={t('learning:overview:ctaProfileTitle')}
                        description={t('learning:overview:ctaProfileDescription')}
                        imageSrc={'/assets/img/professions/WIHV_Professions_Helpdesk.jpg'}
                        buttonText={t('learning:overview:ctaProfileBtn')}
                        buttonLink={'/profile'} />
                </Container>
            </Container>

            <Container fluid className="py-5">
                <Container>
                    <LearningResourceList />
                </Container>
            </Container>

            <ScrollToTopButton section='list' />
        </div>
    )
}

export default LearningOverview
import formatDuration from "@/general/utilities/DurationStringFormatter";
import { LearningPath } from "@/learning/models/learning-path";
import { t } from "i18next";
import { useEffect, useState } from "react";
import './LearningPathContent.scss';
import LearningPathCourse from "./LearningPathCourse";

type LearningPathContentProps = {
    learningPath: LearningPath;
    opened: boolean;
};

function LearningPathContent({ learningPath, opened }: LearningPathContentProps) {

    const [showCourses, setShowCourses] = useState(opened);

    useEffect(() => {
        setShowCourses(opened);
    }, [opened]);

    return (
        <div className="learning-path-content">
            <div className="header" onClick={() => setShowCourses(!showCourses)}>
                <h3 className="title">{learningPath.name}</h3>
                <button className="toggle" onClick={() => setShowCourses(!showCourses)}>
                    <i className={`fas fa-chevron-${showCourses ? 'up' : 'down'}`} />
                </button>
            </div>

            <div className="syllabus">
                <p>{learningPath.courses.length} {t('learning:pathDetail:modulesTitle')}</p>
                <p>{formatDuration(learningPath.durationInMinutes)}</p>
            </div>

            <ul className={`course-list course-list--${showCourses ? 'opened' : 'closed'}`}>
                {learningPath.courses.map((course) => (
                    <LearningPathCourse key={course.id} learningPath={learningPath} course={course} visible={showCourses} />
                ))}
            </ul>
        </div>
    );
}

export default LearningPathContent;
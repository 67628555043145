import PageNotFound from '@/components/not-found-component/PageNotFound';
import { Route, Routes } from 'react-router-dom';
import ProfessionsOverview from './ProfessionsOverview';
import ProfessionDetail from './components/ProfessionDetail';

function ProfessionsModule() {

    return (
        <Routes>
            <Route path="/">
                <Route index element={<ProfessionsOverview />} />
                <Route path={":professionId"} element={<ProfessionDetail />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    )
}

export default ProfessionsModule;
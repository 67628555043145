import { CookiePreferences } from "@/general/model/cookie-preferences";
import { createContext } from "react";

export interface ICookiePreferencesContext {
    preferences: CookiePreferences;
    acceptAll: () => void;
    denyAll: () => void;
    updatePreferences: (newPreferences: CookiePreferences) => void;
}

export const CookiePreferencesContext = createContext<ICookiePreferencesContext>(undefined);
import { useAttributeCategoryTranslation } from '@/general/i18n/translation-helpers'
import { AttributeCategory } from '@/general/model/attribute-category'
import { RecommendedProfession } from '@/professions/model/recommended-profession'
import { t } from 'i18next'
import { Table } from 'react-bootstrap'
import RecommendedProfessionTableRow from './RecommendedProfessionTableRow'
import './RecommendedProfessionsTable.scss'
import { useScreenDetector } from '@/hooks/useScreenDetector'

type RecommendedProfessionProps = {
    recommendedProfessions: RecommendedProfession[]
}

function RecommendedProfessionsTable({ recommendedProfessions }: RecommendedProfessionProps) {

    const { translateAttributeCategory } = useAttributeCategoryTranslation();
    const { isMobile, isTablet } = useScreenDetector();

    return (
        <Table>
            <thead>
                <tr>
                    <th className="th-ranking"><span className="ms-2">{t('professionsGuide:tableHeaderRanking')}</span></th>
                    <th className="th-profession">{t('professionsGuide:tableHeaderProfession')}</th>
                    <th className="th-recommended text-center">{t('professionsGuide:tableHeaderRecommended')}</th>
                    {!(isMobile || isTablet) &&
                        <>
                            <th className="text-center th-attribute-category">{translateAttributeCategory(AttributeCategory.Personality)}</th>
                            <th className="text-center th-attribute-category">{translateAttributeCategory(AttributeCategory.Skills)}</th>
                            <th className="text-center th-attribute-category">{translateAttributeCategory(AttributeCategory.Potential)}</th>
                        </>
                    }
                </tr>
            </thead>
            <tbody>
                {recommendedProfessions?.map((recommendedProfession, index) => (
                    <RecommendedProfessionTableRow key={recommendedProfession.id} ranking={index + 4} recommendedProfession={recommendedProfession} />
                ))}
            </tbody>
        </Table>
    )
}

export default RecommendedProfessionsTable
import { t } from "i18next";

function formatDuration(duration: number): string {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    if (duration < 60) {
        return `${minutes} ${t('general:minuteAbbreviation')}`;
    }

    return minutes > 0
        ? `${hours} ${t('general:hourAbbreviation')} ${minutes} ${t('general:minuteAbbreviation')}`
        : `${hours} ${t('general:hourAbbreviation')}`;
}

export default formatDuration;
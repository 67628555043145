import { ConfigContext } from "@/context/ConfigContext";
import useAuthenticatedRequest from "@/hooks/useAuthenticatedRequest";
import { Settings } from "@/settings/model/settings";
import { t } from "i18next";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { mutate } from "swr";
import { ISettingsState, SettingsContext } from "./settings-context";

function SettingsProvider({ children }) {

    const configContext = useContext(ConfigContext);
    const authenticatedRequest = useAuthenticatedRequest();

    const [contextValue, setContextValue] = useState<ISettingsState>({
        settings: undefined,
        tempChanges: undefined,
        editable: false,
        requestInProgress: false,
        updateEditable: updateEditable,
        updateChanges: updateChanges,
        updateSettings: updateSettings,
        saveSettings: saveSettings,
    });

    function updateEditable(editable: boolean) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                tempChanges: prevContextValue.settings,
                editable: editable,
            };
        });
    }

    function updateChanges(settings: Settings) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                tempChanges: settings
            };
        });
    }

    function updateSettings(settings: Settings) {
        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                settings: settings,
                tempChanges: settings,
            };
        });
    }

    function saveSettings(settings: Settings) {

        setContextValue((prevContextValue) => {
            return {
                ...prevContextValue,
                requestInProgress: true,
            };
        });

        authenticatedRequest(configContext.configBody.api.endpoints.general.settings, 'POST', settings)
            .then((response) => {
                mutate(response);
                updateSettings(response);
                updateEditable(false);
                toast.success(t('settings:toasts:saveSuccess'));
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('settings:toasts:saveFailed') + ' ' + error.message);
            })
            .finally(() => {
                setContextValue((prevContextValue) => {
                    return {
                        ...prevContextValue,
                        requestInProgress: false,
                    };
                });
            });
    };

    return (
        <SettingsContext.Provider value={contextValue}>
            {children}
        </SettingsContext.Provider>
    );
}

export default SettingsProvider;
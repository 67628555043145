import { Form, InputGroup } from 'react-bootstrap';
import './SortComponent.scss';
import { t } from 'i18next';

interface SortOption {
    value: string;
    label: string;
}

interface SortComponentProps {
    options: SortOption[];
    onSortChange: (value: string) => void;
}

function SortComponent({ options, onSortChange }: SortComponentProps) {
    return (
        <InputGroup className='sort'>
            <InputGroup.Text className='icon-left'>
                <i className="fas fa-sort"></i>
            </InputGroup.Text>
            <Form.Select defaultValue={''} onChange={(e) => onSortChange(e.target.value)}>
                <option value="" disabled>{t('general:sortingPlaceholder')}</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </Form.Select>
        </InputGroup>
    );
}

export default SortComponent;
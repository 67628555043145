import AnchorButton from '@/components/button/AnchorButton';
import { ErrorComponent } from '@/components/error-component/ErrorComponent';
import FavoriteLearningResourceToggle from '@/components/favorite-toggle/FavoriteLearningResourceToggle';
import { LoadingComponent } from '@/components/loading-component/LoadingComponent';
import { BreadcrumbsContext } from '@/context/BreadcrumbsContext';
import { ConfigContext } from '@/context/ConfigContext';
import { Breadcrumb } from '@/general/model/breadcrumb';
import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { LearningCourse } from '../models/learning-course';
import './LearningCourseDetail.scss';
import LearningResourceMetadata from './LearningResourceMetadata';

function LearningCourseDetail() {

    const configContext = useContext(ConfigContext);
    const { courseId } = useParams();
    const location = useLocation();
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);

    const { data: course, isLoading, error, mutate } = useSWR<LearningCourse>(courseId ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.learningCourses.detail.replace('{id}', courseId) : null);

    useEffect(() => {
        const breadcrumbs: Breadcrumb[] = [
            {
                label: t('documentTitles:learning'),
                path: '/learning'
            }
        ];

        if (location.state) {

            breadcrumbs.push({
                label: location.state.name,
                path: `/learning/path/${location.state.id}`
            });
        }

        breadcrumbs.push({
            label: course?.name
        });

        setBreadcrumbs(breadcrumbs);
    }, [setBreadcrumbs, course, location]);

    return (
        <>
            {isLoading &&
                <LoadingComponent message={t('learning:courseDetail:loading')} />
            }

            {error &&
                <ErrorComponent message={t('learning:courseDetail:error')} />
            }

            {course &&
                <div className='course-detail'>

                    <Container fluid className="header">
                        <Container className='py-4'>

                            <div className='header-img'>
                                <img src={course.imageUrl || '/assets/img/WIHV_3D_Visual_Leerpad.jpg'} alt='' />
                            </div>

                            <Row className='position-relative'>
                                <Col sm={12} md={12} lg={5}>
                                    <div className='info'>
                                        <span className='type'>{t('learning:overview:learningCourse')}</span>
                                        <FavoriteLearningResourceToggle resource={course} lightTheme={false} />
                                    </div>
                                    <h1 className='title'>
                                        <span>{course.name}</span>
                                    </h1>
                                    <p className='description'>{course.shortDescription}</p>
                                    <div className='actions'>
                                        <AnchorButton
                                            href={course.resourceUrl}
                                            target='_blank'
                                            text={t('learning:courseDetail:startBtn')}
                                            icon='arrow-up-right-from-square' />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <LearningResourceMetadata learningResource={course} />

                    <Container fluid className="py-5">
                        <Container>
                            <Row>
                                <Col sm={12} md={6}>
                                    <h2 className='content-title'>{t('learning:courseDetail:contentTitle')}</h2>
                                    <p>{course.description}</p>
                                </Col>
                                <Col sm={12} md={{ span: 4, offset: 2 }}>
                                    <h2 className='content-title'>{t('learning:courseDetail:skillsTitle')}</h2>
                                    <ul className='mb-4'>
                                        {course.skills.map((skill, index) => (
                                            <li key={index}><i className="fa-solid fa-circle-check me-2"></i>{skill}</li>
                                        ))}
                                    </ul>
                                    <AnchorButton
                                        href={course.resourceUrl}
                                        target='_blank'
                                        text={t('learning:courseDetail:startBtn')}
                                        icon='arrow-up-right-from-square' />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </div>
            }
        </>
    )
}

export default LearningCourseDetail
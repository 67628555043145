
function getMockImage(type: 'profession' | 'measurement' | 'learning', uniqueId: string): string {
    
    const professionImages = [
        '/assets/img/professions/WIHV_Professions_Business-Intelligence-Specialist.jpg',
        '/assets/img/professions/WIHV_Professions_Databasebeheerder.jpg',
        '/assets/img/professions/WIHV_Professions_GIS-Specialist.jpg',
        '/assets/img/professions/WIHV_Professions_Helpdesk.jpg',
        '/assets/img/professions/WIHV_Professions_Information-Security-Officer.jpg',
        '/assets/img/professions/WIHV_Professions_IT-Infrastructuur-Specialist.jpg',
        '/assets/img/professions/WIHV_Professions_Systeembeheerder.jpg'
    ];

    // Simple hash function for strings
    const hashString = (str: string) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    };

    switch (type) {
        case 'profession':
            const index = Math.abs(hashString(uniqueId)) % professionImages.length;
            return professionImages[index];
        case 'measurement':
            return '/assets/img/WIHV_3D_Visual_Leerpad.jpg';
        case 'learning':
            return '/assets/img/WIHV_3D_Visual_Leerpad.jpg';
        default:
            return '/assets/img/WIHV_3D_Visual_Leerpad.jpg';
    }

}

export default getMockImage;
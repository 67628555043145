import { LayoutContext } from '@/context/LayoutContext';
import { PlatformSettingsContext } from '@/context/PlatformSettingsContext';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NavigationDrawer.scss';
import NavigationDrawerItem from './NavigationDrawerItem';

function NavigationDrawer() {

    const { navigationFolded, navigationWidth, isMobile, setNavigationFolded } = useContext(LayoutContext);
    const platformSettingsContext = useContext(PlatformSettingsContext);

    useEffect(() => {
        if (isMobile && !navigationFolded) {
            document.body.classList.add('noscroll');
        }
        else {
            document.body.classList.remove('noscroll');
        }        
    }, [navigationFolded, isMobile]);

    return (
        <motion.div
            className={`navigation-drawer ${isMobile ? 'mobile' : ''} ${navigationFolded ? 'folded' : 'unfolded'}`}
            animate={{ width: navigationWidth }}
            transition={{ duration: 0.2 }}
        >

            <Link to='/' className='logo'>
                <AnimatePresence>
                    {navigationFolded
                        ? <img src={`/assets/img/WIHV-Icon.png`} alt='' />
                        : <motion.img
                            alt=''
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.2, delay: 0.1 }}
                            src={`/assets/img/werk-in-het-vooruitzicht-fullcolor.svg`}
                        />
                    }
                </AnimatePresence>
            </Link>

            {/* TODO */}
            {/* {platformSettingsContext.logo &&
                    <>
                        <span className='divider' />
                        <div className='logo logo-partner'>
                            <img src={platformSettingsContext.logo} alt='' />
                        </div>
                    </>
                } */}

            <nav className="navigation-drawer-nav">
                <ul className='navigation-drawer-links'>
                    <NavigationDrawerItem icon='house' title='Dashboard' link='/' />
                    <NavigationDrawerItem icon='user' title='Profiel' link='/profile' />
                    <NavigationDrawerItem icon='passport' title='WIHV paspoort' link='/development' />
                    <NavigationDrawerItem icon='list-check' title='Metingen' link='/measurements' />
                    <NavigationDrawerItem icon='laptop-code' title='Beroepen' link='/professions' />
                    <NavigationDrawerItem icon='ranking-star' title='Beroepenwijzer' link='/professions-guide' />
                    <NavigationDrawerItem icon='graduation-cap' title='Leeraanbod' link='/learning' />
                </ul>
            </nav>

            <div className='navigation-footer'>
                {navigationFolded
                    ?
                    <ul className='navigation-drawer-links'>
                        <NavigationDrawerItem icon='circle-up' title='Upgrade naar plus' link='/vouchers' />
                    </ul>
                    :
                    <section className='account-cta'>
                        <h3 className='title'>Upgrade naar plus</h3>
                        <p className='description'>
                            Ontgrendel alle functies en benut de volledige potentie van het platform.
                        </p>
                        <Link to={'/vouchers'} className='link'>
                            <i className='fas fa-arrow-right' />
                        </Link>
                    </section>
                }
                <button className='toggle' onClick={() => setNavigationFolded(!navigationFolded)}>
                    <i className={`fas fa-angles-${navigationFolded ? 'right' : 'left'}`} />
                </button>
            </div>
        </motion.div>
    );
}

export default NavigationDrawer;

import { DataOrigin, OriginType } from '@/general/model/data-origin';
import { RequirementCategory } from '@/general/model/profile-completion-requirements';
import { firstLetterLowercaseStringFormatter } from '@/general/utilities/FirstLetterLowercaseStringFormatter';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import './RequirementTaskList.scss';

type RequirementTaskListProps = {
    requirementCategory: RequirementCategory
}

function RequirementTaskList({ requirementCategory }: RequirementTaskListProps) {

    function getLinkElement(dataOrigin: DataOrigin) {

        switch (dataOrigin.type) {
            case OriginType.InputFields:
                return <Link to="/profile">{t('dashboard:requirementCategoryCompletion:inputFieldTask', { task: t(`dashboard:requirementCategoryCompletion:inputFields:${firstLetterLowercaseStringFormatter(dataOrigin.displayValue)}`)})}</Link>
            case OriginType.Measurement:
                return <Link to={`/measurements/${dataOrigin.value}`}>{t('dashboard:requirementCategoryCompletion:measurementTask', { task: dataOrigin.displayValue})}</Link>
            default:
                return <span>{dataOrigin.displayValue}</span>
        }
    }

    return (
        <div className="requirement-task-list">
            <h4 className="title">{t('dashboard:requirementCategoryCompletion:taskListTitle')}</h4>
            <ul className="list">
                {requirementCategory.requirements.filter(x => !x.completed).map((requirement, index) => (
                    <li key={index} className="task">
                        <i className="icon far fa-circle fa-sm me-2"></i>
                        {getLinkElement(requirement.origin)}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default RequirementTaskList
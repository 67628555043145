import { ErrorComponent } from '@/components/error-component/ErrorComponent';
import FavoriteLearningResourceToggle from '@/components/favorite-toggle/FavoriteLearningResourceToggle';
import LearningPathContent from '@/components/learning-path/LearningPathContent';
import { LoadingComponent } from '@/components/loading-component/LoadingComponent';
import { BreadcrumbsContext } from '@/context/BreadcrumbsContext';
import { ConfigContext } from '@/context/ConfigContext';
import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { LearningPath } from '../models/learning-path';
import './LearningPathDetail.scss';
import LearningResourceMetadata from './LearningResourceMetadata';

function LearningPathDetail() {

    const configContext = useContext(ConfigContext);
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const { pathId } = useParams();

    const { data: learningPath, isLoading, error, mutate } = useSWR<LearningPath>(pathId ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.learningPaths.detail.replace('{id}', pathId) : null);

    useEffect(() => {
        setBreadcrumbs([
            {
                label: t('documentTitles:learning'),
                path: '/'
            },
            {
                label: learningPath?.name
            }
        ]);
    }, [setBreadcrumbs, learningPath]);

    return (
        <>
            {isLoading &&
                <LoadingComponent message={t('learning:pathDetail:loading')} />
            }

            {error &&
                <ErrorComponent message={t('learning:pathDetail:error')} />
            }

            {learningPath &&
                <div className='learningPath-detail'>

                    <Container fluid className="header">
                        <Container className='py-4'>

                            <div className='header-img'>
                                <img src={learningPath.imageUrl || '/assets/img/WIHV_3D_Visual_Leerpad.jpg'} alt='' />
                            </div>

                            <Row className='position-relative'>
                                <Col sm={12} md={12} lg={5}>
                                    <div className='info'>
                                        <span className='type'>
                                            <i className='icon fas fa-sitemap me-2' />
                                            {t('learning:overview:learningPath')}
                                        </span>
                                        <FavoriteLearningResourceToggle resource={learningPath} lightTheme={false} />
                                    </div>
                                    <h1 className='title'>
                                        <span>{learningPath.name}</span>
                                    </h1>
                                    <p className='description'>{learningPath.shortDescription}</p>
                                    <div className='actions'>
                                        <a className='btn btn--oranje' href={learningPath.resourceUrl} target='_blank'>
                                            {t('learning:pathDetail:startBtn')}
                                            <i className='fas fa-arrow-up-right-from-square ms-3' />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <LearningResourceMetadata learningResource={learningPath} />

                    <Container fluid className="py-5">
                        <Container>
                            <Row className='mb-5'>
                                <Col sm={12}>
                                    <h2 className='content-title'>{t('learning:pathDetail:contentTitle')}</h2>
                                    <p>{learningPath.description}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <LearningPathContent learningPath={learningPath} opened={true} />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </div>
            }
        </>
    )
}

export default LearningPathDetail
import { ConfigContext } from '@/context/ConfigContext';
import { MeasurementDefinition } from '@/measurements/model/measurement-definitions/measurement-definition';
import { useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import useSWR from 'swr';
import useAuthenticatedPost from './useAuthenticatedRequest';
import useAuthenticatedRequest from './useAuthenticatedRequest';

const useFavoriteMeasurements = () => {

    const configContext = useContext(ConfigContext);
    const authenticatedRequest = useAuthenticatedRequest();
    const isAuthenticated = useIsAuthenticated();
    const { data: favoriteMeasurements, isLoading, error, mutate } = useSWR<MeasurementDefinition[]>(isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.measurements.favourites : null);

    function toggleFavorite(measurement: MeasurementDefinition) {
        if (favoriteMeasurements === undefined) return;

        const currentlyFavorited = favoriteMeasurements.findIndex(x => x.id === measurement.id) !== -1;

        authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.measurements.favourite, 'POST', { id: measurement.id, isFavorite: currentlyFavorited })
            .then(() => {
                currentlyFavorited ? toast.success(t('measurements:toasts:favoriteRemoved')) : toast.success(t('measurements:toasts:favoriteAdded'));
                mutate(currentlyFavorited ? favoriteMeasurements.filter(x => x.id !== measurement.id) : [...favoriteMeasurements, measurement]);
            })
            .catch(() => {
                toast.error(t('measurements:toasts:favoriteFailed'));
            });
    }

    return { toggleFavorite, isLoading, error, favoriteMeasurements };
}

export default useFavoriteMeasurements;
import { BreadcrumbsContext } from '@/context/BreadcrumbsContext';
import { LayoutContext } from '@/context/LayoutContext';
import { useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LinkButton from '../button/LinkButton';
import './PageNotFound.scss';

function PageNotFound() {

    const isAuthenticated = useIsAuthenticated();
    const { setStickyHeader } = useContext(LayoutContext);
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);

    useEffect(() => {
        setStickyHeader(true);

        return () => {
            setStickyHeader(false);
        }
    }, [setStickyHeader]);

    useEffect(() => {
        setBreadcrumbs([
            {
                label: t('notFoundPage:headerTitle')
            }
        ]);
    }, [setBreadcrumbs]);

    return (
        <div className="page-not-found">
            <Container fluid>
                <Container>
                    <Row className="content pt-5 pb-4">
                        <Col sm={12} md={5} className='mb-4 mb-md-0'>
                            <img src="/assets/img/WIHV_3D_Visual_Computer_Error.jpg" alt="" className='image' />
                        </Col>

                        <Col sm={12} md={{ span: 5, offset: 1 }}>
                            <h1 className='mb-4'>{t('notFoundPage:title')}</h1>
                            <p className='mb-4'>{t('notFoundPage:description')}</p>

                            <LinkButton
                                icon='chevron-right'
                                to={isAuthenticated ? '/profile' : '/'}
                                displayType='primary'
                                text={isAuthenticated ? t('notFoundPage:profileBtn') : t('notFoundPage:homeBtn')} />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default PageNotFound
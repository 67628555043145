
import { ConfigContext } from '@/context/ConfigContext';
import { Attribute } from '@/general/model/attribute';
import { useContext } from 'react';
import useSWR from 'swr';

const useProfileAttributes = () => {
    
    const configContext = useContext(ConfigContext);
    const { data: profileAttributes, isLoading, error, mutate } = useSWR<Attribute[]>(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.attributes.list, { shouldRetryOnError: false });

    return { isLoading, error, profileAttributes, mutate };
}

export default useProfileAttributes;

import { ConfigContext } from '@/context/ConfigContext';
import { ProfileCompletionRequirements } from '@/general/model/profile-completion-requirements';
import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import useSWR from 'swr';

const useProfileCompletionRequirements = () => {
    
    const configContext = useContext(ConfigContext);
    const isAuthenticated = useIsAuthenticated();
    const { data: profileCompletionRequirements, isLoading, error, mutate } = useSWR<ProfileCompletionRequirements>(isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.profile.completionRequirements : null);

    const totalCompletion = Math.round(profileCompletionRequirements?.requirementCategories.reduce((acc, x) => acc + x.percentCompleted, 0) / profileCompletionRequirements?.requirementCategories.length) || 0;
    const profileCompleted = totalCompletion === 100;

    return { profileCompletionRequirements, totalCompletion, profileCompleted, isLoading, error, mutate };
}

export default useProfileCompletionRequirements;
import './DashboardTile.scss';

type DashboardTileProps = {
    tileClassName?: string;
    contentClassName?: string;
    imgSrc?: string;
    children: React.ReactNode;
}

function DashboardTile({ tileClassName, contentClassName = '', imgSrc = null, children }: DashboardTileProps) {
    return (
        <div className={`dashboard-tile ${tileClassName}`}>

            {imgSrc &&
                <div className="background-image">
                    <img src={imgSrc} alt="" />
                </div>
            }

            <div className={`content ${contentClassName}`}>
                {children}
            </div>
        </div>
    )
}

export default DashboardTile
import { ConfigContext } from '@/context/ConfigContext';
import getToken from '@/general/token-retriever';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import axios from 'axios';
import { t } from 'i18next';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';

const useAuthenticatedRequest = () => {
    
    const navigate = useNavigate();
    const msalContext = useMsal();
    const configContext = useContext(ConfigContext);
    const isAuthenticated = useIsAuthenticated();

    const request = async (url: string, method: 'POST' | 'PUT' | 'DELETE', data: any = null) => {

        const token = isAuthenticated ? await getToken(msalContext, configContext) : null;
        const savedLanguage = localStorage.getItem('selectedLanguage');

        const response = await axios({
            url,
            method,
            data,
            headers: {
                'Authorization': isAuthenticated ? `${token}` : '',
                'Accept': 'application/json',
                'Accept-Language': savedLanguage,
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey,
            }
        });

        if (response.status === 401) {
            toast.error(t('unauthorizedAccess'));
            return navigate("/");
        }

        return response.data;
    };

    return request;
};

export default useAuthenticatedRequest;
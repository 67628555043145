import { t } from "i18next";
import Dropzone from "react-dropzone";
import './FileUploadControl.scss';

type FileUploadProps = {
    className?: string
    maxFiles?: number
    maxFileSize?: number
    onAcceptedFiles: (files: File[]) => void
    onRejectedFiles: (files: any) => void
}

function FileUploadControl({ className, maxFiles = 1, maxFileSize = 10485760, onAcceptedFiles, onRejectedFiles }: FileUploadProps) {

    return (
        <Dropzone maxFiles={maxFiles} maxSize={maxFileSize} onDrop={(acceptedFiles, fileRejections, event) => { onAcceptedFiles(acceptedFiles); onRejectedFiles(fileRejections); }}>
            {({ getRootProps, getInputProps, isFocused, isDragAccept, isDragReject }) => (
                <div {...getRootProps({ className: `drop-zone ${className} ${isFocused ? 'drop-zone--is-focused' : ''} ${isDragAccept ? 'drop-zone--is-accept' : ''} ${isDragReject ? 'drop-zone--is-reject' : ''}` })}>
                    <input {...getInputProps()} />
                    <p className='drop-text'>{t('profile:uploads:dropText')}</p>
                </div>
            )}
        </Dropzone>
    );
}

export default FileUploadControl;
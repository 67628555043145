import { ConfigContext } from '@/context/ConfigContext';
import { Profession } from '@/professions/model/profession';
import { useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import useSWR from 'swr';
import useAuthenticatedRequest from './useAuthenticatedRequest';

const useFavoriteProfessions = () => {

    const configContext = useContext(ConfigContext);
    const authenticatedRequest = useAuthenticatedRequest();
    const isAuthenticated = useIsAuthenticated();

    const { data: favoriteProfessions, isLoading, error, mutate } = useSWR<Profession[]>(isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.professions.favourites : null);

    function toggleFavorite(profession: Profession) {
        if (favoriteProfessions === undefined) return;

        const currentlyFavorited = favoriteProfessions.findIndex(x => x.id === profession.id) !== -1;

        authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.professions.favourite, 'POST' , { id: profession.id, isFavorite: currentlyFavorited })
            .then(() => {
                currentlyFavorited ? toast.success(t('professions:toasts:favoriteRemoved')) : toast.success(t('professions:toasts:favoriteAdded'));
                mutate(currentlyFavorited ? favoriteProfessions.filter(x => x.id !== profession.id) : [...favoriteProfessions, profession]);
            })
            .catch(() => {
                toast.error(t('professions:toasts:favoriteFailed'));
            });
    }

    return { toggleFavorite, isLoading, error, favoriteProfessions };
}

export default useFavoriteProfessions;
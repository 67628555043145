import { t } from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import './ErrorComponent.scss';
import { BreadcrumbsContext } from '@/context/BreadcrumbsContext';
import { LayoutContext } from '@/context/LayoutContext';
import { useContext, useEffect } from 'react';
import AnchorButton from '../button/AnchorButton';

type ErrorComponentProps = {
    message?: string;
}

export function ErrorComponent({ message }: ErrorComponentProps) {

    const { setStickyHeader } = useContext(LayoutContext);
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);

    useEffect(() => {
        setStickyHeader(true);

        return () => {
            setStickyHeader(false);
        }
    }, [setStickyHeader]);

    useEffect(() => {
        setBreadcrumbs([
            {
                label: t('errorPage:headerTitle')
            }
        ]);
    }, [setBreadcrumbs]);

    return (
        <div className="page-error">
            <Container fluid>
                <Container>
                    <Row className="content pt-5 pb-4">
                        <Col sm={12} md={5} className='mb-4 mb-md-0'>
                            <img src="/assets/img/WIHV_3D_Visual_Computer_Error.jpg" alt="" className='image' />
                        </Col>

                        <Col sm={12} md={{ span: 6, offset: 1 }}>
                            <h1 className='mb-4'>{t('errorPage:title')}</h1>
                            <p className='mb-4'>{t('errorPage:description')}</p>
                            {
                                message &&
                                <p className='pb-4'>{message}</p>
                            }
                            <AnchorButton
                                onClick={() => window.location.reload()}
                                text={t('errorPage:retryBtn')}
                                icon='chevron-right' />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}

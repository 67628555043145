import { AttributeCategory } from "./attribute-category"
import { DataOrigin } from "./data-origin"

export interface BaseAttribute {
    id: string
    category: AttributeCategory
    name: string
    value: number
    description: string
    origins: DataOrigin[]
}

export interface PersonalityAttribute extends BaseAttribute {
    subCategory: PersonalitySubCategory
}

export interface SkillsAttribute extends BaseAttribute {
    subCategory: SkillsSubCategory
}

export interface PotentialAttribute extends BaseAttribute {
    subCategory: PotentialSubCategory
}

export type Attribute = PersonalityAttribute | SkillsAttribute | PotentialAttribute;

export enum PersonalitySubCategory {
    Identity = 10,
    Behavior = 20,
    Values = 30,
    Interests = 40,
    Goals = 50
}

export enum SkillsSubCategory {
    Knowledge = 10,
    Skills = 20,
}

export enum PotentialSubCategory {
    Growth = 10,
    Potential = 20,
    Incentives = 30,
}
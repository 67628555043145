
import PageNotFound from '@/components/not-found-component/PageNotFound';
import { Route, Routes } from 'react-router-dom';
import ReflectionOverview from './ReflectionOverview';

function EvaluationModule() {
    return (
        <Routes>
            <Route path="/reflection" element={<ReflectionOverview />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default EvaluationModule;
import { FilterOption } from '@/components/filter-component/FilterComponent';
import { ProfessionSearchDocument } from '@/components/search/models/profession-search-document';
import { ConfigContext } from '@/context/ConfigContext';
import { Profession } from '@/professions/model/profession';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';

const useAllProfessions = () => {

    const configContext = useContext(ConfigContext);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchterm, setSearchterm] = useState<string>('');
    const [sorting, setSorting] = useState<string>('');
    const [filter, setFilter] = useState<FilterOption[]>([]);
    const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);

    const [professions, setProfessions] = useState<Profession[]>([]);

    useEffect(() => {

        if (isLoading) return;
        sortAndFilterProfessions();

    }, [page, searchterm, sorting, filter]);

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const updateSearchterm = useCallback(
        debounce((value) => {
            setPage(1);
            setSearchterm(value);
        }, 400), []
    );

    const updateSorting = (value: string) => {
        setPage(1);
        setSorting(value);
    }

    const updateFilters = (value: FilterOption[]) => {
        setPage(1);
        setFilter(value);
    }

    const getFilterExpression = () => {

        const savedLanguage = localStorage.getItem('selectedLanguage') ?? 'nl';
        let filterString = `language eq '${savedLanguage}'`;

        if (filter.length > 0) {
            filter.forEach((filterOption) => {
                if (filterOption.category === t('professions:overview:listFilterProfessionGroupCategory')) {
                    filterString += ` and professionGroups/any(t: t eq '${filterOption.id}')`;
                }
            });
        }

        return filterString;
    }

    const sortAndFilterProfessions = async () => {

        setIsLoading(true);

        const body = {
            count: true,
            facets: ['professionGroups'],
            filter: getFilterExpression(),
            select: 'professionId, name, shortName, description, professionGroups',
            search: searchterm,
            skip: (page - 1) * 10,
            top: 10
        };

        if (sorting) {
            const [field, order] = sorting.split(' ');
            body['orderby'] = `${field} ${order}`;
        }

        fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.search.professions}`, {
            credentials: "same-origin",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((data) => {

                const professions: Profession[] = data?.value?.map((profession: ProfessionSearchDocument) => {
                    return {
                        id: profession.professionId,
                        name: profession.name,
                        shortName: profession.shortName,
                        description: profession.description,
                        created: profession.created,
                        modified: profession.modified
                    }
                });

                setTotalCount(data['@odata.count']);

                if (filterOptions.length === 0) {
                    setFilterOptions(data['@search.facets'].professionGroups.map((facet: any) => {
                        return { id: facet.value, label: facet.value, type: 'checkbox', value: facet.count, category: t('professions:overview:listFilterProfessionGroupCategory') }
                    }));
                }

                setProfessions((prevProfessions) => {
                    if (page === 1) {
                        return professions;
                    }
                    return [...prevProfessions, ...professions];
                });
                setIsLoading(false);
            })
            .catch((error) => {
                setProfessions([]);
                setIsLoading(false);
                console.error(error);
                //TODO: handle error
            });
    }

    return { professions, totalCount, isLoading, filterOptions, updateSearchterm, updateSorting, updateFilters, loadMore };
}

export default useAllProfessions;
import { ConfigContext } from '@/context/ConfigContext';
import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import useSWR from 'swr';
import { LearningResourceCategory } from '../models/learning-resource-category';
import './LearningResourceCategories.scss';
import LearningResourceSwiper from './LearningResourceSwiper';
import SkeletonSwiperComponent from '@/components/swiper-component/SkeletonSwiperComponent';

function LearningResourceCategories() {

    const isAuthenticated = useIsAuthenticated();
    const configContext = useContext(ConfigContext);

    const baseUrl = isAuthenticated ? configContext.configBody.api.baseUrl : configContext.configBody.api.publicBaseUrl;
    const { data: categories, isLoading, error, mutate } = useSWR<LearningResourceCategory[]>(baseUrl + configContext.configBody.api.endpoints.learning.categories);

    return (
        <div className='learningResource-categories'>
            {categories?.map((category, index) => {
                return (
                    <div key={index} className="learningResource-categories__category">
                        <h2 className="learningResource-categories__category-title">{category.title}</h2>
                        <LearningResourceSwiper resources={category.resources} />
                    </div>
                )
            })}

            {
                isLoading &&
                <>
                    <SkeletonSwiperComponent />
                    <SkeletonSwiperComponent />
                </>
            }
        </div>
    )
}

export default LearningResourceCategories
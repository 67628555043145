import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import './ADComponent.scss';

export function ADErrorComponent({ error }) {
    const { t } = useTranslation();

    return (
        <div className='ad-info'>
            <div className='ad-info__bg-img'>
                <img src={'/assets/img/WIHV_3D_Visual_Profiel_OUD.jpg'} style={{ objectPosition: 'center center' }} alt='' />
            </div>

            <div className="ad-info__content">
                <Link to='/' className="mb-5">
                    <img src={`/assets/img/werk-in-het-vooruitzicht-fullcolor.svg`} alt='' />
                </Link>
                <div className="h3 font-bold pb-4">{t('activeDirectory:error')}</div>
                <p className="pb-4">{error ? error.errorCode : t('activeDirectory:unknownError')}</p>
                <a href='/' className='btn btn-secondary'>{t('activeDirectory:retryLink')}</a>
            </div>
        </div>
    );
}
import { createContext } from "react";

export interface ILayoutContext {
    isMobile: boolean;
    headerHeight: string;
    stickyHeader: boolean;
    navigationWidth: string;
    navigationFolded: boolean;
    setNavigationFolded: (folded: boolean) => void;
    setStickyHeader: (sticky: boolean) => void;
}

export const LayoutContext = createContext<ILayoutContext>(undefined);

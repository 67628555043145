import Skeleton from 'react-loading-skeleton'
import './SkeletonSwiperComponent.scss'

function SkeletonSwiperComponent() {
    return (
        <div className='skeleton-swiper'>
            <Skeleton height={32} width={300} baseColor='rgba(255,255,255,0.6)' highlightColor='rgba(255,255,255,0.8)' className='title' />
            <Skeleton borderRadius={25} count={3} inline={true} baseColor='rgba(255,255,255,0.6)' highlightColor='rgba(255,255,255,0.8)' containerClassName='swiper' />
        </div>
    )
}

export default SkeletonSwiperComponent
import AttributeEvaluation from "@/evaluation/components/attribute-evaluation/AttributeEvaluation";
import UnsavedChangesGuard from "@/components/unsaved-changes-guard/UnsavedChangesGuard";
import { ConfigContext } from "@/context/ConfigContext";
import { Evaluation } from "@/development/models/evaluation";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import './FeedbackOverview.scss';
import { AttributeFeedback } from "@/general/model/attribute-feedback";

function FeedbackOverview() {

    useDocumentTitle(t('documentTitles:feedback'));

    const configContext = useContext(ConfigContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const hash = queryParams.get('hash');

    const [loading, setLoading] = useState<boolean>(true);
    const [started, setStarted] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [evaluation, setEvaluation] = useState<Evaluation>(null);

    useEffect(() => {

        if (hash) {
            validateAndRetrieveEvaluation(hash);
        } else {
            setError(t('feedback:noHashFound'));
            setLoading(false);
        }

    }, []);

    async function validateAndRetrieveEvaluation(hash) {

        try {
            const response = await fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.evaluations.validate}`, {
                credentials: "same-origin",
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
                },
                body: JSON.stringify({
                    hash: hash
                })
            });

            if (!response.ok) {
                throw new Error("Invalid response");
            }

            const data = await response.json();
            setEvaluation(data);
        } catch (error) {
            setError(error.toString());
        } finally {
            setLoading(false);
        }
    };

    function handleSaveFeedback(feedbacks: AttributeFeedback[]) {

        const updatedEvaluation = {
            ...evaluation,
            attributeFeedbacks: feedbacks
        } as Evaluation;

        setIsSubmitting(true);

        return fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.evaluations.detail}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
            },
            body: JSON.stringify(updatedEvaluation)
        })
            .then((response) => {
                if (response.ok) {
                    setSaved(true);
                    toast.error(t('evaluation:toasts:saveFeedbackSuccess'));
                    return response.json();
                }

                throw new Error("Invalid response");
            })
            .catch((error) => {
                toast.error(t('evaluation:toasts:saveFeedbackFailed'));
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    return (
        <div className="feedback-overview">

            <div className='background-img'>
                <img src='/assets/img/WIHV_3D_Visual_Blauw_Wit.jpg' style={{ objectPosition: 'center center' }} alt='' />
            </div>

            <Container fluid className='feedback-overview__content py-5'>
                <Container>

                    {loading &&
                        <Row className='position-relative pt-5'>
                            <Col sm={12} md={12} lg={5} xl={5} xxl={5} className='d-flex flex-column text-wit'>
                                <div className="font-heavy h1 pb-3">{t('evaluation:feedback:loadingTitle')}</div>
                                <p className="pb-4">
                                    {t('evaluation:feedback:loadingDescription')}
                                </p>
                            </Col>
                        </Row>
                    }

                    {!started && evaluation &&
                        <Row className='position-relative pt-5'>
                            <Col sm={12} md={12} lg={5} xl={5} xxl={5} className='d-flex flex-column text-wit'>
                                <div className="font-heavy h1 pb-3">{t('evaluation:feedback:startTitle', { name: evaluation.createdBy })}</div>
                                <p className="h4 text-geel font-bold pb-3">{t('evaluation:feedback:startSubtitle')}</p>
                                <p className="pb-4">
                                    {t('evaluation:feedback:startDescription')}
                                </p>
                                <button className="btn btn--oranje" onClick={() => setStarted(true)}>
                                    <span>{t('evaluation:feedback:startBtn')}</span>
                                    <i className="fas fa-chevron-right ms-3" />
                                </button>
                            </Col>
                        </Row>
                    }

                    {error &&
                        <Row className='position-relative pt-5'>
                            <Col sm={12} md={12} lg={5} xl={5} xxl={5} className='d-flex flex-column text-wit'>
                                <div className="font-heavy h1 pb-3">{t('evaluation:feedback:noEvaluationTitle')}</div>
                                <p className="pb-4">
                                    {t('evaluation:feedback:noEvaluationDescription')}
                                </p>
                            </Col>
                        </Row>
                    }

                    {started && evaluation && !saved &&
                        <>
                            <Col sm={12} md={{span: 6, offset: 3}} className='intro'>
                                <h1 className="title">{t('evaluation:feedback:title')}</h1>
                                <h2 className="subtitle">{t('evaluation:feedback:subtitle', { name: evaluation.createdBy })}</h2>
                                <p className="description">{t('evaluation:feedback:description')}</p>
                            </Col>
                            <AttributeEvaluation evaluation={evaluation} isSubmitting={isSubmitting} onSave={(feedbacks) => handleSaveFeedback(feedbacks)} />
                        </>
                    }

                    {saved &&
                        <Row className='position-relative'>
                            <Col sm={12} md={12} lg={5} xl={5} xxl={5} className='d-flex flex-column text-wit'>
                                <div className="font-heavy h1 pb-5">{t('evaluation:feedback:savedTitle')}</div>
                                <Link className="button" to="/"><span>{t('evaluation:feedback:savedLink')}</span><i className="fas fa-chevron-right ms-3" /></Link>
                            </Col>
                        </Row>
                    }

                </Container>
            </Container>

            <UnsavedChangesGuard shouldBlock={started && !saved} />
        </div>

    )
}

export default FeedbackOverview
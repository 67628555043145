import { ConfigContext } from '@/context/ConfigContext';
import { LearningCourse } from '@/learning/models/learning-course';
import { LearningResourceStatus } from '@/learning/models/learning-resource-status';
import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import useSWR from 'swr';

const useStartedLearningCourses = () => {

    const configContext = useContext(ConfigContext);
    const isAuthenticated = useIsAuthenticated();
    const url = isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.learningCourses.list + `?status=${LearningResourceStatus.Started}` : null;
    const { data: learningCourses, isLoading: learningCoursesLoading, error: learningCoursesError, mutate: mutateStartedLearningCourses } = useSWR<LearningCourse[]>(url);

    return { learningCourses, learningCoursesLoading, learningCoursesError, mutateStartedLearningCourses };
}

export default useStartedLearningCourses;

import Button from "@/components/button/Button";
import FormCard from "@/components/cards/FormCard";
import ConfirmModal from "@/components/confim-modal/ConfirmModal";
import CookiePreferencesModal from "@/components/cookie-preferences-modal/CookiePreferencesModal";
import { ConfigContext } from "@/context/ConfigContext";
import { CookiePreferencesContext } from "@/context/CookiePreferencesContext";
import { saveAs } from 'file-saver';
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import useSWR from "swr";

function PrivacySettings() {

    const configContext = useContext(ConfigContext);
    const cookiePreferencesContext = useContext(CookiePreferencesContext);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showCookiePreferencesModal, setShowCookiePreferencesModal] = useState(false);
    const { isLoading, mutate } = useSWR(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.profile.download, { shouldRetryOnError: false, revalidateOnMount: false });

    useEffect(() => {
        if (cookiePreferencesContext.preferences) {
            setShowCookiePreferencesModal(false);
        }
    }, [cookiePreferencesContext.preferences]);

    const handleDownload = async () => {

        const data = await mutate();
        setShowConfirmModal(false);

        if (data) {
            toast.success(t('settings:toasts:downloadProfileDataSuccess'));
            const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'text/plain' });
            saveAs(blob, 'wihv-profile.txt');
        } else {
            toast.error(t('settings:toasts:downloadProfileDataFailed'));
        }
    };

    return (
        <>
            <FormCard title={t('settings:privacy:title')}>
                <p>{t('settings:privacy:description')}</p>
                <div className="mt-4 mb-5">
                    <div className="h5 font-bold mb-2">{t('settings:privacy:downloadProfileDataTitle')}</div>
                    <p className="mb-3">{t('settings:privacy:downloadProfileDataDescription')}</p>
                    <Button text={t('settings:privacy:downloadProfileDataBtn')} onClick={() => setShowConfirmModal(true)} displayType="secondary" icon="download" />
                </div>
                <div className="">
                    <div className="h5 font-bold mb-2">{t('settings:privacy:cookiePreferencesTitle')}</div>
                    <p className="mb-3">{t('settings:privacy:cookiePreferencesDescription')}</p>
                    <Button text={t('settings:privacy:cookiePreferencesBtn')} onClick={() => setShowCookiePreferencesModal(true)} displayType="secondary" icon="cookie" />
                </div>
            </FormCard>

            <ConfirmModal
                title={t('settings:privacy:downloadProfileDataTitle')}
                description={t('settings:privacy:confirmDownloadProfileDataDescription')}
                showModal={showConfirmModal}
                isSubmitting={isLoading}
                onConfirm={handleDownload}
                onClose={() => setShowConfirmModal(false)} />

            <CookiePreferencesModal showModal={showCookiePreferencesModal} onClose={() => setShowCookiePreferencesModal(false)} />
        </>
    )
}

export default PrivacySettings
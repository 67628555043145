import { IMsalContext } from "@azure/msal-react";
import { IConfigContext } from "@/context/ConfigContext";

async function getToken(msalContext: IMsalContext, configContext: IConfigContext): Promise<string> {
    try {
        const result = await msalContext.instance.acquireTokenSilent({
            account: msalContext.accounts[0],
            scopes: [...configContext?.configBody?.api?.endpoints?.scopes],
        });
        return `Bearer ${result.accessToken}`;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export default getToken;
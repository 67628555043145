import { FilterOption } from '@/components/filter-component/FilterComponent';
import { ConfigContext } from '@/context/ConfigContext';
import { MeasurementDefinition } from '@/measurements/model/measurement-definitions/measurement-definition';
import { useIsAuthenticated } from '@azure/msal-react';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

const useAllMeasurements = () => {

    const configContext = useContext(ConfigContext);
    const isAuthenticated = useIsAuthenticated();
    const endpoint = (isAuthenticated ? configContext.configBody.api.baseUrl : configContext.configBody.api.publicBaseUrl) + configContext.configBody.api.endpoints.measurements.list;
    const { data, isLoading, error, mutate } = useSWR<MeasurementDefinition[]>(endpoint);

    const [searchterm, setSearchterm] = useState<string>('');
    const [sorting, setSorting] = useState<string>('');
    const [filter, setFilter] = useState<FilterOption[]>([]);

    const [measurements, setMeasurements] = useState<MeasurementDefinition[]>([]);
    const [filteredMeasurements, setFilteredMeasurements] = useState<MeasurementDefinition[]>([]);

    useEffect(() => {
        if (!data) return;
        setMeasurements(data);
    }, [data]);

    useEffect(() => {
        sortAndFilterMeasurements();
    }, [measurements, searchterm, sorting, filter]);

    const updateSearchterm = useCallback(
        debounce((value) => setSearchterm(value), 400), []
    );

    const updateSorting = (value: string) => {
        setSorting(value);
    }

    const updateFilters = (value: FilterOption[]) => {
        setFilter(value);
    }

    const sortAndFilterMeasurements = () => {

        let measurementResult = [...measurements];

        if (searchterm) {
            measurementResult = measurementResult.filter((measurement) =>
                measurement.title.toLowerCase().includes(searchterm.toLowerCase())
            );
        }

        if (sorting) {
            const [field, order] = sorting.split(' ');
            if (field === 'title') {
                measurementResult = measurementResult.sort((a, b) => {
                    if (order === 'asc') {
                        return a.title.localeCompare(b.title);
                    } else {
                        return b.title.localeCompare(a.title);
                    }
                });
            }
            if (field === 'duration') {
                measurementResult = measurementResult.sort((a, b) => {
                    if (order === 'asc') {
                        return a.durationInMinutes - b.durationInMinutes;
                    } else {
                        return b.durationInMinutes - a.durationInMinutes;
                    }
                });
            }
        }

        if (filter.length > 0) {
            filter.forEach((filterOption) => {
                if (filterOption.id === 'maxDurationInMinutes') {
                    measurementResult = measurementResult.filter((measurement) => {
                        return measurement.durationInMinutes <= filterOption.value;
                    });
                }
            });
            
            const completedFilter = filter.find((filterOption) => filterOption.id === 'completed');
            const notCompletedFilter = filter.find((filterOption) => filterOption.id === 'notCompleted');
            if (completedFilter && !notCompletedFilter) {
                measurementResult = measurementResult.filter((measurement) => measurement.completed);
            }
            if (notCompletedFilter && !completedFilter) {
                measurementResult = measurementResult.filter((measurement) => !measurement.completed);
            }
        }

        setFilteredMeasurements(measurementResult);
    }


    return { filteredMeasurements, isLoading, error, updateSearchterm, updateSorting, updateFilters };
}

export default useAllMeasurements;
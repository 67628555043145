import { Swiper } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import './SwiperComponent.scss';

type SwiperComponentProps = {
    slidesPerView?: number | 'auto';
    spaceBetween?: number;
    scrollbar?: boolean;
    loop?: boolean;
    children: React.ReactNode;
}

function SwiperComponent({ slidesPerView = 'auto', spaceBetween = 16, scrollbar = true, loop = true, children }: SwiperComponentProps) {

    return (
        <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={spaceBetween}
            loop={loop}
            scrollbar={scrollbar && {
                hide: !scrollbar,
                draggable: scrollbar,
            }}
            navigation={true}
            modules={[Navigation, Scrollbar]}
            className="swiper-container"
        >
            {children}
        </Swiper>
    )
}

export default SwiperComponent
import { ProfileContext } from '@/context/ProfileContext';
import { useLanguageLevelTranslation } from '@/general/i18n/translation-helpers';
import { Language } from '@/profile/model/language';
import { LanguageLevel } from '@/profile/model/language-level';
import { Profile } from '@/profile/model/profile';
import { t } from 'i18next';
import { createRef, useContext, useState } from 'react';
import { Card, CloseButton, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import './ProfileCard.scss';
import { motion } from 'framer-motion';
import Button from '@/components/button/Button';
import { useMissingProfileCardRequirements } from '@/hooks/useMissingProfileCardRequirements';

const languages: string[] = ["nl", "en", "de", "fr", "es", "ua"];

type LanguagesFormProps = {
    index: number;
    tempChanges: Profile;
    languages: string[];
    onUpdate: (event: any) => void;
};

const LanguagesForm = ({ index, tempChanges, languages, onUpdate }: LanguagesFormProps) => {

    const { translateLanguageLevel } = useLanguageLevelTranslation();

    const getLanguageLevels = () => {
        let levels: any[] = [];
        const languageLevels = Object.values(LanguageLevel).filter(value => !isNaN(Number(value)));
        languageLevels.forEach((level, index) => {
            levels.push({ label: translateLanguageLevel(+level), value: level });
        });
        return levels;
    }

    const handleLanguageChange = (event: any, index: number) => {
        if (event.target.selectedIndex != 0) {

            let isoCode = event.target.selectedOptions[0]?.value;
            tempChanges.languages[index].isoCode = isoCode;
            onUpdate(tempChanges.languages);
        }
    }

    const handleLanguageProficiencyChange = (ll: LanguageLevel, index: number, property: string) => {
        tempChanges.languages[index][property] = ll;
        onUpdate(tempChanges.languages);
    }

    const removeLanguage = (index: number) => {
        tempChanges.languages = tempChanges.languages.filter((item, i) => i !== index);
        onUpdate(tempChanges.languages);
    }

    return (
        <Row className='wihv-modal__row p-2 p-md-3' key={index}>
            <Col sm={12} className="p-0">

                <Form.Group as={Row}>
                    <Col sm={12}>
                        <Form.Label>{t('profile:languages:labelIsoCode')}</Form.Label>
                        <Form.Select value={tempChanges.languages[index].isoCode ?? ''} onChange={(e) => handleLanguageChange(e, index)} required>
                            <option key={-1} value="">{t('profile:languages:placeholderIsoCode')}</option>
                            {
                                languages.map((item, languageIndex) => (<option key={languageIndex} value={item}>{t(`languages:${item}`)}</option>))
                            }
                        </Form.Select>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className='general-proficiency mt-3'>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                        <div className='language-proficiency'>
                            <Form.Label>{t('profile:languages:general')}</Form.Label>
                            <Select
                                placeholder={t('profile:languages:placeholderLanguageLevel')}
                                className="certification-select"
                                value={tempChanges.languages[index]?.general ? { label: translateLanguageLevel(tempChanges.languages[index].general), value: tempChanges.languages[index].general } : null}
                                isClearable={false}
                                isSearchable={true}
                                options={getLanguageLevels()}
                                onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'general')}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: 'none',
                                        borderRadius: '25px',
                                        padding: '0rem 0.5rem',
                                        boxShadow: 'none'
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: '#0c1732',
                                    }),
                                }}
                            />
                        </div>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className='mt-3'>
                    <Col>
                        <div className='mt-2'>
                            <Form.Label>{t('profile:languages:reading')}</Form.Label>
                            <Select
                                placeholder={t('profile:languages:placeholderLanguageLevel')}
                                className="certification-select"
                                value={tempChanges.languages[index]?.reading ? { label: translateLanguageLevel(tempChanges.languages[index].reading), value: tempChanges.languages[index].reading } : null}
                                isClearable={false}
                                isSearchable={true}
                                options={getLanguageLevels()}
                                onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'reading')}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: 'none',
                                        borderRadius: '25px',
                                        padding: '0rem 0.5rem',
                                        boxShadow: 'none'
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: '#0c1732',
                                    }),
                                }}
                            />
                        </div>

                        <div className='mt-2'>
                            <Form.Label>{t('profile:languages:writing')}</Form.Label>
                            <Select
                                placeholder={t('profile:languages:placeholderLanguageLevel')}
                                className="certification-select"
                                value={tempChanges.languages[index]?.writing ? { label: translateLanguageLevel(tempChanges.languages[index].writing), value: tempChanges.languages[index].writing } : null}
                                isClearable={false}
                                isSearchable={true}
                                options={getLanguageLevels()}
                                onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'writing')}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: 'none',
                                        borderRadius: '25px',
                                        padding: '0rem 0.5rem',
                                        boxShadow: 'none'
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: '#0c1732',
                                    }),
                                }}
                            />
                        </div>

                        <div className='mt-2'>
                            <Form.Label>{t('profile:languages:listening')}</Form.Label>
                            <Select
                                placeholder={t('profile:languages:placeholderLanguageLevel')}
                                className="certification-select"
                                value={tempChanges.languages[index]?.listening ? { label: translateLanguageLevel(tempChanges.languages[index].listening), value: tempChanges.languages[index].listening } : null}
                                isClearable={false}
                                isSearchable={true}
                                options={getLanguageLevels()}
                                onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'listening')}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: 'none',
                                        borderRadius: '25px',
                                        padding: '0rem 0.5rem',
                                        boxShadow: 'none'
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: '#0c1732',
                                    }),
                                }}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className='mt-2'>
                            <Form.Label>{t('profile:languages:speechTransferInformation')}</Form.Label>
                            <Select
                                placeholder={t('profile:languages:placeholderLanguageLevel')}
                                className="certification-select"
                                value={tempChanges.languages[index]?.speechTransferInformation ? { label: translateLanguageLevel(tempChanges.languages[index].speechTransferInformation), value: tempChanges.languages[index].speechTransferInformation } : null}
                                isClearable={false}
                                isSearchable={true}
                                options={getLanguageLevels()}
                                onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'speechTransferInformation')}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: 'none',
                                        borderRadius: '25px',
                                        padding: '0rem 0.5rem',
                                        boxShadow: 'none'
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: '#0c1732',
                                    }),
                                }}
                            />
                        </div>

                        <div className='mt-2'>
                            <Form.Label>{t('profile:languages:speechDialogue')}</Form.Label>
                            <Select
                                placeholder={t('profile:languages:placeholderLanguageLevel')}
                                className="certification-select"
                                value={tempChanges.languages[index]?.speechDialogue ? { label: translateLanguageLevel(tempChanges.languages[index].speechDialogue), value: tempChanges.languages[index].speechDialogue } : null}
                                isClearable={false}
                                isSearchable={true}
                                options={getLanguageLevels()}
                                onChange={(s) => handleLanguageProficiencyChange(s.value, index, 'speechDialogue')}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: 'none',
                                        borderRadius: '25px',
                                        padding: '0rem 0.5rem',
                                        boxShadow: 'none'
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: '#0c1732',
                                    }),
                                }}
                            />
                        </div>
                    </Col>
                </Form.Group>
            </Col>
            <button type='button' onClick={() => removeLanguage(index)} className="wihv-modal__row-delete"><i className="fa-solid fa-trash-can" /></button>
        </Row>
    );
};

function ProfileLanguages() {

    const profileContext = useContext(ProfileContext);
    const { translateLanguageLevel } = useLanguageLevelTranslation();
    const [validated, setValidated] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showExplainer, setShowExplainer] = useState<boolean>(false);
    const formRef = createRef<HTMLFormElement>();
    const { isRequired } = useMissingProfileCardRequirements(['languages']);

    const languageEfficienyExplanations = t('profile:languages:explanations', { returnObjects: true }) as any[];

    const updateTempChanges = (languages: Language[]) => {
        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            languages: [...languages]
        };

        profileContext.updateChanges(updatedProfile);
    }

    const saveChanges = () => {
        setValidated(true);
        if (formRef.current?.checkValidity()) {
            profileContext.saveProfile(profileContext.tempChanges)
                .then(() => { closeModal(); })
                .catch(() => { });
        }
    };

    const openModal = () => {
        setValidated(false);
        setShowEditModal(true);
    }

    const closeModal = () => {
        setShowEditModal(false);
        profileContext.updateChanges(profileContext.profile);
    };

    const addLanguage = () => {
        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            languages: [...profileContext.tempChanges?.languages ?? [], {} as Language]
        };

        profileContext.updateChanges(updatedProfile);
    }

    const getUnselectedLanguages = (currentLanguage: string) => {
        return languages?.filter(l =>
            !profileContext.tempChanges?.languages?.some(pl => pl.isoCode === l)
            || l === currentLanguage) ?? [];
    }

    const canAddAnotherLanguage = () => {
        return profileContext.tempChanges?.languages?.length != languages?.length;
    }

    return (
        <>
            <Card id="languages-card" className="profile-card mb-4">
                <Card.Header>
                    <Card.Title>{t('profile:languages:title')}</Card.Title>
                    <motion.button whileTap={{ scale: 0.85 }} onClick={() => openModal()}><i className="fa-solid fa-pen" /></motion.button>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        {profileContext.profile?.languages?.map((item, i) => (
                            <div className='card-item' key={i}>
                                <h3>{t(`languages:${item.isoCode}`)}</h3>
                                <p>
                                    <span className='me-2'>{t('profile:languages:general')}</span>
                                    <span className='font-medium'>{translateLanguageLevel(+item.general)}</span>
                                </p>
                            </div>
                        ))}

                        {profileContext.profile?.languages?.length === 0 &&
                            <>
                                {isRequired
                                    ? <div className='profile-completion-required'>
                                        <p>{t('profile:general:missingRequirements')}</p>
                                        <i className="icon fa-solid fa-arrow-turn-up"></i>
                                    </div>
                                    :
                                    <div className='card-item'>
                                        <p>{t('profile:languages:noLanguages')}</p>
                                    </div>
                                }
                            </>
                        }
                    </Card.Text >
                </Card.Body>
            </Card>

            <Modal className='wihv-modal wihv-modal--profile languages-modal' size='lg' show={showEditModal} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('profile:languages:title')}</Modal.Title>
                    <CloseButton variant='white' onClick={() => closeModal()} />
                </Modal.Header>
                <Modal.Body>
                    <Form ref={formRef} validated={validated} noValidate>

                        {profileContext.tempChanges?.languages?.length === 0 &&
                            <div className='text-center'>
                                <p>{t('profile:languages:noLanguages')}</p>
                            </div>
                        }

                        {profileContext.tempChanges?.languages?.map((item, i) => (
                            <LanguagesForm index={i} onUpdate={updateTempChanges} tempChanges={profileContext.tempChanges} languages={getUnselectedLanguages(item.isoCode)} key={i} />
                        ))}
                    </Form>
                    <Row>
                        <Col sm={12} className='mt-3 text-center'>
                            <Button displayType='secondary' text={t('profile:languages:addLanguageBtn')} icon='plus' onClick={() => addLanguage()} disabled={!canAddAnotherLanguage()} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className='mt-3'>
                            <div className='d-flex justify-content-center'>
                                <div className='languages-modal__toggle' role='button' tabIndex={0} onClick={() => setShowExplainer(!showExplainer)} onKeyDown={(event) => { if (event.key === 'Enter') setShowExplainer(!showExplainer) }}>
                                    <i className="fa-solid fa-info-circle me-2"></i>
                                    <span className='text-decoration-underline'>{t('profile:languages:openExplainer')}</span>
                                </div>
                            </div>
                            <div className={`languages-modal__explainer ${showExplainer ? 'd-flex' : 'd-none'}`}>
                                <ul>
                                    {languageEfficienyExplanations?.map((item, i) => (
                                        <li key={i} className='mb-2'>
                                            <span className='font-medium'>{item.title}</span>
                                            <p>{item.description}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button displayType='secondary' text={t('general:cancel')} onClick={() => closeModal()} />
                    <Button displayType='primary' text={t('general:save')} loading={profileContext.requestInProgress} onClick={() => saveChanges()} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProfileLanguages
import Button from "@/components/button/Button";
import LinkButton from "@/components/button/LinkButton";
import { BreadcrumbsContext } from "@/context/BreadcrumbsContext";
import { useIsAuthenticated } from "@azure/msal-react";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './VouchersOverview.scss';
import RedeemVoucherModal from "./components/RedeemVoucherModal";
import VouchersFrequentlyAskedQuestions from "./components/VouchersFrequentlyAskedQuestions";

const features = [
    {
        title: 'Toegang tot het platform',
        free: true,
        premium: true
    },
    {
        title: 'Orienteren op beroepen',
        free: true,
        premium: true
    },
    {
        title: 'Volledig leeraanbod',
        free: false,
        premium: true
    },
    {
        title: 'Aanbevelingen',
        free: false,
        premium: true
    },
    {
        title: 'AI Video analyses',
        free: false,
        premium: true
    }
]

function VouchersOverview() {

    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const [showRedeemVoucherModal, setShowRedeemVoucherModal] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:vouchers')
        }]);
    }, [setBreadcrumbs]);

    return (
        <div className="vouchers-overview">
            <Container fluid>
                <Container>
                    <Row>
                        <Col sm={12} className="py-5">
                            <div className="hero">
                                <h1 className="title">Jouw succes start hier</h1>
                                <p className="subtitle">Complementeer profiel. Leer skills. Level up.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="pb-5">
                        <Col sm={12} className="p-0">

                            <section className="plans">
                                <div className="plan-comparison">
                                    <div className="plan plan--start">
                                        <div className="header">
                                            <div className="title">
                                                <h2>Start</h2>
                                            </div>
                                            <div className="description">Geniet van onze basisfuncties zonder kosten, perfect voor het verkennen van ons platform.</div>
                                        </div>

                                        <div className="price">
                                            <span>Gratis</span>
                                        </div>

                                        <ul className="feature-list">
                                            {features.map((feature, index) => (
                                                <li key={index} className={`feature ${feature.free ? '' : 'feature--disabled'}`}>
                                                    {feature.free ? <i className="icon fas fa-check"></i> : <i className="icon fas fa-times"></i>}
                                                    {feature.title}
                                                </li>
                                            ))}
                                        </ul>

                                        {isAuthenticated
                                            ? <div className="owned-status"><i className="icon fas fa-check me-2"/><span>{t('vouchers:overview:alreadyOwned')}</span></div>
                                            : <LinkButton text={"Meld je nu aan"} icon="sign-in" to='/profile' />
                                        }
                                    </div>

                                    <div className="plan plan--plus">
                                        <div className="header">
                                            <div className="title">
                                                <h2>Plus</h2>
                                                <div className="label">Meest populair</div>
                                            </div>
                                            <div className="description">Ontgrendel alle functies, maximaliseer je productiviteit en haal het beste uit ons platform.</div>
                                        </div>

                                        <div className="price">€ 199 <span className="billing-cycle">/ éénmalig</span></div>

                                        <ul className="feature-list">
                                            {features.map((feature, index) => (
                                                <li key={index} className={`feature ${feature.premium ? '' : 'feature--disabled'}`}>
                                                    {feature.premium ? <i className="icon fas fa-check"></i> : <i className="icon fas fa-times"></i>}
                                                    {feature.title}
                                                </li>
                                            ))}
                                        </ul>

                                        {isAuthenticated &&
                                            <>
                                                <Button text={"Vraag voucher aan"} icon="bag-shopping" displayType="secondary" />
                                                <Button text={"Verzilver voucher"} icon="key" onClick={() => { setShowRedeemVoucherModal(true) }} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </section>
                        </Col>
                    </Row>

                </Container>
            </Container>

            <Container fluid className='faq py-5'>
                <Container>
                    <VouchersFrequentlyAskedQuestions />
                </Container>
            </Container>

            <RedeemVoucherModal showModal={showRedeemVoucherModal} onSuccess={() => { }} onClose={() => setShowRedeemVoucherModal(false)} />

        </div>
    )
}

export default VouchersOverview
import './LoadingComponent.scss';
import WihvLoader from './WihvLoader';

type LoadingComponentProps = {
    message: string;
}

export function LoadingComponent({ message }: LoadingComponentProps) {

    return (
        <div className='loading-info'>
            <div className="content p-5">
                <WihvLoader />
                <h2 className="pt-4">{message}</h2>
            </div>
        </div>
    );
}

import './PercentageRadialChart.scss'

type PercentageRadialChartProps = {
    title: string
    percentage: number
    displayPercentage?: boolean
    dimension?: number
    strokeWidth?: number
    fontSize?: number
}

function PercentageRadialChart({ title, percentage, displayPercentage = true, dimension, strokeWidth, fontSize }: PercentageRadialChartProps) {

    const radius = 80;
    let color = '#7d77ff';

    const circleRadius = Math.min(radius, 85);
    const circumference = 2 * Math.PI * circleRadius;
    const strokeLength = (circumference / 100) * percentage;

    const adjustedStrokeWidth = Math.min(strokeWidth, dimension / 2);
    const viewBoxSize = 180 + adjustedStrokeWidth * 2;
    const circleCenter = viewBoxSize / 2;

    return (
        <div className={'radial-chart'} title={`${title}: ${percentage}%`}>
            <svg viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`} width={dimension ?? 100} height={dimension ?? 100} aria-label={`${title}: ${percentage}%`}>
                <circle
                    className="radial-chart__total"
                    stroke={color}
                    strokeWidth={strokeWidth ?? 15}
                    fill="none"
                    cx={circleCenter}
                    cy={circleCenter}
                    r={circleRadius}
                />
                <circle
                    className="radial-chart__progress"
                    stroke={color}
                    strokeWidth={strokeWidth ?? 15}
                    strokeDasharray={`${strokeLength} ${circumference}`}
                    strokeLinecap="round"
                    fill="none"
                    cx={circleCenter}
                    cy={circleCenter}
                    r={circleRadius}
                />
                {displayPercentage &&
                    <text className='radial-chart__percentage'
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize={fontSize ?? 30}
                        fontWeight={600}
                        fill="#000">
                        {percentage}%
                    </text>
                }
            </svg>
        </div>
    );
}

export default PercentageRadialChart
import InterestSearchBar from '@/components/search/InterestSearchBar';
import { ProfileContext } from '@/context/ProfileContext';
import { Interest } from '@/profile/model/interest';
import { Profile } from '@/profile/model/profile';
import { t } from 'i18next';
import { createRef, useContext, useState } from 'react';
import { Card, CloseButton, Col, Form, Modal, Row } from 'react-bootstrap';
import './ProfileCard.scss';
import { motion } from 'framer-motion';
import Button from '@/components/button/Button';
import { useMissingProfileCardRequirements } from '@/hooks/useMissingProfileCardRequirements';

function ProfileInterests() {

    const profileContext = useContext(ProfileContext);
    const [showEditModal, setShowEditModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const formRef = createRef<HTMLFormElement>();
    const { isRequired } = useMissingProfileCardRequirements(['interests']);

    const openModal = () => {
        setValidated(false);
        setShowEditModal(true);
    }

    const closeModal = () => {
        setShowEditModal(false);
        profileContext.updateChanges(profileContext.profile);
    };

    const saveChanges = () => {
        setValidated(true);
        if (formRef.current?.checkValidity()) {
            profileContext.saveProfile(profileContext.tempChanges)
                .then(() => { closeModal(); })
                .catch(() => { });
        }
    };

    const updateTempChanges = (index, propertyName, newValue) => {

        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            interests: profileContext.tempChanges.interests.map((item, i) => i === index ? { ...item, [propertyName]: newValue } : item)
        };

        profileContext.updateChanges(updatedProfile);
    };

    const setInterest = (index: number, interest: Interest) => {

        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            interests: profileContext.tempChanges.interests.map((item, i) => i === index
                ? { ...item, id: interest?.id, name: interest?.name, description: interest?.description }
                : item)
        };

        profileContext.updateChanges(updatedProfile);
    }

    const addInterest = () => {
        const updatedInterests = [...profileContext.tempChanges.interests, {} as Interest];
        profileContext.updateChanges({ ...profileContext.tempChanges, interests: updatedInterests });
    }

    const removeInterest = (index: number) => {
        const updatedInterests = profileContext.tempChanges.interests.filter((item, i) => i !== index);
        profileContext.updateChanges({ ...profileContext.tempChanges, interests: updatedInterests });
    }

    return (
        <>
            <Card id="interests-card" className="profile-card mb-4">
                <Card.Header>
                    <Card.Title>{t('profile:interests:title')}</Card.Title>
                    <motion.button whileTap={{ scale: 0.85 }} onClick={() => openModal()}><i className="fa-solid fa-pen" /></motion.button>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        {profileContext.profile?.interests?.map((item, i) => (
                            <div className='card-item' key={i}>
                                <h3 className='title'>{item.name}</h3>
                                <p className='description'>{item.description}</p>
                            </div>
                        ))}

                        {profileContext.profile?.interests?.length === 0 &&
                            <>
                                {isRequired
                                    ? <div className='profile-completion-required'>
                                        <p>{t('profile:general:missingRequirements')}</p>
                                        <i className="icon fa-solid fa-arrow-turn-up"></i>
                                    </div>
                                    :
                                    <div className='card-item'>
                                        <p>{t('profile:interests:noInterests')}</p>
                                    </div>
                                }
                            </>
                        }
                    </Card.Text >
                </Card.Body>
            </Card>

            <Modal className='wihv-modal wihv-modal--profile' size='lg' show={showEditModal} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('profile:interests:title')}</Modal.Title>
                    <CloseButton variant='white' onClick={() => closeModal()} />
                </Modal.Header>
                <Modal.Body>
                    <Form ref={formRef} validated={validated} noValidate>

                        {profileContext.tempChanges?.interests?.length === 0 &&
                            <div className='text-center'>
                                <p>{t('profile:interests:noInterests')}</p>
                            </div>
                        }

                        {profileContext.tempChanges?.interests?.map((item, index) => (
                            <Row className='wihv-modal__row p-2 p-md-3' key={index}>
                                <Col sm={12} className="p-0">
                                    <div >
                                        <Form.Group as={Row} className="mt-0">
                                            <Col className="mt-2">
                                                <InterestSearchBar required={true} selectedInterest={item} onInterestChange={selectedInterest => setInterest(index, selectedInterest)} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mt-0">
                                            <Col className="mt-2">
                                                <Form.Label><span>{t('profile:interests:labelDescription')}</span></Form.Label>
                                                <div className='form-textarea'>
                                                    <Form.Control as="textarea" rows={3} placeholder="" value={item.description ?? ''} onChange={e => updateTempChanges(index, 'description', e.target.value)} />
                                                </div>
                                            </Col>
                                        </Form.Group>
                                    </div>
                                </Col>
                                <button type='button' onClick={() => removeInterest(index)} className="wihv-modal__row-delete"><i className="fa-solid fa-trash-can" /></button>
                            </Row>
                        ))}
                    </Form>
                    <Row>
                        <Col sm={12} className='mt-3 text-center'>
                            <Button displayType='secondary' text={t('profile:interests:addInterestBtn')} icon='plus' onClick={() => addInterest()} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button displayType='secondary' text={t('general:cancel')} onClick={() => closeModal()} />
                    <Button displayType='primary' text={t('general:save')} loading={profileContext.requestInProgress} onClick={() => saveChanges()} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProfileInterests
import SettingsProvider from '@/context/settings-provider';
import SettingsOverview from './SettingsOverview';

function SettingsModule() {

    return (
        <SettingsProvider>
            <SettingsOverview />
        </SettingsProvider>
    )
}

export default SettingsModule;
import { ConfigContext } from '@/context/ConfigContext';
import { RecommendedProfession } from '@/professions/model/recommended-profession';
import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import useSWR from 'swr';

const useRecommendedProfessions = () => {

    const configContext = useContext(ConfigContext);
    const isAuthenticated = useIsAuthenticated();
    const { data: recommendedProfessions, isLoading, error, mutate } = useSWR<RecommendedProfession[]>(isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.professions.recommended : null);

    return { recommendedProfessions, isLoading, error, mutate };
}

export default useRecommendedProfessions;
import { Card } from 'react-bootstrap'
import './FormCard.scss'
import { useState } from 'react'

type FormCardProps = {
    children: React.ReactNode
    title: string
    className?: string
}

function FormCard({ children, title, className = '' }: FormCardProps) {

    const [cardOpen, setCardOpen] = useState(true);

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') { setCardOpen(!cardOpen); }
    };

    return (
        <Card className={`form-card mb-4 ${className}`}>
            <Card.Header className='header' role='button' tabIndex={0} onClick={() => setCardOpen(!cardOpen)} onKeyDown={handleKeyDown}>
                <h2>{title}</h2>
                <span className='toggle-icon'>
                    <i className={`fas fa-chevron-${cardOpen ? 'up' : 'down'} fa-md`}></i>
                </span>
            </Card.Header>
            <Card.Body className={`content content--${cardOpen ? 'open' : 'closed'}`}>
                <Card.Text as="div">
                    {children}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default FormCard
import React, { PropsWithChildren, useMemo } from "react";
import { ConfigContext, IConfigContext } from "./ConfigContext";


export type ConfigProviderProps = PropsWithChildren<{
    Config: IConfigContext
}>;

export function ConfigProvider({ Config, children }: ConfigProviderProps): React.ReactElement {

    const memoizedConfig = useMemo(() => Config, [Config]);

    return (
        <ConfigContext.Provider value={memoizedConfig}>
            {children}
        </ConfigContext.Provider>
    );
}
import { createContext } from "react";
import { Profile } from "../modules/profile/model/profile";

export interface IProfileState {
    profile: Profile;
    tempChanges: Profile;
    requestInProgress: boolean;
    updateChanges: (profile: Profile) => void;
    updateProfile: (profile: Profile) => void;
    saveProfile: (profile: Profile) => Promise<Profile>;
}

export const ProfileContext = createContext<IProfileState>(undefined);
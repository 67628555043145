import { AuthenticationResult, EventType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { IConfigContext } from "@/context/ConfigContext";
import { Initialization } from "@microsoft/applicationinsights-web/types/Initialization";

export function createMsal(config: IConfigContext, ai: Initialization): PublicClientApplication {

    const adConfig = {
        ...config.configBody.ad.msal,
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:       
                            ai.trackException({exception: new Error(message)});                     
                            console.error(message);
                            return;
                        case LogLevel.Warning:
                            ai.trackException({exception: new Error(message)});
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
            },
        },
    }
    const msalInstance = new PublicClientApplication(adConfig);

    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    msalInstance.addEventCallback((event) => {        
        if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS)
        ) {
            const authResult = event.payload as AuthenticationResult;
            if (authResult) {
                msalInstance.setActiveAccount(authResult.account);

                var telemetryInitializer = (envelope) => {
                    envelope.tags["userName"] = msalInstance.getAllAccounts()[0].username;
                }
                ai?.addTelemetryInitializer(telemetryInitializer);
            }
        }
    });
    return msalInstance;
}
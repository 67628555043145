import LinkButton from "@/components/button/LinkButton";
import { t } from "i18next";
import DashboardTile from "./DashboardTile";
import './CreateProfileTile.scss';

function ProfileCtaCard({ className = '' }) {

    return (
        <DashboardTile tileClassName={className} imgSrc="/assets/img/professions/WIHV_Professions_Business-Intelligence-Specialist.jpg">
            <div className="create-profile-tile-content">
                <h3 className="subtitle">{t('dashboard:profileCta:subtitle')}</h3>
                <h2 className="title">{t('dashboard:profileCta:title')}</h2>
                <p className="description">{t('dashboard:profileCta:description')}</p>
                <LinkButton text={t('dashboard:profileCta:btn')} to={'/profile'} icon="chevron-right" />
            </div>
        </DashboardTile>
    )
}

export default ProfileCtaCard
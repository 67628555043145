import { EssayMeasurementAnswer } from "@/measurements/model/measurement-answers/essay-measurement-answer";
import { MeasurementAnswer } from "@/measurements/model/measurement-answers/measurement-answer";
import { EssayMeasurementDefinition } from "@/measurements/model/measurement-definitions/essay-measurement-definition";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './EssayMeasurement.scss';
import { t } from "i18next";

type EssayMeasurementProps = {
    measurement: EssayMeasurementDefinition;
    isSubmitting: boolean;
    onSave: (data: MeasurementAnswer) => void;
}

function EssayMeasurement({ measurement, isSubmitting, onSave }: EssayMeasurementProps) {

    const [answer, setAnswer] = useState<EssayMeasurementAnswer>({ id: measurement.id, type: measurement.type, answer: null });
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [textLength, setTextLength] = useState(0);

    const onChangeHandler = (e: any) => {

        var text = e.target.value;
        setTextLength(text.length);
        setAnswer((prevAnswer) => ({ ...prevAnswer, answer: text }));
        if (text.length >= measurement.minCharacters) {
            setSubmitEnabled(true);
        }
    }

    const handleSave = () => {
        onSave(answer);
    }

    return (
        <>
            <Container className="essay-measurement">
                <Row className="pt-5">
                    <Col sm={12} md={12} lg={{ offset: 2, span: 8 }} xl={{ offset: 2, span: 8 }} className="text-center py-4 py-md-5">
                        <h2 className="essay-measurement__title">{measurement.title}</h2>
                        <p>{measurement.description}</p>

                        <form className="mt-4 mt-md-5">
                            <textarea rows={7} className="form-control"
                                minLength={measurement.minCharacters}
                                maxLength={measurement.maxCharacters}
                                placeholder={t('measurements:essay:placeholder')}
                                onChange={onChangeHandler}
                                required={true}>
                            </textarea>

                            <div className="essay-measurement__requirements p-3">

                                <small className="text-muted">{t('measurements:essay:characterRequirement', { min: measurement.minCharacters })}</small>
                                <small className="text-oranje font-bold ms-auto me-3">
                                    <span >{textLength}</span>/{measurement.maxCharacters}
                                </small>

                                <button onClick={() => handleSave()} disabled={!submitEnabled || isSubmitting} className={`btn btn--oranje`}>
                                    {t('measurements:essay:saveBtn')}
                                    {isSubmitting && <i className="fa-solid fa-spinner fa-lg fa-spin ms-2" />}
                                    {!isSubmitting && <i className="fas fa-chevron-right ms-2" />}
                                </button>
                            </div>
                        </form>

                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default EssayMeasurement
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { useEffect, useState } from "react";
import { ILayoutContext, LayoutContext } from "./LayoutContext";

export function LayoutProvider({ children }): React.ReactElement {

    const { isMobile } = useScreenDetector();
    var unfoldedNavigationDrawerWidth = isMobile ? '100%' : '260px';
    var foldedNavigationDrawerWidth = isMobile ? '0px' : '80px';
    const defaultHeaderHeight = '72px';
    const stickyHeaderHeight = '56px';
    const scrollThreshold = 5;
    const navigationFoldedPreference = sessionStorage.getItem('navigationFolded') === 'true';

    const [contextValue, setContextValue] = useState<ILayoutContext>({
        isMobile: isMobile,
        headerHeight: isMobile ? stickyHeaderHeight : defaultHeaderHeight,
        stickyHeader: isMobile,
        navigationWidth: navigationFoldedPreference ? foldedNavigationDrawerWidth : unfoldedNavigationDrawerWidth,
        navigationFolded: navigationFoldedPreference,
        setNavigationFolded: setNavigationFolded,
        setStickyHeader: setStickyHeader
    });

    useEffect(() => {
        setContextValue((prevContextValue) => ({
            ...prevContextValue,
            isMobile: isMobile
        }));
    }, [isMobile]);

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, []);

    function checkScrollTop() {
        setContextValue((prevContextValue) => ({
            ...prevContextValue,
            headerHeight: isMobile ? stickyHeaderHeight : window.scrollY > scrollThreshold ? stickyHeaderHeight : defaultHeaderHeight,
            stickyHeader: isMobile ? true : window.scrollY > scrollThreshold
        }));
    };

    function setNavigationFolded(folded: boolean) {
        sessionStorage.setItem('navigationFolded', folded.toString());
        setContextValue((prevContextValue) => ({
            ...prevContextValue,
            navigationWidth: folded ? foldedNavigationDrawerWidth : unfoldedNavigationDrawerWidth,
            navigationFolded: folded
        }));
    }

    function setStickyHeader(sticky: boolean) {
        setContextValue((prevContextValue) => ({
            ...prevContextValue,
            headerHeight: isMobile ? stickyHeaderHeight : sticky ? stickyHeaderHeight : defaultHeaderHeight,
            stickyHeader: isMobile ? true : sticky
        }));
    }

    return (
        <LayoutContext.Provider value={contextValue}>
            {children}
        </LayoutContext.Provider>
    );
};
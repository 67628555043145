import { FilterOption } from '@/components/filter-component/FilterComponent';
import { LearningResourceSearchDocument } from '@/components/search/models/learning-resource-search-document';
import { ConfigContext } from '@/context/ConfigContext';
import { useLearningResourceTypeTranslation, useSupplierTypeTranslation } from '@/general/i18n/translation-helpers';
import { LearningResource } from '@/learning/models/learning-resource';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';

const useAllLearningResources = () => {

    const configContext = useContext(ConfigContext);
    const { translateSupplierType } = useSupplierTypeTranslation();
    const { translateLearningResourceType } = useLearningResourceTypeTranslation();

    const [totalCount, setTotalCount] = useState<number>(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [searchterm, setSearchterm] = useState<string>('');
    const [sorting, setSorting] = useState<string>('');
    const [filter, setFilter] = useState<FilterOption[]>([]);

    const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
    const [resources, setResources] = useState<LearningResource[]>([]);

    useEffect(() => {

        if (isLoading) return;
        sortAndFilterList();

    }, [page, searchterm, sorting, filter]);

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const updateSearchterm = useCallback(
        debounce((value) => {
            setPage(1);
            setSearchterm(value);
        }, 400), []
    );

    const updateSorting = (value: string) => {
        setPage(1);
        setSorting(value);
    }

    const updateFilters = (value: FilterOption[]) => {
        setPage(1);
        setFilter(value);
    }

    const getFilterExpression = () => {

        let filterExpression = '';
        let filterGroups: { [key: string]: string[] } = {};

        if (filter.length > 0) {
            filter.forEach((filterOption) => {
                if (!filterGroups[filterOption.category]) {
                    filterGroups[filterOption.category] = [];
                }

                if (filterOption.category === t('learning:overview:listFilterExperienceLevelCategory')) {
                    filterGroups[filterOption.category].push(`experienceLevel eq '${filterOption.value}'`);
                }
                if (filterOption.category === t('learning:overview:listFilterResourceTypeCategory')) {
                    filterGroups[filterOption.category].push(`resourceType eq ${filterOption.value}`);
                }
                if (filterOption.category === t('learning:overview:listFilterLanguageNameCategory')) {
                    filterGroups[filterOption.category].push(`languageName eq '${filterOption.value}'`);
                }
                if (filterOption.category === t('learning:overview:listFilterSupplierCategory')) {
                    filterGroups[filterOption.category].push(`supplier eq ${filterOption.value}`);
                }
            });

            Object.keys(filterGroups).forEach((category, index) => {
                if (index !== 0) {
                    filterExpression += ' and';
                }
                filterExpression += ` (${filterGroups[category].join(' or ')})`;
            });
        }

        return filterExpression;
    }

    const sortAndFilterList = async () => {

        setIsLoading(true);

        const body = {
            count: true,
            facets: ['experienceLevel', 'resourceType', 'supplier', 'languageName'],
            filter: getFilterExpression(),
            select: 'learningResourceId, resourceType, imageUrl, courses, educationId, name, shortName, shortDescription, description, supplier, experienceLevel, retailPrice, durationInMinutes, certificateName, languageName, skills, created, modified, isActive',
            search: searchterm,
            skip: (page - 1) * 10,
            top: 10
        };

        if (sorting) {
            const [field, order] = sorting.split(' ');
            body['orderby'] = `${field} ${order}`;
        }

        fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.search.learningResources}`, {
            credentials: "same-origin",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
            },
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((data) => {

                const newResources: LearningResource[] = data?.value?.map((resource: LearningResourceSearchDocument) => {
                    return {
                        id: resource.learningResourceId,
                        name: resource.name,
                        shortDescription: resource.shortDescription,
                        description: resource.description,
                        supplier: resource.supplier,
                        experienceLevel: resource.experienceLevel,
                        retailPrice: resource.retailPrice,
                        durationInMinutes: resource.durationInMinutes,
                        certificateName: resource.certificateName,
                        imageUrl: resource.imageUrl,
                        languageName: resource.languageName,
                        skills: resource.skills,
                        courses: resource.courses,
                        created: resource.created,
                        modified: resource.modified,
                        resourceType: resource.resourceType
                    } as Partial<LearningResource>;
                });

                setTotalCount(data['@odata.count']);

                if (filterOptions.length === 0) {

                    const filterOptions: FilterOption[] = [];

                    filterOptions.push(...data['@search.facets']?.experienceLevel?.map((facet: any) => {
                        return { id: facet.value, label: facet.value, type: 'checkbox', value: facet.value, category: t('learning:overview:listFilterExperienceLevelCategory') }
                    }));

                    filterOptions.push(...data['@search.facets']?.resourceType?.map((facet: any) => {
                        return { id: facet.value, label: translateLearningResourceType(facet.value), type: 'checkbox', value: facet.value, category: t('learning:overview:listFilterResourceTypeCategory') }
                    }));

                    filterOptions.push(...data['@search.facets']?.languageName?.map((facet: any) => {
                        return { id: facet.value, label: t(`languages:${facet.value}`), type: 'checkbox', value: facet.value, category: t('learning:overview:listFilterLanguageNameCategory') }
                    }));

                    filterOptions.push(...data['@search.facets']?.supplier?.map((facet: any) => {
                        return { id: facet.value, label: translateSupplierType(facet.value), type: 'checkbox', value: facet.value, category: t('learning:overview:listFilterSupplierCategory') }
                    }));

                    setFilterOptions(filterOptions);
                }

                setResources((prevResources) => {
                    if (page === 1) {
                        return newResources;
                    }
                    return [...prevResources, ...newResources];
                });
                setIsLoading(false);
            })
            .catch((error) => {
                setResources([]);
                setIsLoading(false);
                console.error(error);
                //TODO: handle error
            });
    }

    return { resources, totalCount, isLoading, filterOptions, updateSearchterm, updateSorting, updateFilters, loadMore };
}

export default useAllLearningResources;
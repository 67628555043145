import { CookiePreferencesContext } from "@/context/CookiePreferencesContext";
import { CookiePreferences } from "@/general/model/cookie-preferences";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Accordion, Container, Form, Modal } from "react-bootstrap";
import Button from "../button/Button";
import './CookiePreferencesModal.scss';

type CookiePreferencesModalProps = {
    showModal: boolean;
    onClose: () => void;
}

function CookiePreferencesModal({ showModal, onClose }: CookiePreferencesModalProps) {

    const { preferences, acceptAll, denyAll, updatePreferences } = useContext(CookiePreferencesContext);
    const [tempPreferences, setTempPreferences] = useState<CookiePreferences>(null);

    useEffect(() => {
        const tempPreferences = preferences ? { ...preferences } : { strictlyNecessary: true, functional: false, multimedia: false } as CookiePreferences;
        setTempPreferences(tempPreferences);
    }, [preferences]);

    const onSave = () => {
        updatePreferences(tempPreferences);
    }

    return (
        <Modal className="wihv-modal cookie-preferences-modal" show={showModal} size="lg" onHide={() => onClose()} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('settings:cookiePreferences:title')}</Modal.Title>
            </Modal.Header>

            <Modal.Body as={Container}>
                <h3 className="subtitle">{t('settings:cookiePreferences:subtitle')}</h3>
                <p className="description">{t('settings:cookiePreferences:description')}</p>

                <section className="cookie-categories">
                    <Accordion className='accordion'>
                        <Accordion.Item className='accordion-item' eventKey={'strictlyNecessary'}>
                            <Accordion.Header className='accordion-header'>
                                <div className="header-content">
                                    <span>{t('settings:cookiePreferences:strictlyNecessary')}</span>
                                    <div className="strictly-necessary-switch">
                                        <span>{t('settings:cookiePreferences:alwaysEnabled')}</span>
                                        <Form.Switch
                                            onClick={(e) => e.stopPropagation()}
                                            style={{ cursor: 'not-allowed' }}
                                            checked={true}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>{t('settings:cookiePreferences:strictlyNecessaryDescription')}</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion className='accordion'>
                        <Accordion.Item className='accordion-item' eventKey={'functional'}>
                            <Accordion.Header className='accordion-header'>
                                <div className="header-content">
                                    <span>{t('settings:cookiePreferences:functional')}</span>
                                    <Form.Switch
                                        onClick={(e) => e.stopPropagation()}
                                        checked={tempPreferences?.functional}
                                        onChange={(e) => setTempPreferences({ ...tempPreferences, functional: e.target.checked })}
                                    />
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>{t('settings:cookiePreferences:functionalDescription')}</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion className='accordion'>
                        <Accordion.Item className='accordion-item' eventKey={'multimedia'}>
                            <Accordion.Header className='accordion-header'>
                                <div className="header-content">
                                    <span>{t('settings:cookiePreferences:multimedia')}</span>
                                    <Form.Switch
                                        onClick={(e) => e.stopPropagation()}
                                        checked={tempPreferences?.multimedia}
                                        onChange={(e) => { setTempPreferences({ ...tempPreferences, multimedia: e.target.checked }) }}
                                    />
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>{t('settings:cookiePreferences:multimediaDescription')}</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </section>

                <section className="contact-info">
                    <h4 className="title">{t('settings:cookiePreferences:contactTitle')}</h4>
                    <p className="description">
                        {t('settings:cookiePreferences:contactDescription')}
                        <a className="ms-1" href="mailto:info@werkinhetvooruitzicht.nl">{t('settings:cookiePreferences:contactLink')}</a>
                    </p>
                </section>

            </Modal.Body>

            <Modal.Footer>
                <Button text={t('cookieConsent:acceptAll')} displayType="secondary" onClick={() => acceptAll()} />
                <Button text={t('cookieConsent:denyAll')} displayType="secondary" onClick={() => denyAll()} />
                <Button className="save" text={t('settings:cookiePreferences:savePreferences')} icon="floppy-disk" onClick={() => onSave()} />
            </Modal.Footer>
        </Modal>
    )
}

export default CookiePreferencesModal
import { ConfigContext } from '@/context/ConfigContext';
import { LearningCourse } from '@/learning/models/learning-course';
import { LearningPath } from '@/learning/models/learning-path';
import { LearningResourceStatus } from '@/learning/models/learning-resource-status';
import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import useSWR from 'swr';

const useCompletedLearningResources = () => {

    const configContext = useContext(ConfigContext);
    const isAuthenticated = useIsAuthenticated();
    const completedLearningPathsUrl = isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.learningPaths.list + `?status=${LearningResourceStatus.Completed}` : null;
    const completedLearningCoursesUrl = isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.learningCourses.list + `?status=${LearningResourceStatus.Completed}` : null;
    const { data: completedLearningPaths, isLoading: learningPathsLoading, error: learningPathsError, mutate: mutateStartedLearningPaths } = useSWR<LearningPath[]>(completedLearningPathsUrl);
    const { data: completedLearningCourses, isLoading: learningCoursesLoading, error: learningCoursesError, mutate: mutateStartedLearningCourses } = useSWR<LearningCourse[]>(completedLearningCoursesUrl);

    const completedLearningResources = [...completedLearningCourses || [], ...completedLearningPaths || []];

    return { completedLearningResources };
}

export default useCompletedLearningResources;
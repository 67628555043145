import ConfirmModal from '@/components/confim-modal/ConfirmModal';
import { ConfigContext } from '@/context/ConfigContext';
import { useFileUploadTypeTranslation } from '@/general/i18n/translation-helpers';
import useAuthenticatedRequest from '@/hooks/useAuthenticatedRequest';
import { saveAs } from 'file-saver';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import useSWR from 'swr';
import { FileUpload } from '../model/file-upload';
import './ProfileCard.scss';
import './ProfileUploadsFile.scss';

type ProfileUploadsFileProps = {
    file: FileUpload;
}

function ProfileUploadsFile({ file }: ProfileUploadsFileProps) {

    const configContext = useContext(ConfigContext);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const { translateFileUploadType } = useFileUploadTypeTranslation();
    const authenticatedRequest = useAuthenticatedRequest();
    const { isLoading: downloadIsLoading, mutate: downloadMutate } = useSWR(file ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.uploads.detail.replace('{id}', file?.id) : null, { shouldRetryOnError: false, revalidateOnMount: false });

    function handleDelete() {
        setShowConfirmModal(false);
        setIsSubmitting(true);

        authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.uploads.detail.replace('{id}', file?.id), 'DELETE')
            .then(() => {
                toast.success(t('profile:toasts:deleteFileSuccess'));
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('profile:toasts:deleteFileFailed') + ' ' + error.message);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    async function handleDownload() {

        const data = await downloadMutate();

        if (data) {
            toast.success(t('profile:toasts:downloadFileSuccess'));
            const blob = new Blob(data);
            saveAs(blob);
        } else {
            toast.error(t('profile:toasts:downloadFileFailed'));
        }
    }

    return (
        <>
            <div className='card-item profile-uploads-file'>
                <div className='metadata'>
                    <h3 className='title'>{file.fileName}</h3>
                    <p className='subtitle'>{translateFileUploadType(file.type)}</p>
                </div>
                <div className='actions'>
                    <button disabled={downloadIsLoading} onClick={() => { handleDownload(); }}>
                        <i className="fa-solid fa-download me-2" />
                        {t('profile:uploads:downloadBtn')}
                    </button>
                    <button disabled={isSubmitting} onClick={() => { setShowConfirmModal(true) }}>
                        <i className="fa-solid fa-trash-can me-2" />
                        {t('profile:uploads:deleteBtn')}
                    </button>
                </div>
            </div>

            <ConfirmModal
                title={t('profile:uploads:deleteFileTitle')}
                description={t('profile:uploads:deleteFileDescription')}
                showModal={showConfirmModal}
                isSubmitting={isSubmitting}
                onConfirm={handleDelete}
                onClose={() => setShowConfirmModal(false)} />

        </>
    );
}

export default ProfileUploadsFile
import PageNotFound from '@/components/not-found-component/PageNotFound';
import { Route, Routes } from 'react-router-dom';
import LearningOverview from './LearningOverview';
import LearningCourseDetail from './components/LearningCourseDetail';
import LearningPathDetail from './components/LearningPathDetail';

function LearningModule() {

    return (
        <Routes>
            <Route path="/">
                <Route index element={<LearningOverview />} />
                <Route path={"/course/:courseId"} element={<LearningCourseDetail />} />
                <Route path={"/path/:pathId"} element={<LearningPathDetail />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    )
}

export default LearningModule;

import PageNotFound from '@/components/not-found-component/PageNotFound';
import ProfileProvider from '@/context/ProfileProvider';
import { Route, Routes } from 'react-router-dom';
import DevelopmentOverview from './DevelopmentOverview';

function DevelopmentModule() {
    return (
        <Routes>
            <Route path="/" index element={
                <ProfileProvider>
                    <DevelopmentOverview />
                </ProfileProvider>
            } />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default DevelopmentModule;
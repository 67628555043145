import Stepper from '@keyvaluesystems/react-stepper';
import './Stepper.scss'

export interface Step {
    stepValue: any;
    stepLabel: string;
    stepDescription?: string;
    completed: boolean;
}

type StepperProps = {
    steps: Step[];
    currentStepIndex: number;
    stepClickEnabled?: boolean;
    onHandleStepClick?: (index: number) => void;
}

function StepperComponent({ steps, currentStepIndex, stepClickEnabled = false, onHandleStepClick }: StepperProps) {

    const styles = {
        LabelTitle: () => ({
            color: "var(--color-main-text)",
        }),
        ActiveLabelTitle: () => ({
            color: "var(--color-brand-primary-500)",
        }),
        LineSeparator: () => ({
            backgroundColor: "var(--color-brand-primary-500)",
        }),
        ActiveNode: () => ({
            backgroundColor: "var(--color-brand-primary-500)",
        }),
        CompletedNode: () => ({
            backgroundColor: "var(--color-brand-primary-500)",
        }),
        Node: () => ({
            cursor: stepClickEnabled ? "pointer" : "default",
        })
    };

    return (
        <Stepper
            steps={steps}
            currentStepIndex={currentStepIndex}
            orientation="horizontal"
            labelPosition="bottom"
            styles={styles}            
            onStepClick={(step) => onHandleStepClick(steps.indexOf(step))}
        />
    )
}

export default StepperComponent
import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

const useCurrentAccount = () => {

    const { accounts, instance } = useMsal();
    const [currentAccount, setCurrentAccount] = useState<AccountInfo>(null);

    useEffect(() => {
        setCurrentAccount(accounts[0]);
    }, [accounts]);

    return { currentAccount };
}

export default useCurrentAccount;
import { Md5 } from 'ts-md5';
import apiEndpoints from './api-endpoints.json';

function hexToBase64(str) {
    return window.btoa(String.fromCharCode.apply(null,
        str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
    );
}

export function getConfig(): Promise<any> {
    const metaElementUrl = document.querySelector('meta[name="component_config_url"]')
    const metaElementHash = document.querySelector('meta[name="component_config_hash"]')

    if (metaElementUrl instanceof HTMLMetaElement && metaElementHash instanceof HTMLMetaElement) {
        const configUrl = metaElementUrl.content;
        const metaHash = metaElementHash.content;
        // console.log(`Loading configuration from ${configUrl}`);
        return fetch(configUrl)
            .then(response => {
                response.clone().arrayBuffer().then(array => {
                    const md5 = new Md5();
                    md5.appendByteArray(new Uint8Array(array));
                    const generatedMd5 = md5.end().toString().toUpperCase();
                    const responseHash = hexToBase64(generatedMd5);
                    if (metaHash.localeCompare(responseHash, undefined, { sensitivity: 'base' })) {
                        // throw new Error(`Hash of config mismatch! Response: ${responseHash} Meta: ${metaHash}`);                            
                    }
                });
                return response.json();
            })
            .then(apiConfig => {
                // Merge the fetched configuration with the hardcoded api endpoints
                return {
                    ...apiConfig,
                    api: {
                        ...apiConfig.api,
                        endpoints: {
                            ...apiEndpoints
                        }
                    }
                };
            })
            .catch(e => console.error(`Failed to load config from ${configUrl}`, e))
    }

    return Promise.resolve();
};
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import axios, { ResponseType } from 'axios';
import { PropsWithChildren, useContext } from 'react';
import { useNavigate } from 'react-router';
import { SWRConfig } from 'swr';
import getToken from './token-retriever';
import toast from 'react-hot-toast';
import { ConfigContext } from '@/context/ConfigContext';
import { t } from 'i18next';

export const PlatformSWRConfig = ({ children }: PropsWithChildren) => {

    const navigate = useNavigate();
    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const configContext = useContext(ConfigContext);

    const responseTypeMapping = {
        'picture': 'blob'
    };

    const fetcher = async (url: string) => {

        const token = isAuthenticated ? await getToken(msalContext, configContext) : null;
        const savedLanguage = localStorage.getItem('selectedLanguage');
        const pattern = Object.keys(responseTypeMapping).find(pattern => url.includes(pattern));
        const responseType = pattern ? responseTypeMapping[pattern] : 'json';

        const response = await axios.get(url, {
            headers: {
                'Authorization': isAuthenticated ? `${token}` : '',
                'Accept': 'application/json',
                'Accept-Language': savedLanguage,
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey,
            },
            responseType: responseType
        });

        if (response.status === 401) {
            toast.error(t('unauthorizedAccess'));
            return navigate("/");
        }

        return response.data;
    };

    return (
        <SWRConfig value={{ fetcher, revalidateOnFocus: false, revalidateIfStale: false }}>
            {children}
        </SWRConfig>
    );
};
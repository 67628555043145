import React, { useEffect, useState } from "react";
import { IPlatformSettingsContext, PlatformSettingsContext } from "./PlatformSettingsContext";

export function PlatformSettingsProvider({ children }): React.ReactElement {

    const [contextValue, setContextValue] = useState<IPlatformSettingsContext>({
        logo: null
    });

    // useEffect(() => {
    //     setContextValue({
    //         logo: 'https://jobsrepublic-too-images.imgix.net/company/uwv/logo.png?trim=color&trimcolor=fff&w=180'
    //     });
    // }, []);

    return (
        <PlatformSettingsContext.Provider value={contextValue}>
            {children}
        </PlatformSettingsContext.Provider>
    );
}
import { OriginType } from "@/general/model/data-origin";
import { firstLetterLowercaseStringFormatter } from "@/general/utilities/FirstLetterLowercaseStringFormatter";
import { useEffect, useState } from "react";
import useProfileCompletionRequirements from "./useProfileCompletionRequirements";

export const useMissingProfileCardRequirements = (fields: string[]) => {

    const { profileCompletionRequirements } = useProfileCompletionRequirements();
    const [totalRequirementsCount, setTotalRequirementsCount] = useState<number>(null);
    const [completedRequirementsCount, setCompletedRequirementsCount] = useState<number>(null);
    const [percentComplete, setPercentComplete] = useState<number>(null);
    const [isRequired, setIsRequired] = useState<boolean>(false);

    useEffect(() => {
        setPercentComplete(totalRequirementsCount && completedRequirementsCount ? Math.round((completedRequirementsCount / totalRequirementsCount) * 100) : null);
    }, [totalRequirementsCount, completedRequirementsCount]);

    useEffect(() => {
        if (profileCompletionRequirements && fields) {

            const profileInputFieldsRequirements = profileCompletionRequirements.requirementCategories.map(c => c.requirements.filter(r => r.origin.type == OriginType.InputFields)).flat();
            const requirementsForCard = profileInputFieldsRequirements.filter(r => fields.includes(firstLetterLowercaseStringFormatter(r.origin.displayValue)));

            setIsRequired(requirementsForCard.some(r => fields.includes(firstLetterLowercaseStringFormatter(r.origin.displayValue))));
            setTotalRequirementsCount(requirementsForCard.length);
            setCompletedRequirementsCount(requirementsForCard.filter(r => r.completed).length);
        }
    }, [profileCompletionRequirements, fields]);

    return { totalRequirementsCount, completedRequirementsCount, percentComplete, isRequired };
}
import CTAFullWidth from "@/components/cta/CTAFullWidth";
import ScrollToTopButton from "@/components/scroll-to-top/ScrollToTopButton";
import { BreadcrumbsContext } from "@/context/BreadcrumbsContext";
import useCustomToasts from "@/hooks/useCustomToasts";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import './MeasurementsOverview.scss';
import FavoriteMeasurements from "./components/FavoriteMeasurements";
import MeasurementCategories from "./components/MeasurementCategories";
import MeasurementList from "./components/MeasurementList";
import RecommendedMeasurements from "./components/RecommendedMeasurements";

function MeasurementsOverview() {

    useDocumentTitle(t('documentTitles:measurements'));
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const { showMeasurementsVoucherCTAToast } = useCustomToasts();
    const [skipInView, setSkipInView] = useState(true);

    const { ref, inView } = useInView({
        initialInView: false,
        triggerOnce: true,
        threshold: 0.4,
        skip: skipInView
    });

    useEffect(() => {
        setSkipInView(false);

        if (inView) {
            showMeasurementsVoucherCTAToast();
        }
    }, [inView]);

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:measurements')
        }]);
    }, [setBreadcrumbs]);

    return (
        <div className="measurement-overview">
            
            <RecommendedMeasurements />

            <FavoriteMeasurements />

            <Container fluid className="pt-3 pb-5">
                <Container>
                    <MeasurementCategories />
                </Container>
            </Container>

            <Container fluid className="gradient-background py-5">
                <Container>
                    <CTAFullWidth
                        title={t('measurements:overview:ctaProfileTitle')}
                        description={t('measurements:overview:ctaProfileDescription')}
                        imageSrc={'/assets/img/professions/WIHV_Professions_Helpdesk.jpg'}
                        buttonText={t('measurements:overview:ctaProfileBtn')}
                        buttonLink={'/profile'} />
                </Container>
            </Container>

            <Container fluid className="py-5" ref={ref}>
                <Container>
                    <MeasurementList />
                </Container>
            </Container>

            <ScrollToTopButton section='list' />
        </div>
    )
}

export default MeasurementsOverview
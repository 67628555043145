import useFavoriteMeasurements from '@/hooks/useFavoriteMeasurements';
import { MeasurementDefinition } from '@/measurements/model/measurement-definitions/measurement-definition';
import { AnimatePresence, motion } from 'framer-motion';
import './FavoriteToggle.scss';

type FavoriteMeasurementToggleProps = {
    measurement: MeasurementDefinition;
    lightTheme?: boolean;
    iconSize?: string;
}

function FavoriteMeasurementToggle({ measurement, lightTheme = true, iconSize = 'fa-xl' }: FavoriteMeasurementToggleProps) {

    const { toggleFavorite, favoriteMeasurements } = useFavoriteMeasurements();

    function handleToggle(e) {
        e.preventDefault();
        toggleFavorite(measurement);
    }

    const isFavorite = favoriteMeasurements?.some(x => x.id === measurement.id);

    return (
        <div className='favorite-toggle' role="button" tabIndex={0} onKeyDown={(e) => { if (e.key === 'Enter') handleToggle(e) }} onClick={(e) => handleToggle(e)}>
            <AnimatePresence mode='wait'>
                {isFavorite
                    ? <motion.i className={`icon fas fa-star ${iconSize} icon--favorite ${lightTheme ? 'icon--light' : 'icon--dark'}`}
                        key="favorite"
                        animate={{ opacity: 1, scale: 1, rotate: 0 }}
                        exit={{ opacity: 0, scale: 0.7, rotate: 180 }}
                        transition={{ duration: 0.3 }} />
                    : <motion.i className={`icon far fa-star ${iconSize} icon--unfavorite ${lightTheme ? 'icon--light' : 'icon--dark'}`}
                        key="unfavorite"
                        animate={{ opacity: 1, scale: 1, rotate: 0 }}
                        exit={{ opacity: 0, scale: 0.7, rotate: 180 }}
                        transition={{ duration: 0.3 }} />}
            </AnimatePresence>
        </div>
    )
}

export default FavoriteMeasurementToggle
import { ConfigContext } from '@/context/ConfigContext';
import { LearningResource } from '@/learning/models/learning-resource';
import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import useSWR from 'swr';

const useRecommendedLearningResources = () => {

    const configContext = useContext(ConfigContext);
    const isAuthenticated = useIsAuthenticated();
    const { data: recommendedLearningResources, isLoading, error, mutate } = useSWR<LearningResource[]>(isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.learning.recommended : null);

    return { recommendedLearningResources, isLoading, error, mutate };
}

export default useRecommendedLearningResources;
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import LanguageSelector from '@/components/language-selector/LanguageSelector';
import ProfilePicture from '@/components/profile-picture/ProfilePicture';
import { LayoutContext } from '@/context/LayoutContext';
import { useMsal } from '@azure/msal-react';
import { Variants, motion } from 'framer-motion';
import { t } from 'i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import './Header.scss';

function Header() {

    const menuRef = useRef(null);
    const { stickyHeader, isMobile, navigationFolded, setNavigationFolded } = useContext(LayoutContext);
    const location = useLocation();
    const { i18n } = useTranslation();
    const { instance } = useMsal();
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        setMenuOpen(false);
        if (isMobile) {
            setNavigationFolded(true);
        }
    }, [location]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, [menuRef]);

    function logout() {
        instance.logoutRedirect();
    }

    function handleToggleClick(event) {
        event.stopPropagation();
        setMenuOpen(prevMenuOpen => !prevMenuOpen);
    }

    const dropdownVariants: Variants = {
        open: {
            scale: 1,
            transition: {
                type: "spring",
                bounce: 0,
                duration: 0.5,
                delayChildren: 0.2,
                staggerChildren: 0.05
            },
            boxShadow: "var(--shadow-overlay)",
        },
        closed: {
            scale: 0,
            transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3
            },
            boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
        }
    };

    const itemVariants: Variants = {
        open: {
            opacity: 1,
            y: 0,
            transition: { type: "spring", stiffness: 300, damping: 24 }
        },
        closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
    };

    const profilePictureVariants = {
        normal: {
            width: '48px',
            height: '48px',
            transition: { type: 'spring', stiffness: 400, damping: 40 }
        },
        sticky: {
            width: '32px',
            height: '32px',
            transition: { type: 'spring', stiffness: 400, damping: 40 }
        }
    };

    return (
        <Container fluid className={`platform-header ${stickyHeader ? 'sticky' : 'normal'}`}>
            <Container className='platform-header-inner'>

                <div className='breadcrumbs'>
                    <Breadcrumbs />
                </div>

                <div className='actions'>

                    <motion.nav
                        className='navigation'
                        initial={false}
                        animate={menuOpen ? "open" : "closed"}>

                        <motion.div
                            className='navigation-toggle'
                            whileTap={{ scale: 0.95 }}
                            tabIndex={0}
                            onClick={handleToggleClick}
                            onKeyDown={(event) => { if (event.key === 'Enter') handleToggleClick(event) }}>

                            <motion.div
                                variants={profilePictureVariants}
                                initial={false}
                                animate={stickyHeader ? 'sticky' : 'normal'}
                            >
                                <ProfilePicture editable={false} iconSize='fa-md' />
                            </motion.div>
                        </motion.div>

                        <motion.div variants={dropdownVariants} className={`navigation-dropdown ${menuOpen ? 'navigation-dropdown--open' : 'navigation-dropdown--closed'}`} ref={menuRef}>

                            <motion.div variants={itemVariants}>
                                <Link to={'/settings'} className='dropdown-item'>
                                    <i className='fa-solid fa-cog' />
                                    <span role='button'>{t('header:settings')}</span>
                                </Link>
                            </motion.div>

                            <motion.div variants={itemVariants} className='divider'></motion.div>

                            <motion.div variants={itemVariants} className='dropdown-item dropdown-item--no-hover'>
                                <img className='language-flag' src={`/assets/img/languages/${i18n.language}.svg`} alt={i18n.language} />
                                <LanguageSelector />
                            </motion.div>

                            <motion.div variants={itemVariants} className='divider'></motion.div>

                            <motion.div variants={itemVariants} className='dropdown-item' tabIndex={0} onClick={() => logout()} onKeyDown={(event) => { if (event.key === 'Enter') logout() }}>
                                <i className='fa-solid fa-sign-out' />
                                <span role='button'>{t('header:logout')}</span>
                            </motion.div>
                        </motion.div>
                    </motion.nav>

                    {isMobile &&
                        <div
                            onClick={() => setNavigationFolded(!navigationFolded)}
                            className="mobile-menu-toggle">
                            <i className={`fa-solid fa-lg fa-${navigationFolded ? 'bars' : 'times'}`} />
                        </div>
                    }
                </div>
            </Container>
        </Container>
    )
}

export default Header
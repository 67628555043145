import { t } from "i18next"
import { Container, Row, Col } from "react-bootstrap"
import { LearningResource } from "../models/learning-resource"
import './LearningResourceMetadata.scss'
import { SupplierType } from "../models/supplier-type"
import { useSupplierTypeTranslation } from "@/general/i18n/translation-helpers"

type LearningResourceMetadataProps = {
    learningResource: LearningResource
}

function LearningResourceMetadata({ learningResource }: LearningResourceMetadataProps) {

    const { translateSupplierType } = useSupplierTypeTranslation();

    return (
        <Container fluid className="learning-resource-wrapper">
            <Container>
                <Row>
                    <Col sm={12} className='learning-resource-metadata'>
                        <div className='meta-item'>
                            <i className="icon fa-solid fa-chart-line"></i>
                            <div className='text'>
                                <span>{t('learning:detail:experienceLevel')}</span>
                                <p>{learningResource.experienceLevel}</p>
                            </div>
                        </div>
                        <div className='meta-item'>
                            <i className="icon fa-regular fa-clock"></i>
                            <div className='text'>
                                <span>{t('learning:detail:durationInMinutes')}</span>
                                <p>{Math.ceil(learningResource.durationInMinutes / 60)} {t('general:hourLong')}</p>
                            </div>
                        </div>
                        <div className='meta-item'>
                            <i className="icon fa-solid fa-earth-americas"></i>
                            <div className='text'>
                                <span>{t('learning:detail:languageName')}</span>
                                <p>{learningResource.languageName}</p>
                            </div>
                        </div>
                        <div className='meta-item'>
                            {learningResource.supplier === SupplierType.LinkedInLearning && <i className="icon icon--linkedin fa-brands fa-linkedin"></i>}
                            {learningResource.supplier === SupplierType.Unknown && <i className="icon fas fa-circle-question"></i>}
                            <div className='text'>
                                <span>{t('learning:detail:supplier')}</span>
                                <p>{translateSupplierType(learningResource.supplier)}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default LearningResourceMetadata
import { t } from "i18next";

function formatFileSize(size: number): string {
    
    if (size < 1024) {
        return `${size} ${t('general:byteAbbreviation')}`;
    }

    if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)} ${t('general:kilobyteAbbreviation')}`;
    }

    return `${(size / (1024 * 1024)).toFixed(2)} ${t('general:megabyteAbbreviation')}`;
}

export default formatFileSize;
export interface DataOrigin {
    type: OriginType
    displayValue: string
    value: string
}

export enum OriginType {
    Unknown = 0,
    InputFields = 1,
    Measurement = 2,
    Uploads = 3,
    Reflection = 4,
    Feedback = 5,
    Favorites = 6,
}
import { ConfigContext } from '@/context/ConfigContext';
import { LearningResource } from '@/learning/models/learning-resource';
import { useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import useSWR from 'swr';
import useAuthenticatedRequest from './useAuthenticatedRequest';

const useFavoriteLearningResources = () => {

    const configContext = useContext(ConfigContext);
    const authenticatedRequest = useAuthenticatedRequest();
    const isAuthenticated = useIsAuthenticated();
    const { data: favoriteLearningResources, isLoading, error, mutate } = useSWR<LearningResource[]>(isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.learning.favourites : null);

    function toggleFavorite(resource: LearningResource) {
        if (favoriteLearningResources === undefined) return;

        const currentlyFavorited = favoriteLearningResources.findIndex(x => x.id === resource.id) !== -1;

        authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.learning.favourite, 'POST', { id: resource.id, isFavorite: currentlyFavorited })
            .then(() => {
                currentlyFavorited ? toast.success(t('learning:toasts:favoriteLearningResourceRemoved')) : toast.success(t('learning:toasts:favoriteLearningResourceAdded'));
                mutate(currentlyFavorited ? favoriteLearningResources.filter(x => x.id !== resource.id) : [...favoriteLearningResources, resource]);
            })
            .catch(() => {
                toast.error(t('learning:toasts:favoriteLearningResourceFailed'));
            });
    }

    return { toggleFavorite, isLoading, error, favoriteLearningResources };
}

export default useFavoriteLearningResources;
import { Spinner } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import './ADComponent.scss';

export function ADLoadingComponent() {
    const { t } = useTranslation();

    return (
        <div className='ad-info'>

            <div className='ad-info__bg-img'>
                <img src={'/assets/img/WIHV_3D_Visual_Profiel.jpg'} style={{ objectPosition: 'center center' }} alt='' />
            </div>

            <div className="ad-info__content">
                <Link to='/' className='mb-5'>
                    <img src={`/assets/img/werk-in-het-vooruitzicht-fullcolor.svg`} alt='' />
                </Link>
                <Spinner animation="border" role="status" className='text-oranje'>
                    <span className="visually-hidden">{t('activeDirectory:authenticating')}</span>
                </Spinner>
                <div className="h3 font-heavy pt-4">{t('activeDirectory:loading')}</div>
            </div>
        </div>
    );
}
import { MeasurementDefinition } from "@/measurements/model/measurement-definitions/measurement-definition";
import { useIsAuthenticated } from "@azure/msal-react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import FavoriteMeasurementToggle from "../favorite-toggle/FavoriteMeasurementToggle";
import './MeasurementCard.scss';
import Skeleton from "react-loading-skeleton";

type MeasurementCardProps = {
    measurement: MeasurementDefinition;
    type?: 'default' | 'locked';
}

function MeasurementCard({ measurement, type = 'default' }: MeasurementCardProps) {

    const isAuthenticated = useIsAuthenticated();
    const isCompleted = !isNaN(Date.parse(measurement?.completed || ''));

    return (
        <>
            {measurement &&
                <Link to={`/measurements/${measurement.id}`} className='measurement-card'>

                    <div className="img">
                        <img src={measurement.imageUrl || '/assets/img/WIHV_3D_Visual_Leerpad.jpg'} alt='' />
                    </div>

                    {isAuthenticated &&
                        <div className="favorite">
                            <FavoriteMeasurementToggle measurement={measurement} />
                        </div>
                    }

                    <div className='content'>
                        <h2 className='title'>{measurement.title}</h2>
                        <div className="meta">
                            {isCompleted &&
                                <span className="meta-item">{t('measurements:overview:measurementCardMetaCompleted')}</span>
                            }
                            <span className="meta-item">{measurement.durationInMinutes} {t('measurements:overview:measurementCardMetaMinutes')}</span>
                        </div>
                        <p className='description'>{measurement.description}</p>
                        {isCompleted &&
                            <div className="results-link">
                                <Link to={`/measurements/${measurement.id}/results`}>
                                    {t('measurements:overview:measurementCardResults')}
                                    <i className='icon fa-solid fa-chevron-right ms-2 fa-sm' />
                                </Link>
                            </div>
                        }
                    </div>

                </Link>
            }

            {
                type === 'locked' &&
                <div className='measurement-card measurement-card--locked'>

                    <div className="img">
                        <Skeleton height={'100%'} style={{ lineHeight: 'unset', borderRadius: 0, opacity: 0.7 }} enableAnimation={false} />
                    </div>

                    <div className='content'>
                        <h2 className='title'>
                            <Skeleton height={24} width={200} enableAnimation={false} />
                        </h2>
                        <div className="meta">
                            <Skeleton height={20} width={50} enableAnimation={false} />
                        </div>
                        <p className='description'>
                            <Skeleton count={4} enableAnimation={false} />
                        </p>
                    </div>

                    <div className="locked-overlay">
                        <i className="icon fa-solid fa-lock me-2" />
                    </div>
                </div>
            }
        </>
    )
}

export default MeasurementCard
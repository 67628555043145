import { motion } from 'framer-motion';

interface ProgressBarProps {
    currentStep: number;
    maxSteps: number;
    className?: string;
}

function ProgressBar({ currentStep, maxSteps, className }: ProgressBarProps) {
    const progress = (currentStep / maxSteps) * 100;

    return (
        <div style={styles.container} className={className ?? ''}>
            <motion.div
                style={styles.progress}
                initial={{ width: 0 }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 0.5 }}
            />
        </div>
    );
};

const styles = {
    container: {
        width: '100%',
        height: '12px',
        backgroundColor: 'var(--color-neutral-light-200)',
        borderRadius: '6px',
        overflow: 'hidden',
    },
    progress: {
        height: '100%',
        backgroundColor: 'var(--color-brand-secondary-100)',
        borderRadius: '6px',
    },
};

export default ProgressBar;
import { MotionProps, motion } from "framer-motion";
import { link } from "fs";
import { AnchorHTMLAttributes } from "react";

type AnchorButtonProps = {
    text: string
    icon?: string
    iconSet?: 'fas' | 'far' | 'fab'
    loading?: boolean
    disabled?: boolean
    displayType?: 'primary' | 'secondary' | 'tertiary' | 'white'
    linkToElement?: string
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

function AnchorButton({ text, icon, iconSet = 'fas', loading, disabled, displayType = 'primary', linkToElement, ...anchorProps }: AnchorButtonProps) {

    const handleClick = (event) => {
        // Get the height of the header
        const headerHeight = document.querySelector('.platform-header')?.clientHeight || 0;

        // Scroll to the element, accounting for the header height
        const element = document.querySelector(linkToElement);
        if (element) {
            window.scroll({
                top: element.getBoundingClientRect().top + window.scrollY - headerHeight,
                behavior: 'smooth',
            });
        }
    };

    return (
        <motion.a whileTap={{ scale: 0.9 }} {...linkToElement ? { onClick: (e) => handleClick(e) } : {}} className={`button button--${displayType} ${disabled ? 'button--disabled' : ''} ${loading ? 'button--loading' : ''}`} {...(anchorProps as AnchorHTMLAttributes<HTMLAnchorElement> & MotionProps)}>
            {text}
            {icon && !loading && <i className={`icon ${iconSet} fa-${icon}`} />}
            {loading && <i className='loading-icon fas fa-spinner fa-spin' />}
        </motion.a>
    )
}

export default AnchorButton;
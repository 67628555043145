import Button from '@/components/button/Button';
import { ConfigContext } from '@/context/ConfigContext';
import { Evaluation } from '@/development/models/evaluation';
import { EvaluationStatus } from '@/development/models/evaluation-status';
import { EvaluationType } from '@/development/models/evaluation-type';
import useAuthenticatedRequest from '@/hooks/useAuthenticatedRequest';
import { t } from 'i18next';
import { createRef, useContext, useEffect, useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';

type InviteFeedbackProvidersModalProps = {
    showModal: boolean;
    onClose: () => void;
    onSuccess?: () => void;
}

function InviteFeedbackProvidersModal({ showModal, onClose, onSuccess }: InviteFeedbackProvidersModalProps) {

    const configContext = useContext(ConfigContext);
    const authenticatedRequest = useAuthenticatedRequest();
    const formRef = createRef<HTMLFormElement>();
    const [validated, setValidated] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedbackProviders, setFeedbackProviders] = useState<Evaluation[]>([]);

    useEffect(() => {
        if (showModal) {
            setFeedbackProviders([]);
            addFeedbackProvider();
            setValidated(false);
            setIsSubmitting(false);
        }
    }, [showModal]);

    const handleSubmit = () => {

        setValidated(true);

        if (formRef.current?.checkValidity() && feedbackProviders.length > 0) {
            setIsSubmitting(true);

            authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.evaluations.list, 'POST', feedbackProviders)
                .then(() => {
                    toast.success(t('development:toasts:validatorsSaveSuccess'));
                    onSuccess();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(t('development:toasts:validatorsSaveFailed') + ' ' + error.message);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    }

    const addFeedbackProvider = () => {
        setFeedbackProviders(prev => [
            ...prev,
            {
                name: '',
                email: '',
                type: EvaluationType.Feedback,
                status: EvaluationStatus.Pending,
                updatedOn: new Date().toISOString(),
            } as Evaluation
        ]);
    }

    const removeFeedbackProvider = (index: number) => {
        const updatedFeedbackProviders = feedbackProviders.filter((item, i) => i !== index);
        setFeedbackProviders(updatedFeedbackProviders);
    }

    const updateFeedbackProvider = (index, propertyName, newValue) => {
        const updatedFeedbackProviders = feedbackProviders.map((item, i) => i === index ? { ...item, [propertyName]: newValue } : item);
        setFeedbackProviders(updatedFeedbackProviders);
    };

    return (
        <Modal className="wihv-modal feedback-providers-modal" show={showModal} onHide={() => onClose()} size='lg' backdrop="static" centered>
            <Modal.Header>
                <Modal.Title>{t('development:evaluation:modalTitle')}</Modal.Title>
            </Modal.Header>

            <Modal.Body as={Container}>

                <p className='pb-5'>{t('development:evaluation:modalDescription')}</p>

                <Form ref={formRef} validated={validated} noValidate>

                    {feedbackProviders.length == 0 &&
                        <div className='text-center pb-5'>
                            <p>{t('development:evaluation:noFeedbackProviders')}</p>
                        </div>
                    }

                    {feedbackProviders.map((item, index) => (
                        <Row className='wihv-modal__row mx-2 p-2 p-md-3' key={index}>
                            <Col sm={12} className="p-0">
                                <Form.Group as={Row} className="mt-0">
                                    <Col className="mt-2">
                                        <Form.Label><span>{t('development:evaluation:labelName')}</span><span className='required'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="" value={item.name ?? ''} onChange={e => updateFeedbackProvider(index, 'name', e.target.value)} required />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-0">
                                    <Col className="mt-2">
                                        <Form.Label><span>{t('development:evaluation:labelEmail')}</span><span className='required'>*</span></Form.Label>
                                        <Form.Control type="email" placeholder="" value={item.email ?? ''} onChange={e => updateFeedbackProvider(index, 'email', e.target.value)} required />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <button type='button' onClick={() => removeFeedbackProvider(index)} className="wihv-modal__row-delete">
                                <i className="fa-solid fa-trash-can" />
                            </button>
                        </Row>
                    ))}
                </Form>
                <Row>
                    <Col sm={12} className='mt-3 text-center'>
                        <Button text={t('development:evaluation:addFeedbackProviderBtn')} icon='plus' displayType='secondary' onClick={() => { addFeedbackProvider() }} />
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button text={t('development:evaluation:modalCancelBtn')} displayType='secondary' onClick={() => onClose()} />
                <Button text={t('development:evaluation:modalSubmitBtn')} icon='paper-plane' onClick={() => handleSubmit()} loading={isSubmitting}/>
            </Modal.Footer>
        </Modal>
    )
}

export default InviteFeedbackProvidersModal